import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useState } from "react";
import * as actions from "store/actions";
import { useDispatch } from "react-redux";
import { addUpdateAccountSetting_, getSettingDetail_ } from "store/services/accountSettingService";

const NotificationSettings = ({ documentId }) => {
  const dispatch = useDispatch();

  const [notificationSetting, setNotificationSetting] = useState({
    id: null,
    data_key: "client_document_read_notification",
    data_set: null,
  });

  useEffect(() => {
    fetchSettings();
  }, [documentId]);

  const fetchSettings = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(getSettingDetail_("client_document_read_notification"));
      setNotificationSetting(response?.body);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching notes", err);
    }
  }

  const handleToggleNotification = async (isChecked) => {
    try{
        let payload = {
          data_set : isChecked === true ? "enable" : "disable"
        }
        dispatch(actions.persist_store({ loader: true }));
        await dispatch(addUpdateAccountSetting_(notificationSetting.id, payload));
        setNotificationSetting((prev) => ({
          ...prev,
          data_set: payload?.data_set
        }))
        dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
        console.log(err);
        dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <p className="m-0">
        Receive notification when client views documents?
      </p>
      <BootstrapSwitchButton
        className="rounded-pill"
        checked={notificationSetting?.data_set === "enable"}
        onlabel="Yes"
        offlabel="No"
        size="xs"
        onChange={handleToggleNotification}
      />
    </>
  );
};

export default NotificationSettings;
