import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const SchedulerFutureBookingAllowed = ({
  nextStep,
  settingList,
  handleDisplayName,
  editDisplayName,
  buttonDisable,
  handleChange,
  handleNext,
  submitData_,
  settingListStep,
  step_name,
  setStep,
  setSchedulerType,
  setStepType,
  stepType,
}) => {
  const [stepToCheck, setStepToCheck] = useState(false)

  useEffect(() => {
    let settingListSteps = settingListStep.data_set ?  JSON.parse(settingListStep?.data_set) : [];
    let isStepExists = settingListSteps.some(step => step.step_name === step_name);
      if (isStepExists) {
        setStepToCheck(true)
      } else {
        setStepToCheck(false)
      }
  }, [stepToCheck])
  const backStep_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }else{
      setStep(14)
    }
  }
  console.log("settingList", settingList)
  return (
    <>
      {settingList.data_key ===
        "scheduler_future_date_booking_allowed" && (
          <div
            className="commonContent p-3 gettingStarted"
            style={{ background: "#fffef7", zIndex: "9" }}
          >
            <div className="top py-2 border-bottom">
              <h3 className="m-0 fw-bold py-2 capitalize">
                Max Scheduling Date
              </h3>
            </div>
            <div className="CardBody capitalize py-2">
              <div className="py-2">
                <p className="py-2 m-0">
                  Select how far into the future clients can
                  schedule appointments. For example, if you
                  choose  {settingList?.data_set} then your clients will
                  only be able to schedule an appointment
                  within the next {settingList?.data_set}.
                </p>

                <div className="py-2 d-flex align-items-center justify-content-between gap-10 flex-wrap">
                  <div className="left">
                    <h4 className="m-0 fw-bold py-2">
                      Max Scheduling Date:{" "}
                      <p className="fw-normal d-inline-block text-muted m-0">
                      {settingList?.data_set}  in the future
                      </p>
                    </h4>
                  </div>
                  <div className="right">
                    <Button
                      onClick={handleDisplayName}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    >
                      Update
                    </Button>
                  </div>
                </div>
                {editDisplayName ? (
                  <>
                    <div className="commonCardBox p-3 mt-3 bg-white">
                      <Form>
                        <Row>
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label theme-clr fw-sbold m-0"
                            >
                              Max Scheduling Date Options:
                            </label>
                            <Form.Select
                              aria-label="Default select example"
                              className="form-control"
                              name="scheduler_future_date_booking_allowed"
                              onChange={handleChange(
                                "scheduler_future_date_booking_allowed"
                              )}
                            >
                              <option value="1 month" selected={settingList.data_set === "1 month" ? "selected" : ""}>1 month</option>
                              <option value="2 months" selected={settingList.data_set === "2 months" ? "selected" : ""}>2 months</option>
                              <option value="3 months" selected={settingList.data_set === "3 months" ? "selected" : ""}>3 months</option>
                              <option value="4 months" selected={settingList.data_set === "4 months" ? "selected" : ""}>4 months</option>
                              <option value="5 months" selected={settingList.data_set === "5 months" ? "selected" : ""}>5 months</option>
                              <option value="6 months" selected={settingList.data_set === "6 months" ? "selected" : ""}>6 months</option>
                              <option value="9 months" selected={settingList.data_set === "9 months" ? "selected" : ""}>9 months</option>
                              <option value="12 months" selected={settingList.data_set === "12 months" ? "selected" : ""}>12 months</option>
                              <option value="16 months" selected={settingList.data_set === "16 months" ? "selected" : ""}>16 months</option>
                              <option value="20 months" selected={settingList.data_set === "20 months" ? "selected" : ""}>20 months</option>
                              <option value="24 months" selected={settingList.data_set === "24 months" ? "selected" : ""}>24 months</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {!stepToCheck ? (
                <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
                  <Button
                    onClick={() => setStep(14)}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={buttonDisable ? true : false}
                    onClick={async () => {
                      await submitData_("15", settingList.id, nextStep, false);
                      handleNext("future_date_booking_setting");
                    }}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    {buttonDisable ? "Wait.." : "Save"}
                  </Button>
                </div>
                ):(
                  <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
                  <Button
                      onClick={() => backStep_()}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={buttonDisable ? true : false}
                    onClick={async () => {
                      await submitData_("15", settingList.id, nextStep, stepType ? true : false);
                      handleNext("future_date_booking_setting");
                    }}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    {buttonDisable ? "Wait.." : "Save"}
                  </Button>
                </div>
                )}
              </div>
            </div>
          </div>
        )}
    </>
  )
}

export default SchedulerFutureBookingAllowed;