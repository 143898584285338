import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import parse from 'html-react-parser';
import moment from "moment";

import { TODO } from "common/viewContent";
import ViewDirectLinkPopup from "../Modals/ViewDirectLinkPopup";

const TableData = ({ client, todoItems, todoFor, onClickOpen, onClickSendReminder, onClickRemove }) => {
  const [showDirectLinkPopup, setShowDirectLinkPopup] = useState(false);
  const [currentTodo, setCurrentTodo] = useState(false);

  const openViewTodoLinkPopup = (todo) => {
    setCurrentTodo(todo);
    setShowDirectLinkPopup(true);
  }

  return (
    <>
    <ViewDirectLinkPopup 
      showPopup={showDirectLinkPopup}
      closePopup={() => {
        setShowDirectLinkPopup(false);
      }}
      todo={currentTodo}
    />
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Item Name
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Status
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Item Added On
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {(todoItems && todoItems.length > 0) ?
              todoItems.map((todo, idx) => {

                return (
                  <tr key={idx}>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0 ws-normal fw-bold">
                        {todo.form_name}
                      </p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p
                        className={TODO[todo.status].className}
                      >
                        <span className="icn me-2">
                          {parse(TODO[todo.status].icon || "")}
                        </span>
                        {TODO[todo.status].status}
                      </p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">
                        {moment(todo.created_at).format('MMM D, YYYY hh:mm A')}
                      </p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          className=" bg-white btnSm capitalize text-dark fw-sbold border pe-5 position-relative"
                          id="dropdown-basic"
                        >
                          Options
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {todoFor === "client" ?
                            <Dropdown.Item
                              onClick={() => {
                                onClickSendReminder(todo.id);
                              }}
                              className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                            >
                              Send Reminder
                            </Dropdown.Item>
                            :
                            <Dropdown.Item
                              onClick={() => {
                                onClickOpen(todo);
                              }}
                              className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                            >
                              Open
                            </Dropdown.Item>
                          }
                          <Dropdown.Item
                            onClick={() => {
                              onClickRemove(todo.id);
                            }}
                            className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                          >
                            Remove
                          </Dropdown.Item>
                          {client?.is_direct_account === "yes" && (
                            <Dropdown.Item
                              onClick={() => {
                                openViewTodoLinkPopup(todo);
                              }}
                              className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                            >
                              View Direct Link
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
              :
              <tr>
                <td className="text-center" colSpan={4}>No data found</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableData;
