import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import moment from "moment";

// ICN
import { Link } from "react-router-dom";
import { UC_FIRST } from "helpers/common";

const RecommendationListing = ({ recommendations, onClickOpen, removeRecommendation }) => {
  const data = [
    {
      item: "Nutritional Assessment Questionnaire",
      status: `in progress`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
    {
      item: "test - ignore",
      status: `not complete`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
    {
      item: "Nutritional Assessment Questionnaire",
      status: `paused`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
  ];

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Item Name
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Status
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Item Added On
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {recommendations && recommendations.length > 0 ?
              recommendations.map((recommendation, idx) => {
                return (
                    <tr key={idx}>
                      <td className="px-3 py-2 border-0">
                        <p className="theme-clr m-0 ws-normal fw-bold">
                          Recommendation
                        </p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <p
                          // className={
                          //   recommendation.status == "in progress"
                          //     ? "SuccessText text-capitalize fw-sbold m-0"
                          //     : data.status == "not complete"
                          //     ? "DangerText text-capitalize fw-sbold m-0"
                          //     : data.status == "paused"
                          //     ? "WarningText text-capitalize fw-sbold m-0"
                          //     : ""
                          // }
                        >
                          {recommendation.status == "pending" ? (
                            "Paused"
                          ): recommendation.status == "in-process" ? (
                            "In Progress"
                          ): (
                            UC_FIRST(recommendation.status)
                          )}
                        </p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <p className="theme-clr m-0">{moment(recommendation.created_at).format("MMM DD, YYYY H:mm A")}</p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <Dropdown>
                          <Dropdown.Toggle
                            className=" bg-white btnSm capitalize text-dark fw-sbold border pe-5 position-relative"
                            id="dropdown-basic"
                          >
                            Options
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <ul className="list-unstyled ps-0 mb-0 noLastBorder">
                              <li>
                                <Link
                                  onClick={() => {
                                    console.log("open", recommendation)
                                    onClickOpen(recommendation.id);
                                  }}
                                  className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                                >
                                  Open
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    removeRecommendation(recommendation.id);
                                  }}
                                  className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                                >
                                  Remove
                                </Link>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                );
              })
              :
                <tr>
                  <td className="text-center" colSpan={4}>No data found</td>
                </tr>
              }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecommendationListing;
