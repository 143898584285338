import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { getPriorityReportV2, getTodoNotes, saveTodoNotes } from "store/services/clientDataReportsService";
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { ReactComponent as EditIcn } from "Assets/Images/icn/pencil.svg";
import { ReactComponent as PlusIcn } from "Assets/Images/icn/plus.svg";
import { safelyParseJSON, UC_FIRST } from "helpers/common";
import { decode } from "html-entities";
import AddNotesPopup from "components/shared/AddNotesPopup/Index";
import DownloadPriorityReportPDF from "../PDFReportComponents/PriorityReportPDF";
import DownloadPriorityReportSBGPDF from "../PDFReportComponents/PriorityReportSBGPDF";

import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";

const PriorityReport = ({ eventKey, todoId, client, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityReport, setPriorityReportData] = useState(null);
  const [selectedType, setSelectedType] = useState("questionnaireo");
  const [expandedSection, setExpandedSection] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [notesPopup, setNotesPopup] = useState({
    show: false,
    question: null,
    notes: null,
  });

  useEffect(() => {
    if (isOpen && !priorityReport) {
      getPriorityReportData();
      getNotes();
    }
  }, [isOpen]);

  const openNotesPopup = (question) => {
    let notes = notesData?.find(notes => notes.question_id === question.question_id);
    setNotesPopup(prevState => ({
      ...prevState,
      show: true,
      question,
      notes: notes || null
    }));
  }

  const closeNotesPopup = () => {
    setNotesPopup(prevState => ({
      ...prevState,
      show: false,
      question: null,
      notes: null
    }));
  }

  const getNotes = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      const response = await getTodoNotes(todoId);
      if (response.success === true && response.body.length > 0) {
        setNotesData(response.body);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const toggleExpandedSection = (questionId) => {
    if (expandedSection === questionId) {
      setExpandedSection(null);
    } else {
      setExpandedSection(questionId);
    }
  }

  const getPriorityReportData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      const response = await getPriorityReportV2(data);
      if (response.success === true) {
        const data = response.body;
        setPriorityReportData(data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const handleSaveNotes = async (notes, question) => {
    try {
      let data = {
        todoId,
        values: {
          todo_id: todoId,
          // page_id: question.pageId,
          question_id: question.question_id,
          general_notes: notes.general_notes,
          specific_notes: notes.specific_notes,
        }
      };
      await saveTodoNotes(data);
      await getNotes();
    } catch(err) {
      throw err;
    }
  }

  return (
    <Accordion.Item eventKey={eventKey} className="priority-report my-2">
      <Accordion.Header
       className="accordian-cap "
      >
        Priority Report
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <AddNotesPopup
          showPopup={notesPopup.show}
          setShowPopup={setNotesPopup}
          closePopup={closeNotesPopup}
          question={notesPopup.question}
          notes={notesPopup.notes}
          saveNotes={handleSaveNotes}
        />
        {isLoading ?
          <p>Loading...</p>
          :
          priorityReport ?
            <div className="accordion-inner">
              <div className="py-2">
                <div className="top d-flex align-items-center justify-content-between gap-10">
                  <div className="left d-flex align-items-center gap-10">
                    <p className="m-0 capitalize fw-bold">View Priority Report grouped by:</p>
                    <Form.Select
                      className="form-control fw-sbold btnSm"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSelectedType(e.target.value);
                      }}
                    >
                      <option value="questionnaireo">Questionnaire Section</option>
                      <option value="SBG">SBG Section</option>
                    </Form.Select>
                  </div>
                  <div className="right">
                    <Dropdown className="download-pdf-dropdown">
                      <Dropdown.Toggle
                        className=" commonBtn btnSm capitalize fw-sbold border pe-5 position-relative"
                        id="dropdown-basic"
                      >
                        <span className="icn me-2">
                          <DownloadIcon />
                        </span>
                        Download Report
                      </Dropdown.Toggle>
                      <Dropdown.Menu>

                        <Dropdown.Item as={"div"}>
                          <DownloadPriorityReportPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            type={"questionnaireo"}
                            label="Priority Report"
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <DownloadPriorityReportPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            showNotes={true}
                            type={"questionnaireo"}
                            label="Priority Report w/ Notes"
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <DownloadPriorityReportSBGPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            type={"SBG"}
                            label="Priority Report: SBG"
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <DownloadPriorityReportSBGPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            showNotes={true}
                            type={"SBG"}
                            label="Priority Report: SBG w/ Notes"
                          />
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="top">
                  <p className="m-0">The questions highlighted below are colored and ranked based on the specific NAQ question the user answered. Priority categories used for ranking are:</p>
                  <ul>
                    <li>High: highlighted in red, these represent questions where the user chose the highest risk/priority answer.</li>
                    <li>Medium: highlighted in yellow, these represent questions where the user chose the 2nd highest risk/priority answer.</li>
                    <li>Low: not highlighted, these represent questions where the user chose the 3rd highest risk/priority answer.</li>
                  </ul>
                </div>
              </div>
              <div className="sections-outer">
                {(priorityReport && priorityReport[selectedType]?.length > 0) ?
                  priorityReport[selectedType].map((section) => {
                    if (!section.sectionName) {
                      return "";
                    }
                    return (
                      <div className="section py-4">
                        <h6>{section.sectionName}</h6>
                        <div className="commonCardBox">
                          <div className="table-responsive">
                            <table className="table priority-table">
                              <thead>
                                {" "}
                                <tr>
                                  <th></th>
                                  <th className="p-3 fw-normal text-dark fw-sbold">
                                    Priority
                                  </th>
                                  <th className="p-3 fw-normal text-dark fw-sbold">
                                    Question
                                  </th>
                                  <th className="p-3 fw-normal text-dark fw-sbold">
                                    Answer
                                  </th>
                                  <th className="p-3 fw-normal text-dark fw-sbold">
                                    Score vs Max
                                  </th>
                                  <th className="p-3 fw-normal text-dark fw-sbold">
                                    SBG Section
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <TableRows
                                  section={section}
                                  priorityReport={priorityReport}
                                  expandedSection={expandedSection}
                                  toggleExpandedSection={toggleExpandedSection}
                                  notesData={notesData}
                                  openNotesPopup={openNotesPopup}
                                />
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <>
                    <p>Data not available</p>
                  </>
                }

              </div>
            </div>
            :
            <>
              <p>Data not available</p>
            </>

        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

const TableRows = ({ section, priorityReport, expandedSection, toggleExpandedSection, notesData, openNotesPopup }) => {
  // let questions = section.questions.map()
  return (
    <>
      {section.questions && section.questions.length > 0 &&
        section.questions.map((question) => {
          // let question = priorityReport.questions.find((question) => question.id === questionId);
          let questionId = question.question_id;
          let notes = notesData?.find(notes => notes.question_id === questionId);
          if(notes && notes.specific_notes) {
            notes.specific_notes = safelyParseJSON(notes.specific_notes);
          }
          return (
            <>
              <tr>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>
                  {
                    (question.indicated_nutrients?.length > 0 ||
                      question.targeted_supplements?.length > 0 ||
                      question.food_support_sources?.length > 0 ||
                      question.lifestyle_support_ideas?.length > 0
                    ) &&
                    <Link
                      onClick={() => toggleExpandedSection(questionId)}
                    >
                      <PlusIcn className="plus-icon" />
                    </Link>
                  }
                </td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>{UC_FIRST(question.category)}</td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>{decode(question.question)}</td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>{decode(question?.answer)}</td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>{question.scoreVsMax}</td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>{question.foundations}</td>
                <td className={`p-3 border-0 ${question.category.toLowerCase()}-priority`}>
                  <Link
                    onClick={() => {
                      openNotesPopup(question)
                    }}
                  >
                    {notes ?
                      <EditIcn className="edit-icon" />
                      :
                      <PlusIcn className="add-icon" />
                    }
                  </Link>
                </td>
              </tr>
              {notes &&
                <tr class="severity-notes">
                  <td colSpan={7}>
                    <table class="severity-notes-table">
                      {notes.general_notes &&
                        <tr>
                          <td><strong>Notes</strong></td>
                          <td>{notes.general_notes}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Indicated Nutrients"]) &&
                        <tr>
                          <td><strong>Indicated Nutrients</strong></td>
                          <td>{notes.specific_notes["Indicated Nutrients"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Targeted Supplements"]) &&
                        <tr>
                          <td><strong>Targeted Supplements</strong></td>
                          <td>{notes.specific_notes["Targeted Supplements"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Food Support Sources"]) &&
                        <tr>
                          <td><strong>Food Support Sources</strong></td>
                          <td>{notes.specific_notes["Food Support Sources"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Lifestyle Support Ideas"]) &&
                        <tr>
                          <td><strong>Lifestyle Support Ideas</strong></td>
                          <td>{notes.specific_notes["Lifestyle Support Ideas"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Foundational Connections"]) &&
                        <tr>
                          <td><strong>Foundational Connections</strong></td>
                          <td>{notes.specific_notes["Foundational Connections"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Related Client Concern(s)"]) &&
                        <tr>
                          <td><strong>Related Client Concern(s)</strong></td>
                          <td>{notes.specific_notes["Related Client Concern(s)"]}</td>
                        </tr>
                      }
                    </table>
                  </td>
                </tr>
              }
              {(question.indicated_nutrients ||
                question.targeted_supplements ||
                question.food_support_sources ||
                question.lifestyle_support_ideas
              ) && expandedSection === questionId &&
                <tr>
                  <td colSpan={7}>
                    <Accordion defaultActiveKey={questionId}>
                      <Accordion.Item eventKey={questionId} className="priority-report">
                        <Accordion.Body>
                          <h6>Potential Supports</h6>
                          <Row>
                            {question.indicated_nutrients && question.indicated_nutrients.length > 0 && (
                              <Col lg="12" className="py-3">
                                <p className="capitalize m-0 text-dark fw-bold">NUTRIENTS</p>
                                <ul className=" mb-0" style={{ maxWidth: "500px" }}>
                                  {question.indicated_nutrients.map(nutrient => {
                                      if(!nutrient) {
                                        return null;
                                      }
                                      return (
                                        <li className="">
                                          {nutrient}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </Col>
                            )}
                            {question.targeted_supplements && question.targeted_supplements.length > 0 && (
                              <Col lg="12" className="py-3">
                                <p className="capitalize m-0 text-dark fw-bold">
                                  Supplements
                                </p>
                                <ul className=" mb-0" style={{ maxWidth: "500px" }}>
                                  {question.targeted_supplements.map(supplement => {
                                      if(!supplement) {
                                        return null;
                                      }
                                      return (
                                        <li className="">
                                          {supplement}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </Col>
                            )}
                            {question.food_support_sources && question.food_support_sources.length > 0 && (
                              <Col lg="12" className="py-3">
                                <p className="capitalize m-0 text-dark fw-bold">Foods</p>
                                <ul className=" mb-0" style={{ maxWidth: "500px" }}>
                                  {question.food_support_sources.map(food => {
                                      if(!food) {
                                        return null;
                                      }
                                      return (
                                        <li className="">
                                          {food}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </Col>
                            )}
                            {question.lifestyle_support_ideas && question.lifestyle_support_ideas.length > 0 && (
                              <Col lg="12" className="py-3">
                                <p className="capitalize m-0 text-dark fw-bold">Lifestyle</p>
                                <ul className=" mb-0" style={{ maxWidth: "500px" }}>
                                  {question.lifestyle_support_ideas.map(lifestyleIdea => {
                                      if(!lifestyleIdea) {
                                        return null;
                                      }
                                      return (
                                        <li className="">
                                          {lifestyleIdea}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </Col>
                              )}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </td>
                </tr>
              }
            </>
          )
        })
      }
    </>
  )
}

export default PriorityReport;