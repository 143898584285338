import moment from "moment";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

const CustomBarChart = ({ innerRef, dataset, title, naqList, selectedNAQs }) => {
  const [showPriorityMaxes, setShowPriorityMaxes] = useState(true);

  if(dataset && dataset.length > 0){
    dataset = dataset.map(foundation => {
      if(selectedNAQs.length > 0) {
        let shouldShowData = selectedNAQs.find(id => id == foundation.todo_id);
        if(shouldShowData) {
          return foundation;  
        }
        return null;
      }

      return foundation;
    })
    dataset = dataset.filter(row => !!row);
  }
  
  const toggleShowPriorityMaxes = (e) => {
    setShowPriorityMaxes(e.target.checked);
  };

  const bars = (dataset) => {
    try {
      let count = dataset.length;
      let bars = dataset[0].data.map((row, i) => {
        let rows = [];
        for(let j = 0; j < count; j++) {
          let row = dataset[j].data[i];
          row.color = naqList?.length && naqList.find(naq => Number(dataset[j]?.todo_id) === Number(naq.id))?.color || row.color;
          if(!row) {
            continue;
          }
          rows.push(row);
        }
        return rows.map(row => {
          return (
            <>
              <Bar
                data={row}
                showPriorityMaxes={showPriorityMaxes}
                isChild={row.isChild}
              />
            </>
          )
        })
      })

      return bars;
    } catch(err) {
      console.error("Failed to render bars", err);
      return <tr><td colSpan={4} className="text-center">Failed to render</td></tr>
    }
  }

  if (!dataset || !dataset.length > 0) {
    return (
      <p className="fw-bold py-2">
        Data not found
      </p>
    );
  }

  return (
    <ErrorBoundary fallback={<p>Failed to load graph</p>}>
      <style jsx>
        {`
          .cstmBars:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: var(--bar-width);
            background-color: var(--bar-color); /* Add your styles here */
            z-index: -1;
          }
          .child .cstmBars:after {
            background-color: var(--bar-color);
            width: var(--bar-width);
            opacity: 0.7;
          }
          .cstmBars span:not(.unhide) {
            visibility: hidden;
          }
          .child h6 {
            font-size: 0.75rem;
          }
        `}
      </style>
      <div className="naq2-barchart cstmBarChart position-relative mt-4">
        <h6>{title}</h6>
        <div className="chart-labels py-2 d-flex justify-content-center">
          { naqList && naqList.length > 0 &&
            naqList.map(naq => {
              if(selectedNAQs && !selectedNAQs.includes(naq.id)) {
                return "";
              }
              return (
                <p
                  className="h6 mx-2"
                  style={{ borderBottom: `4px solid ${naq.color}` }}
                >
                  {moment(naq.updated_at).format("MMM DD, YYYY")}
                </p>
              )
            })
          }
        </div>
        <div ref={innerRef} className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className=" border-end py-3 px-3"></th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    Low Priority
                  </span>
                </th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    Medium Priority
                  </span>
                </th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    High Priority
                  </span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bars(dataset)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="py-2">
        <input
          name="show_priority_maxes"
          type="checkbox"
          onChange={toggleShowPriorityMaxes}
          checked={showPriorityMaxes}
        />
        <label className="px-2">Show priority level maxes on chart</label>
      </div>
      {/* <hr></hr>
      <div className="py-2">
        <h6>{totalsTitle}</h6>
        <ul className="symptom-totals">
          {dataset &&
            dataset.length > 0 &&
            dataset.map((row) => {
              return (
                <li>
                  <strong>{`${row.abbr}: ${row.rawTotal}`}</strong>
                  {` chart max of ${row.priorityMaxes?.highMax}`}
                </li>
              );
            })}
        </ul>
      </div> */}
      <hr></hr>
    </ErrorBoundary>
  );
};

const Bar = ({ data, showPriorityMaxes, isChild = false }) => {
  let bars = {
    low: {
      max: data.priorityMaxes?.low_max,
      width: 0,
    },
    med: {
      max: data.priorityMaxes?.med_max,
      width: 0,
    },
    high: {
      max: data.priorityMaxes?.high_max,
      width: 0,
    },
  };

  if (data.rawTotal <= bars.low.max) {
    bars.low.width = Math.min((data.rawTotal * 100) / bars.low.max, 100);
  } else if (data.rawTotal > bars.low.max && data.rawTotal <= bars.med.max) {
    bars.low.width = 100;
    bars.med.width =
      Math.min(((data.rawTotal - bars.low.max) * 100) / (bars.med.max - bars.low.max), 100);
  } else if (data.rawTotal > bars.med.max) {
    bars.low.width = 100;
    bars.med.width = 100;
    bars.high.width =
    Math.min(((data.rawTotal - bars.med.max) * 100) / (bars.high.max - bars.med.max), 100);
  }

  let showDangerSign = false;
  if(data.rawTotal > bars.high.max) {
    showDangerSign = true;
  }

  return (
    <>
      <tr className={`${isChild  && "child"}`}>
        <td className="border-bottom-0 border-end py-1 px-3">
          <h6 className="text-end m-0 fw-sbold ws-nowrap">{`${data.abbr} ${data.rawTotal}`}</h6>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.low.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.low_max}
            </span>
          </p>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.med.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.med_max}
            </span>
          </p>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.high.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.high_max}
              {showDangerSign && (
                <span className="danger-sign">!</span>
              )}
            </span>
          </p>
        </td>
      </tr>
    </>
  );
};

export default CustomBarChart;
