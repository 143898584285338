import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as PencilIcn } from "Assets/Images/icn/pencil.svg";

const ClientPriorities = ({ togglePrioritesPopup, clientPriorities }) => {
  return (
    <div
      className="position-relative p-3 p-lg-4 gettingStarted"
      style={{
        background: "#fff8f6",
        zIndex: "9",
        borderColor: "#f4694b",
      }}
    >
      <div className="top d-flex align-items-start justify-content-between gap-10 commonContent">
        <div className="left">
          <h3 className="m-0 head py-2 fw-bold">
            Client Priorities
          </h3>
        </div>
        <div className="right">
          <Button
            onClick={togglePrioritesPopup}
            className="d-flex btnSm align-items-center justify-content-center gap-10 bg-white border text-dark fw-sbold"
          >
            <span className="icn">
              <PencilIcn />
            </span>{" "}
            Edit Priorities
          </Button>
        </div>
      </div>
      <div className="CardBody py-2">
        <p className="m-0 py-2">
          Track your client's current concerns, priorities and
          short term goals. Click the '
          <Link to="" className=" fw-sbold">
            {" "}
            Edit Priorities{" "}
          </Link>
          ' button above to add or change your priority notes for
          this client. These notes are for you and are not shown
          to your client.
        </p>
        <Row>
          <Col lg="4" sm="6" className="my-2">
            <div className="commonCardBox bg-white p-3 border-0 shadow">
              <h6 className="fw-bold m-0 py-1">
                Current Client Concerns
              </h6>
              <ul className="ps-3 m-0">
                { clientPriorities?.currentClientConcerns && clientPriorities.currentClientConcerns.length > 0 ?
                  clientPriorities.currentClientConcerns.map((note, index) => {
                    return note && (
                      <li key={index} className="text-muted fw-sbold ">
                        { note }
                      </li>
                    )
                  })
                  :
                  <>
                    <li className="text-muted fw-sbold ">
                      No notes entered
                    </li>
                  </>
                }
              </ul>
            </div>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <div className="commonCardBox bg-white p-3 border-0 shadow">
              <h6 className="fw-bold m-0 py-1">
                Current Priorities
              </h6>
              <ul className="ps-3 m-0">
                { clientPriorities?.currentPriorites && clientPriorities.currentPriorites.length > 0 ?
                  clientPriorities.currentPriorites.map((note, index) => {
                    return note && (
                      <li key={index} className="text-muted fw-sbold ">
                        { note }
                      </li>
                    )
                  })
                  :
                  <>
                    <li className="text-muted fw-sbold ">
                      No notes entered
                    </li>
                  </>
                }
              </ul>
            </div>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <div className="commonCardBox bg-white p-3 border-0 shadow">
              <h6 className="fw-bold m-0 py-1">
                Goals
              </h6>
              <ul className="ps-3 m-0">
                { clientPriorities?.shortTermGoals && clientPriorities.shortTermGoals.length > 0 ?
                  clientPriorities.shortTermGoals.map((note, index) => {
                    return note && (
                      <li key={index} className="text-muted fw-sbold ">
                        { note }
                      </li>
                    )
                  })
                  :
                  <>
                    <li className="text-muted fw-sbold ">
                      No notes entered
                    </li>
                  </>
                }
              </ul>
            </div>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <div className="commonCardBox bg-white p-3 border-0 shadow">
              <h6 className="fw-bold m-0 py-1">
                Medications
              </h6>
              <ul className="ps-3 m-0">
                { clientPriorities?.medications && clientPriorities.medications.length > 0 ?
                  clientPriorities.medications.map((note, index) => {
                    return note && (
                      <li key={index} className="text-muted fw-sbold ">
                        { note }
                      </li>
                    )
                  })
                  :
                  <>
                    <li className="text-muted fw-sbold ">
                      No notes entered
                    </li>
                  </>
                }
              </ul>
            </div>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <div className="commonCardBox bg-white p-3 border-0 shadow">
              <h6 className="fw-bold m-0 py-1">
                Food Allergies
              </h6>
              <ul className="ps-3 m-0">
                { clientPriorities?.foodAllergies && clientPriorities.foodAllergies.length > 0 ?
                  clientPriorities.foodAllergies.map((note, index) => {
                    return note && (
                      <li key={index} className="text-muted fw-sbold ">
                        { note }
                      </li>
                    )
                  })
                  :
                  <>
                    <li className="text-muted fw-sbold ">
                      No notes entered
                    </li>
                  </>
                }
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ClientPriorities;