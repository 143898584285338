import { Nav, Tab } from "react-bootstrap";
import NAQv1 from "./NAQv1/Index";
import NAQv2 from "./NAQv2/Index";
import HealthWellnessAssessment from "./HealthWellnessAssessment/Index";
import { connect } from "react-redux";
import { HEALTH_COATCH_CAT_ID } from "common/constants";
const DataAnalysis = ({ client }) => {
  if (!client) {
    return null;
  }

  let clientCategory = client?.client_category;

  return (
    <>
      {clientCategory === HEALTH_COATCH_CAT_ID ? (
        <HealthWellnessAssessment />
      ) : (
        // <NAQv2 />
        <Tab.Container id="left-tabs-example" defaultActiveKey="NAQv1">
          <div className="top px-lg-4 py-3">
            <Nav variant="pills" className="commonBtnTabs px-4">
              <Nav.Item className="px-2">
                <Nav.Link
                  className="fw-sbold capitalize py-2"
                  eventKey="NAQv1"
                >
                  NAQ v1
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="px-2">
                <Nav.Link
                  className="fw-sbold capitalize py-2"
                  eventKey="NAQv2"
                >
                  NAQ v2
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="CardBody p-3 px-lg-4 data-analysis">

            <Tab.Content>
              <Tab.Pane eventKey="NAQv1" mountOnEnter={true}>
                <NAQv1 />
              </Tab.Pane>
              <Tab.Pane eventKey="NAQv2" mountOnEnter={true}>
                <NAQv2 />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      )}
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DataAnalysis);