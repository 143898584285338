import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMax, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';
import { debounce } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const LineChart = forwardRef(function Chart({ 
  chartData,
  height,
  screenSize,
  selectedNAQs,
  getColor,
  innerRef,
}, ref) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState(chartOptions);
  const [hoveredDatasetIndex, setHoveredDatasetIndex] = useState(null);

  let datasetCount = selectedNAQs?.length || chartData?.length;

  let chartHeight = (datasetCount && ((20) * datasetCount) + height) || height;

  const chartPlugins = useMemo(() => {
    return [{
      id: 'myEventCatcher',
      beforeEvent(chart, args, pluginOptions) {
        const event = args.event;
        if (event.type === 'mouseout') {
          setHoveredDatasetIndex(null);
        }
      }
    }]
  }, [setHoveredDatasetIndex]);

  useEffect(() => {
    if(chartData) {
      let labels = [];
      const chartLabels = {};
      const datasetLines = {}
      for(let i = 0; i < chartData[0]?.data?.line?.length; i++) {
        let label = chartData[0]?.data.line[i].title;
        labels.push(decode(label));
        let loopIndex = 0;
        for(let j = loopIndex; j < chartData.length; j++ ) {
          if(selectedNAQs.length && !selectedNAQs.includes(chartData[j].todo_id)) {
            continue;
          }
          let row = chartData[j]?.data.line[i];
          let total = row?.rawTotal || 0;
          if(!row){
            // continue;
          }
          let yAdjust = ((loopIndex + 1 ) * 26);

          // content to draw
          const canvas = document.createElement("canvas");
          const scale = 2;
          const width = 25, height = 20;

          canvas.width = width * scale;
          canvas.height = height * scale;

          const ctx = canvas.getContext("2d");

          // Set text properties
          ctx.fillStyle = "#000";
          ctx.font = "24px Arial";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";


          ctx.fillText(total, canvas.width / 2, canvas.height / 2);

          chartLabels[`chartLabel_${i}_${loopIndex}`] = {
            type: 'label',
            xValue: i,
            yValue: 0,
            yAdjust: yAdjust,
            content: canvas,
            textDecoration: 'underline',
            color: "#fff",
            // fontWeight: 700,
            height,
            width,
            backgroundColor: getColor(chartData[j].todo_id),
            backgroundShadowColor: "#ccc",
            shadowBlur: 5,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            padding: 0,
            borderRadius: 4,
            font: {
              size: 14,
            },
            // rotation: 270,
            position: "center",
            align: "left",
          }
          loopIndex++;
        }
      }

      let dangerLabels = {};
      let datasets = chartData.map((data, index) => {
          let dataset = [];
          let bgColors = [];
          for(let i = 0; i < data?.data?.line?.length; i++ ) {
            if(selectedNAQs.length && !selectedNAQs.includes(data.todo_id)) {
              continue;
            }
            let row = data?.data.line[i];
            let precentOfMax = row.percentOfMax;
            let origValue = precentOfMax * chartMax;
            let value = origValue;
            // keeping a reserved space for the danger sign so adding +5
            if((origValue + 5) > chartMaxSuper) {
              value = chartMaxSuper;
            }
            dataset.push(value);
            bgColors.push(getColor(data.todo_id));
            if(precentOfMax == 0.98) {
              dangerLabels[`danger_${i}_${index}`] = dangerSignAnnotation(dataset.length - 1, (origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : value))
            }
          }
          return (
            {
              label: '',
              data: dataset,
              backgroundColor: bgColors,
              borderColor: bgColors,
              pointRadius: hoveredDatasetIndex === index ? 6 : 4,
              pointHoverRadius: hoveredDatasetIndex === index ? 8 : 6,
              skipNull: false,
              fill: true,
              borderWidth: hoveredDatasetIndex === index ? 4 : 3,
            }
          )
        })


        const data = {
          labels: labels,
          datasets: datasets,
        };
        
        // console.log("data", data);
      let xAdjustValue = (function (){
        switch(screenSize) {
          case "extra-small":
            return -15;
          case "small":
            return -18;
          case "medium":
            return -20;
          case "large":
            return -25;
          default:
            return -25;
        }
      })();
      
      let newOptions = {
        ...chartOptions,
        animations: false,
        interaction: {
          mode: "dataset",
          // axis: "xy",
          intersect: false,
        },
        onHover: hoverCallback,
        scales: {
          ...chartOptions.scales,
          x: {
            ...chartOptions.scales.x,
            // display: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
              padding: datasetCount ? datasetCount * 32 : 10,
              color: "#000",
              font: {
                size: 15,
              }
            },
            grid: {
              display: true
            }
          },
        },
        plugins: {
          ...chartOptions.plugins,
          tooltip: {
            enabled: false,
            callbacks: {
                // label: function(context) {
                //   if(chartData) {
                //     let label = chartData.line[context.dataIndex]?.rawTotal;
                //     return label;
                //   }
                // },
                // title: function(context) {
                //   if(chartData) {
                //     let title = chartData.line[context[0].dataIndex]?.title;
                //     return decode(title);
                //   }
                // }
            }
          },
          annotation: {
            ...chartOptions.plugins.annotation,
            annotations: {
              ...chartOptions.plugins.annotation.annotations,
              ...chartLabels,
              ...datasetLines,
              ...dangerLabels,
              label1: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 1.2),
                content: ['Low Priority'],
              },
              label2: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 2),
                content: ['Medium Priority'],
              },
              label3: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax / 1.2,
                content: ['High Priority'],
              },
            }
          }
        }
      }

      setFormattedData(data);
      setLoading(false);
      setOptions(newOptions);
    }
  }, [chartData, screenSize, selectedNAQs, hoveredDatasetIndex]);

  useImperativeHandle(ref, () => {
    return {
      downloadChartImage() {
        if(innerRef.current) {
          const base64Image = innerRef.current.toBase64Image('image/png', 1);
          return base64Image;
        }
      }
    }
  });

  // Handle mouse hover over the chart
  const hoverCallback = useCallback(debounce((event, chartElements) => {
    if (chartElements.length > 0) {
      setHoveredDatasetIndex(chartElements[0].datasetIndex);
    } else {
      setHoveredDatasetIndex(null);
    }
  }, 10), []);

  if(!chartData) {
    return ""
  }

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Line
          key={datasetCount}
          ref={innerRef}
          options={options}
          data={formattedData}
          height={chartHeight}
          plugins={chartPlugins}
        />
      }
    </>
  );
})