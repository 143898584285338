import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { formatBytes } from "helpers/common";

function DropZone(props) {
  const [fileData, setFileData] = useState({
    previewUrl: null,
    url: null,
    extension: "",
    type: "",
    size: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    return () => {
      if(fileData.previewUrl) {
        URL.revokeObjectURL(fileData.previewUrl);
      }
    }
  }, [])

  useEffect(() => {
    if (props.file) {
      setFileData({
        url: props.file.url,
        extension: props.file.extension,
        type: props.file.file_type,
        size: props.file.size,
      });
    }
  }, [props.file]);

  const handleFiles = (files) => {
    const file = files[0];

    if(!file) {
      toast.error(`Please choose a file`);
      return;
    }
    
    if (file.size >= props.maxFileSize) {
      toast.error(`Max file size: 5 MB`);
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!props.allowType?.length || !props.allowType.includes(fileExtension)) {
      setErrorMessage(`Invalid file type. Allowed types: ${props.allowTypeError}`);
      return;
    }
    
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      let previewUrl = URL.createObjectURL(file);
      setFileData({
        previewUrl: previewUrl,
        url: base64Data,
        extension: file.name.split(".").pop().toLowerCase(),
        type: file.type.split("/")[0],
        size: file.size,
      });

      if (props.onChange) {
        props.onChange(base64Data, file, file.name.split(".").pop().toLowerCase(), file.type.split("/")[0]);
      }
    };

    reader.readAsDataURL(file);
    setErrorMessage("");
  };

  const handleClear = () => {
    setFileData({
      url: null,
      extension: "",
      type: "",
      size: "",
    });
    setErrorMessage("");
    if (props.onChange) {
      props.onChange(null, null, null);
    }
  };
  return (
    <>
      <Dropzone onDrop={handleFiles}>
        {({ getRootProps, getInputProps }) => (
          <div
            className="dropzone-single-image avatar-xl"
            onClick={handleClear}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              id="formrow-profile-image-Input"
              multiple={false}
            />

            <div className="uploadFile position-relative py-3 px-lg-4 px-3 text-center bg-white">
              {fileData.url && props.allowType.includes(fileData.extension) ? (
                <div>
                  {fileData.extension === "pdf" ? (
                    <iframe
                      src={fileData.previewUrl}
                      width={"60%"}
                      height={150}
                      title="PDF Viewer"
                    ></iframe>
                  ) : ["jpg", "png", "jpeg"].includes(fileData.extension) ? (
                    <img className="rounded avatar-xl profile mw-100" alt="" src={fileData.url} />
                  ) : (
                    <div>
                      <p className="m-0">Type: {fileData.extension}</p>
                      <p className="m-0">Size: {formatBytes(fileData.size)}</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="content">
                  <div className="imgWrp my-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                    >
                      {/* Your SVG for drop area */}
                    </svg>
                  </div>
                  <p className="m-0 py-2">
                    Drag & drop or click to choose files
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      <br />
      {errorMessage && (
        <div className="error-message-doc">
          {errorMessage}
        </div>
      )}
    </>
  );
}

DropZone.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string,
    extension: PropTypes.string,
    file_type: PropTypes.string,
    size: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default memo(DropZone);
