import React, { useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";

// icn
import { ReactComponent as PlusIcn } from "../../../../../../../Assets/Images/icn/plus.svg";

import { FULL_NAME, IS_BASE64 } from "helpers/common";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { useParams } from "react-router-dom";
import { addClientDocument, deleteClientDocument, listClientDocuments, toggleClientDocumentVisiblity } from "store/services/clientDocumentsService";
import TableData from "./Components/TableData";
import { UploadMediaFile } from "helpers/UploadFile";
import AddClientDocumentModal from "./Components/Modals/AddClientDocumentModal";
import DocumentNotesPopup from "./Components/Modals/DocumentNotesPopup";
import NotificationSettings from "./Components/NotificationSettings";
import { uploadFilePresignedUrl } from "store/services/fileService";

const DocumentsTab = ({
  dispatch,
  clientDetails,
  loadingClientDetails,
  practitionerOwnedDocs,
  clientOwnedDocs,
}) => {
  const { clientId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [showAddDocumentPopup, setShowAddDocumentPopup] = useState(false); 
  const [notesPopup, setNotesPopup] = useState({
    show: false,
    currentDocId: null
  });

  useEffect(() => {
    getClientDocumentsList();
  }, []);

  const getClientDocumentsList = async () => {
    try {
      let data = {
        clientId,
      };
      setLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(listClientDocuments(data));
      setLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      setLoading(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  const addDocument = async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        dispatch(actions.persist_store({ loader: true }));
        let uploadedFileUrl = null;
        if(values.file){
          const payload = {
            file: values.file,
            values: {
              fileName: values?.file_name,
              extension: values.extension,
              folder: "client-documents"
            }
          }
          uploadedFileUrl = await uploadFilePresignedUrl(payload);
        }
  
        if(uploadedFileUrl) {
          let postData = {
            clientId: clientDetails.id,
            values: {
              document_name : values?.document_name,
              document: uploadedFileUrl,
              client_visible : values.client_visible,
              size: values.size,
            }
          }
          let response = await addClientDocument(postData);
          if(response.success === true && response.message) {
            setShowAddDocumentPopup(false);
            SUCCESS_MESSAGE(response.message)
          }
          await getClientDocumentsList();
        }
        setSubmitting(false);
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        console.log(err, "==============================");
        setSubmitting(false);
        dispatch(actions.persist_store({ loader: false }));
      }
    };

  const toggleAddDocumentPopup = () => {
    setShowAddDocumentPopup(!showAddDocumentPopup);
  };

  let clientFullName = () => {
    return FULL_NAME(clientDetails.first_name, clientDetails.last_name);
  }

  const deleteDocument = async (id) => {
    try {
      let confirmed = await CONFIRM_POPUP()
      if (!confirmed) {
        return;
      }

      dispatch(actions.persist_store({ loader: true }));
      await deleteClientDocument(id);
      await getClientDocumentsList();
      dispatch(actions.persist_store({ loader: false }));
      SUCCESS_MESSAGE("Document deleted successfully");
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error removing document", err);
    }
  };

  const handleToggleClientVisibility = async (id, isVisible) => {
    try {
      let payload = {
        id,
        values: {
          client_visible: isVisible
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      await toggleClientDocumentVisiblity(payload);
      await getClientDocumentsList();
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.error("Error updating client visibility", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const openNotesPopup = (docId) => {
    setNotesPopup({
      show: true,
      currentDocId: docId,
    })
  }

  const closeNotesPopup = () => {
    setNotesPopup({
      show: false,
      currentDocId: null,
    })
  }

  return (
    <>
      <AddClientDocumentModal
        showPopup={showAddDocumentPopup}
        setShowPopup={setShowAddDocumentPopup}
        handleSubmit={addDocument}
      />
      <DocumentNotesPopup
        showPopup={notesPopup.show}
        handleClose={closeNotesPopup}
        documentId={notesPopup.currentDocId}
        getDocuments={getClientDocumentsList}
        // handleSubmit={addDocument}
      />
      <div className="top d-flex align-items-start justify-content-between gap-10">
        {(!loadingClientDetails && clientDetails) &&
          <div className="left">
            <h6 className="m-0 fw-bold">
            {clientFullName()}'s Documents
            </h6>
            <p className="m-0">
            Below are the documents that you have uploaded for {clientFullName()}.
            </p>
          </div>
        }
        <div className="right">
          <div className="d-flex gap-10 align-items-center">
            <div className="d-flex gap-10 me-3">
              <NotificationSettings />
            </div>
            <Button
              onClick={toggleAddDocumentPopup}
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
            >
              <span className="icn me-2">
                <PlusIcn />
              </span>
              Add a new Document
            </Button>
          </div>
        </div>
      </div>
      <div className="CardBody py-3">
        <div className="commonCardBox">
          <TableData
            data={practitionerOwnedDocs}
            onClickRemove={deleteDocument}
            handleToggleVisiblity={handleToggleClientVisibility}
            owner={"practitioner"}
            isLoading={isLoading}
            handleEditNotes={openNotesPopup}
          />
          {/* <p className="text-end m-0 py-2 text-dark fw-sbold">
            Do you have a previous form from this client that you'd like to
            manually enter? You can add it on the <Link onClick={openAddDataTab}>Add Data</Link> page.
          </p> */}
        </div>

        {/* Documents uploaded by Practitioner */}
        <div className="mt-5">
          <div className="top d-flex align-items-start justify-content-between gap-10">
            <div className="left">
              <h6 className="m-0 fw-bold">
                Documents uploaded by {clientFullName()}
              </h6>
              <p className="m-0">
                Below are the documents uploaded by {clientFullName()}
              </p>
            </div>
          </div>
          <div className="commonCardBox">
            <TableData
              data={clientOwnedDocs}
              onClickRemove={deleteDocument}
              owner={"client"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  let allDocuments = state.Client.client.documents;

  return {
    clientDetails: state.Client.client.details,
    loadingClientDetails: state.Client.loadingClientDetails,
    practitionerOwnedDocs: allDocuments?.practitioner_owned,
    clientOwnedDocs: allDocuments?.client_owned,
  };
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DocumentsTab);