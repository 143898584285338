import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

const AppCKEditor = ({ value, onChange }) => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, [])
  
  return (
    isLoaded ? (
      <div className="ck-editor-field">
        <CKEditor
          editor={ Editor }
          data={value || ""}
          config={{
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'fontColor', 'fontBackgroundColor', 'fontSize', 'bulletedList', 'numberedList' ],
          }}
          onChange={(_, editor) => {
            onChange(editor.getData())
          }}
        />
      </div>
    ) : null
  )
}

export default AppCKEditor;