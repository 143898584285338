import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { MAX_SELECT_GRAPH_COMPARISION } from "common/constants";

const SelectNAQs = ({ naqList, onChange, maxSelectable = 2, heading = "" }) => {
  const [ selectedNAQs, setSelectedNAQs ] = useState([]);

  const computedHeading = useMemo(() => {
    switch(maxSelectable) {
      case 2:
        return `Select two NAQs to compare:`;
      case 4:
        return `Select up to four NAQs to compare:`;

      default:
        return `Select up to ${maxSelectable} NAQs to compare:`
    }
  }, [maxSelectable]);

  useEffect(() => {
    let todoIds = naqList && naqList.slice(0, 2).map(naq => naq.id);
    setSelectedNAQs(todoIds);
    onChange(todoIds);
  }, [naqList]);

  const handleChangeSelectedNAQ = (e) => {
    let targetValue = parseInt(e.target.value);
    const prevSelected = [...selectedNAQs];
    let isSelected = prevSelected.find(value => value == targetValue);
    let newValues = [];
    if(isSelected) {
      newValues = prevSelected.filter(value => value != targetValue);
    } else {
      prevSelected.push(targetValue);
      newValues = prevSelected;
    }

    // get the last four values, can only select four items
    if(newValues.length > maxSelectable) {
      newValues = newValues.slice(-maxSelectable);
    }

    setSelectedNAQs(newValues);
    onChange(newValues);
  }

  return (
    <div className="py-2">
      <h6 style={{ textTransform: "none" }}>{computedHeading}</h6>
      {naqList && naqList.length > 0 &&
        naqList.map(naq => {
          return (
            <label
              className="mx-2"
              style={{ borderBottom: `4px solid ${naq.color}` }}
            >
              <input
                className=""
                type="checkbox"
                value={naq.id}
                onChange={handleChangeSelectedNAQ}
                checked={selectedNAQs.includes(naq.id)}
              />
              <span className="px-2">{moment(naq.updated_at).format("MMM DD, YYYY")}</span>
            </label>
          )
        })
      }
    </div>
  )
}

export default SelectNAQs;