import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { ReactComponent as InfoIcn } from "Assets/Images/icn/infoI.svg";
import { getRecommendationReportV2 } from "store/services/clientDataReportsService";
import { Link, useParams } from "react-router-dom";
import { decode } from "html-entities";
import QuestionAnsPopup from "./Components/QuestionAnsPopup";
import DownloadToDoNAQRecommPDF from "../PDFReportComponents/ToDoNAQRecommReportPDF";

const RecommendationReport = ({ eventKey, todoId, client, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendationData, setRecommendationData] = useState(null);
  // const [nutritionalInformation, setNutritionalInformation] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState("generic");
  const [showQueAnsPopup, setShowQueAnsPopup] = useState(false);
  const [queAnsPopupData, setQueAnsPopupData] = useState({
    supportText: "",
    questionIds: [],
    answers: [],
  });

  useEffect(() => {
    if (isOpen && !recommendationData) {
      getRecommendationReportData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedManufacturer && recommendationData) {
      const filteredProducts = recommendationData[selectedManufacturer] || [];

      setProducts(filteredProducts);
    }
  }, [selectedManufacturer, recommendationData])

  const getRecommendationReportData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      const response = await getRecommendationReportV2(data);
      if (response.success === true) {
        const data = response.body;
        setRecommendationData(data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const openQuestionAnswersPopup = (supportText, questionIds, answers) => {
    setQueAnsPopupData({
      supportText,
      questionIds,
      answers,
    });
    setShowQueAnsPopup(true);
  }

  const closeQuestionAnswersPopup = () => {
    setShowQueAnsPopup(false);
    setQueAnsPopupData({
      supportText: "",
      questionIds: [],
      answers: [],
    });
  }

  return (
    <>
    <QuestionAnsPopup
      showPopup={showQueAnsPopup}
      closePopup={closeQuestionAnswersPopup}
      supportText={queAnsPopupData.supportText}
      questionIds={queAnsPopupData.questionIds}
      answers={queAnsPopupData.answers}
    />
    <Accordion.Item eventKey={eventKey} className="recommendation-report my-2">
      <Accordion.Header
       className="accordian-cap "
      >
        Recommendation Report
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        {isLoading ?
          <p>Loading...</p>
          :
          <div className="accordion-inner">
            <div className="py-2">
              <div className="top d-flex align-items-center justify-content-between gap-10">
                <div className="left">
                  <p className="m-0">
                    The following supplements are based on the potential conditions
                    and nutritional deficiencies shown in the Symptom Burden Report.
                  </p>
                </div>
                <div className="right">
                  <DownloadToDoNAQRecommPDF
                    todoId={todoId}
                    client={client}
                    date={submittedOn}
                    manufacturer={selectedManufacturer}
                    label={"Download Report"}
                    linkType={"button"}
                  />
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="top d-flex align-items-center justify-content-between gap-10">
                <div className="left d-flex align-items-center gap-10">
                  <p className="m-0 capitalize fw-bold">Supplement Brand :</p>
                  <Form.Select
                    className="form-control fw-sbold btnSm"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedManufacturer(e.target.value);
                    }}
                    value={selectedManufacturer}
                  >
                    <option value="generic">Generic</option>
                    <option value="biotics">Biotics</option>
                    <option value="wise_woman">Wise Woman Herbals</option>
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="commonCardBox">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      {" "}
                      <tr>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Product
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Upon Rising
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          With Breakfast
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Mid Morning
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          With Lunch
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Mid Afternoon
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          With Dinner
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Before Bed
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Instructions / Notes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products && products.length > 0 ?
                        products.map((product) => {
                          return (
                            <tr>
                              <td className="p-3 border-0">
                                <b>
                                  {decode(product.productName)}
                                </b>
                              </td>
                              <td className="p-3 border-0">{product.dosageUponRising}</td>
                              <td className="p-3 border-0">{product.dosageBreakfast}</td>
                              <td className="p-3 border-0">{product.dosageMidMorning}</td>
                              <td className="p-3 border-0">{product.dosageLunch}</td>
                              <td className="p-3 border-0">{product.dosageMidAfternoon}</td>
                              <td className="p-3 border-0">{product.dosageDinner}</td>
                              <td className="p-3 border-0">{product.dosageBeforeBed}</td>
                              <td className="p-3 border-0">
                                <b>
                                  {product.instructions}
                                </b>
                                <p className="m-0 py-1">
                                  Conditions/Deficiencies: {product?.conditionNames} *
                                </p>
                              </td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td colSpan={11} className="p-3 border-0">Data not available</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div
                className="Note px-3 py-2 commonCardBox d-flex align-items-start"
                style={{ background: "rgb(245, 245, 245)" }}
              >
                <div className="imgWrp me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_0_5147)">
                      <path
                        d="M8.34343 0.15332C4.20184 0.15332 0.84375 3.51142 0.84375 7.653C0.84375 11.7946 4.20184 15.1533 8.34343 15.1533C12.485 15.1533 15.8437 11.7946 15.8437 7.653C15.8437 3.51142 12.485 0.15332 8.34343 0.15332ZM9.9047 11.7768C9.51867 11.9292 9.21137 12.0447 8.98089 12.1247C8.72237 12.2092 8.45154 12.2497 8.17962 12.2447C7.71232 12.2447 7.34851 12.1305 7.08946 11.9025C6.83042 11.6746 6.70153 11.3857 6.70153 11.0346C6.70153 10.8981 6.71105 10.7584 6.7301 10.6162C6.75364 10.4542 6.78479 10.2934 6.82343 10.1343L7.30661 8.4276C7.34915 8.26379 7.38597 8.10824 7.41518 7.96348C7.44438 7.81745 7.45835 7.68348 7.45835 7.56157C7.45835 7.34443 7.41327 7.19205 7.32375 7.10634C7.23296 7.02062 7.06216 6.97872 6.80756 6.97872C6.68311 6.97872 6.55486 6.99713 6.42343 7.03586C6.29327 7.07586 6.18026 7.11205 6.08756 7.1476L6.21518 6.62189C6.53137 6.493 6.83423 6.38253 7.12311 6.2911C7.38729 6.20271 7.66362 6.15602 7.94216 6.15268C8.40629 6.15268 8.76438 6.2657 9.01645 6.48919C9.26724 6.71332 9.39359 7.00475 9.39359 7.36284C9.39359 7.43713 9.3847 7.56792 9.36756 7.75459C9.35266 7.92902 9.32033 8.10152 9.27105 8.26951L8.79042 9.9711C8.74734 10.125 8.71195 10.281 8.68438 10.4384C8.65821 10.5694 8.64271 10.7023 8.63803 10.8359C8.63803 11.0619 8.68819 11.2162 8.78978 11.2981C8.8901 11.38 9.06597 11.4213 9.31486 11.4213C9.43232 11.4213 9.56375 11.4003 9.71232 11.3597C9.85962 11.319 9.96629 11.2828 10.0336 11.2517L9.9047 11.7768ZM9.81962 4.87014C9.60108 5.07623 9.31043 5.18839 9.0101 5.18253C8.69518 5.18253 8.42343 5.0784 8.1974 4.87014C8.08991 4.77559 8.00405 4.659 7.94565 4.52829C7.88725 4.39759 7.8577 4.25584 7.85899 4.11268C7.85899 3.81745 7.97327 3.56348 8.1974 3.35332C8.41641 3.14559 8.70829 3.03226 9.0101 3.03776C9.32565 3.03776 9.59613 3.14253 9.81962 3.35332C10.0437 3.56348 10.1561 3.81745 10.1561 4.11268C10.1561 4.40919 10.0437 4.66189 9.81962 4.87014Z"
                        fill="#030104"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_0_5147">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.84375 0.15332)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="content">
                  <p className="m-0 text-muted">
                    <i>
                      *Statements made on this site and reference materials have not
                      been evaluated by the Food and Drug Administration. These
                      products or protocols may be helpful in correcting the
                      imbalances associated with the potential
                      conditions/deficiencies related to your client's symptoms.
                      These products or protocols are not intended to diagnose,
                      treat, cure or prevent any disease or be used as a substitute
                      for appropriate care of a qualified health practitioner.
                    </i>
                  </p>
                </div>
              </div>
            </div>
            {recommendationData?.topPotentialSupports && 
              <>
                <div className="py-2 mt-3 border-top">
                  <div className="top d-flex align-items-center justify-content-between gap-10">
                    <div className="left">
                      <p className="capitalize text-dark fw-sbold m-0">
                        Top Potential Supports
                      </p>
                      <p className="m-0 py-2">
                        The below list of Potential Supports looks across your client's
                        questions/answers from this NAQ and it shows the most frequently
                        listed supports for those questions. Unlike the Recommendation
                        report, this list is completely based off the individual
                        questions and not the Potential Conditions/Deficiencies in the
                        Symptom Burden Report.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <Row>
                    <Col lg="6" className="my-2 py-3">
                      <p className="capitalize m-0 text-dark fw-bold">NUTRIENTS</p>
                      <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                        {recommendationData &&
                          recommendationData.topPotentialSupports &&
                          recommendationData.topPotentialSupports.nutrients &&
                          recommendationData.topPotentialSupports.nutrients.length > 0 &&
                          recommendationData.topPotentialSupports.nutrients.map(nutrient => {
                            return (
                              <li className="">
                                {decode(nutrient.supportText)}{" "}
                                <Link
                                  onClick={() => {
                                    openQuestionAnswersPopup(nutrient.supportText, nutrient.relatedQuestionIDs, nutrient.answerQuestions);
                                  }}
                                >
                                  <span className="icn ms-1">
                                    <InfoIcn />
                                  </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Col>
                    <Col lg="6" className="my-2 py-3">
                      <p className="capitalize m-0 text-dark fw-bold">
                        Supplements
                      </p>
                      <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                        {recommendationData &&
                          recommendationData.topPotentialSupports &&
                          recommendationData.topPotentialSupports.supplements &&
                          recommendationData.topPotentialSupports.supplements.length > 0 &&
                          recommendationData.topPotentialSupports.supplements.map(supplement => {
                            return (
                              <li className="">
                                {decode(supplement.supportText)}{" "}
                                <Link
                                  onClick={() => {
                                    openQuestionAnswersPopup(supplement.supportText, supplement.relatedQuestionIDs, supplement.answerQuestions);
                                  }}
                                >
                                  <span className="icn ms-1">
                                    <InfoIcn />
                                  </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Col>
                    <Col lg="6" className="my-2 py-3">
                      <p className="capitalize m-0 text-dark fw-bold">Foods</p>
                      <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                        {recommendationData &&
                          recommendationData.topPotentialSupports &&
                          recommendationData.topPotentialSupports.foods &&
                          recommendationData.topPotentialSupports.foods.length > 0 &&
                          recommendationData.topPotentialSupports.foods.map(food => {
                            return (
                              <li className="">
                                {decode(food.supportText)}{" "}
                                <Link
                                  onClick={() => {
                                    openQuestionAnswersPopup(food.supportText, food.relatedQuestionIDs, food.answerQuestions);
                                  }}
                                >
                                  <span className="icn ms-1">
                                    <InfoIcn />
                                  </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Col>
                    <Col lg="6" className="my-2 py-3">
                      <p className="capitalize m-0 text-dark fw-bold">Lifestyle</p>
                      <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                        {recommendationData &&
                          recommendationData.topPotentialSupports &&
                          recommendationData.topPotentialSupports.lifestyle &&
                          recommendationData.topPotentialSupports.lifestyle.length > 0 &&
                          recommendationData.topPotentialSupports.lifestyle.map(lifestyleIdea => {
                            return (
                              <li className="">
                                {decode(lifestyleIdea.supportText)}{" "}
                                <Link
                                  onClick={() => {
                                    openQuestionAnswersPopup(lifestyleIdea.supportText, lifestyleIdea.relatedQuestionIDs, lifestyleIdea.answerQuestions);
                                  }}
                                >
                                  <span className="icn ms-1">
                                    <InfoIcn />
                                  </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Col>
                  </Row>
                </div>
              </>
            }
          </div>
        }
      </Accordion.Body>
    </Accordion.Item>
    </>
  )

  return (
    <>
      <div className="recommendation-report">
        <h4>Recommendation Report</h4>
        <div className="py-2">
          <div className="top d-flex align-items-center justify-content-between gap-10">
            <div className="left">
              <p className="m-0">
                The following supplements are based on the potential conditions
                and nutritional deficiencies shown in the Symptom Burden Report.
              </p>
            </div>
            <div className="right">
              <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
                <span className="icn me-2">
                  <DownloadIcn />
                </span>
                Download Report
              </Button>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="top d-flex align-items-center justify-content-between gap-10">
            <div className="left d-flex align-items-center gap-10">
              <p className="m-0 capitalize fw-bold">Supplement Brand :</p>
              <Form.Select
                className="form-control fw-sbold btnSm"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedManufacturer(e.target.value);
                }}
              >
                <option value="1">Generic</option>
                <option value="2">Biotics</option>
                <option value="3">Wise Woman Herbals</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  {" "}
                  <tr>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Product
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Upon Rising
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      With Breakfast
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Mid Morning
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      With Lunch
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Mid Afternoon
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      With Dinner
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Before Bed
                    </th>
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Instructions / Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products && products.length > 0 ?
                    products.map((product) => {
                      return (
                        <tr>
                          <td className="p-3 border-0">
                            <b>
                              {decode(product.productName)}
                            </b>
                          </td>
                          <td className="p-3 border-0">{product.dosageUponRising}</td>
                          <td className="p-3 border-0">{product.dosageBreakfast}</td>
                          <td className="p-3 border-0">{product.dosageMidMorning}</td>
                          <td className="p-3 border-0">{product.dosageLunch}</td>
                          <td className="p-3 border-0">{product.dosageMidAfternoon}</td>
                          <td className="p-3 border-0">{product.dosageDinner}</td>
                          <td className="p-3 border-0">{product.dosageBeforeBed}</td>
                          <td className="p-3 border-0">
                            <b>
                              {product.instructions}
                            </b>
                            <p className="m-0 py-1">
                              Conditions/Deficiencies: {product.conditions.map(condition => condition)} *
                            </p>
                          </td>
                        </tr>
                      )
                    })
                    :
                    <tr>
                      <td colSpan={11} className="p-3 border-0">Data not available</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div
            className="Note px-3 py-2 commonCardBox d-flex align-items-start"
            style={{ background: "rgb(245, 245, 245)" }}
          >
            <div className="imgWrp me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_0_5147)">
                  <path
                    d="M8.34343 0.15332C4.20184 0.15332 0.84375 3.51142 0.84375 7.653C0.84375 11.7946 4.20184 15.1533 8.34343 15.1533C12.485 15.1533 15.8437 11.7946 15.8437 7.653C15.8437 3.51142 12.485 0.15332 8.34343 0.15332ZM9.9047 11.7768C9.51867 11.9292 9.21137 12.0447 8.98089 12.1247C8.72237 12.2092 8.45154 12.2497 8.17962 12.2447C7.71232 12.2447 7.34851 12.1305 7.08946 11.9025C6.83042 11.6746 6.70153 11.3857 6.70153 11.0346C6.70153 10.8981 6.71105 10.7584 6.7301 10.6162C6.75364 10.4542 6.78479 10.2934 6.82343 10.1343L7.30661 8.4276C7.34915 8.26379 7.38597 8.10824 7.41518 7.96348C7.44438 7.81745 7.45835 7.68348 7.45835 7.56157C7.45835 7.34443 7.41327 7.19205 7.32375 7.10634C7.23296 7.02062 7.06216 6.97872 6.80756 6.97872C6.68311 6.97872 6.55486 6.99713 6.42343 7.03586C6.29327 7.07586 6.18026 7.11205 6.08756 7.1476L6.21518 6.62189C6.53137 6.493 6.83423 6.38253 7.12311 6.2911C7.38729 6.20271 7.66362 6.15602 7.94216 6.15268C8.40629 6.15268 8.76438 6.2657 9.01645 6.48919C9.26724 6.71332 9.39359 7.00475 9.39359 7.36284C9.39359 7.43713 9.3847 7.56792 9.36756 7.75459C9.35266 7.92902 9.32033 8.10152 9.27105 8.26951L8.79042 9.9711C8.74734 10.125 8.71195 10.281 8.68438 10.4384C8.65821 10.5694 8.64271 10.7023 8.63803 10.8359C8.63803 11.0619 8.68819 11.2162 8.78978 11.2981C8.8901 11.38 9.06597 11.4213 9.31486 11.4213C9.43232 11.4213 9.56375 11.4003 9.71232 11.3597C9.85962 11.319 9.96629 11.2828 10.0336 11.2517L9.9047 11.7768ZM9.81962 4.87014C9.60108 5.07623 9.31043 5.18839 9.0101 5.18253C8.69518 5.18253 8.42343 5.0784 8.1974 4.87014C8.08991 4.77559 8.00405 4.659 7.94565 4.52829C7.88725 4.39759 7.8577 4.25584 7.85899 4.11268C7.85899 3.81745 7.97327 3.56348 8.1974 3.35332C8.41641 3.14559 8.70829 3.03226 9.0101 3.03776C9.32565 3.03776 9.59613 3.14253 9.81962 3.35332C10.0437 3.56348 10.1561 3.81745 10.1561 4.11268C10.1561 4.40919 10.0437 4.66189 9.81962 4.87014Z"
                    fill="#030104"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_0_5147">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="translate(0.84375 0.15332)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="content">
              <p className="m-0 text-muted">
                <i>
                  *Statements made on this site and reference materials have not
                  been evaluated by the Food and Drug Administration. These
                  products or protocols may be helpful in correcting the
                  imbalances associated with the potential
                  conditions/deficiencies related to your client's symptoms.
                  These products or protocols are not intended to diagnose,
                  treat, cure or prevent any disease or be used as a substitute
                  for appropriate care of a qualified health practitioner.
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className="py-2 mt-3 border-top">
          <div className="top d-flex align-items-center justify-content-between gap-10">
            <div className="left">
              <p className="capitalize text-dark fw-sbold m-0">
                Top Potential Supports
              </p>
              <p className="m-0 py-2">
                The below list of Potential Supports looks across your client's
                questions/answers from this NAQ and it shows the most frequently
                listed supports for those questions. Unlike the Recommendation
                report, this list is completely based off the individual
                questions and not the Potential Conditions/Deficiencies in the
                Symptom Burden Report.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <Row>
            <Col lg="6" className="my-2 py-3">
              <p className="capitalize m-0 text-dark fw-bold">NUTRIENTS</p>
              <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                <li className="">
                  Nutrients to support bile flow: beet juice, taurine, vitamin C
                  and pancrelipase{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Betaine HCL, pepsin, and pancreatin{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Nutrients that heal the intestines{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Herbs that cleanse the liver{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Micro emulsified oregano{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
              </ul>
            </Col>
            <Col lg="6" className="my-2 py-3">
              <p className="capitalize m-0 text-dark fw-bold">
                Supplements
              </p>
              <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                <li className="">
                  Biotics Beta TCP
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Biotics Hydro-Zyme{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Biotics GI Resolve{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Biotics MCS-2{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Biotics ADP{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
              </ul>
            </Col>
            <Col lg="6" className="my-2 py-3">
              <p className="capitalize m-0 text-dark fw-bold">Foods</p>
              <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                <li className="">
                  Beets
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Vitamin C-rich Foods (Citrus fruits, Peaches, Rose Hips,
                  Cherries, Peppers, Strawberries, Broccoli, Sardines, Organ
                  Meats){" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Whole Eggs{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Salmon{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Dark Green Leafy Vegetables{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
              </ul>
            </Col>
            <Col lg="6" className="my-2 py-3">
              <p className="capitalize m-0 text-dark fw-bold">Lifestyle</p>
              <ul className=" mb-0" style={{ maxWidth: "300px" }}>
                <li className="">
                  Support hydration and electrolyte balance (away from meals)
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Chew food well{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Support digestion{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Take a few deep breaths before eating{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
                <li className="">
                  Cook with fresh or dried oregano{" "}
                  <span className="icn ms-1">
                    <InfoIcn />
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default RecommendationReport;
