import { postData, postMediaData } from 'helpers/apiHelper'
import { MAIN_PATH, UPLOAD_IMAGE_PATH, DELETE_IMAGE_PATH, GET_PRESIGNED_URL } from './apiPath'
import { deleteUploadImage, } from 'store/actions/file';
import Axios from "axios";
import { ERROR_MESSAGE } from "common/constantMessage";

//upload image
export function uploadImage_(value, callback) {
  return postMediaData(`${MAIN_PATH}${UPLOAD_IMAGE_PATH}`, value)
}

//delete image
export function deleteUploadImage_(value, callback) {
  return (dispatch) => postMediaData(`${MAIN_PATH}${DELETE_IMAGE_PATH}`, value)
    .then((data) => {
      dispatch(deleteUploadImage(data, callback));
      return data;
    });
}


// upload image s3 pre-signed url
export async function uploadFilePresignedUrl(payload, callback) {
  try {
    const { file, values } = payload;
    
    let response = await postData(`${MAIN_PATH}${GET_PRESIGNED_URL}`, values);
    const url = response?.body?.url;
    if(!url) {
      throw new Error("Failed to generate presign url");
    }

    const fileUrl = url.split("?")[0];

    await Axios.put(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    return fileUrl
  } catch(error) {
    ERROR_MESSAGE("Failed to upload file");
    throw error;
  }
}