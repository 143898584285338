import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { getSymptomBurdenReportV2 } from "store/services/clientDataReportsService";
import { useParams } from "react-router-dom";
import DownloadSBReportLink from "../PDFReportComponents/SBReportPDF";

const SymptomBurdonReport = ({ eventKey, todoId, client, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [symptomBurdernData, setSymptomBurdenData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalSymptomBurden, setTotalSymptomBurden] = useState(0);

  useEffect(() => {
    if (isOpen && !symptomBurdernData) {
      getSymptomBurdenData();
    }
  }, [isOpen])

  useEffect(() => {
    if (symptomBurdernData) {
      const symptomData = { ...symptomBurdernData };
      let totalSymptomBurden = 0;
      symptomData.potential_conditions.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurden += parseInt(condition.conditionScore);
        }
      })
      symptomData.potential_nutritional_deficiencies.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurden += parseInt(deficiency.conditionScore);
        }
      })
      setTotalSymptomBurden(totalSymptomBurden);
    }
  }, [symptomBurdernData])

  const getSymptomBurdenData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      const response = await getSymptomBurdenReportV2(data);
      if (response.success === true) {
        const data = response.body;
        setSymptomBurdenData(data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <Accordion.Item eventKey={eventKey} className="symptom-burden-report my-2">
      <Accordion.Header
       className="accordian-cap "
      >
        Symptom Burden Report
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        {isLoading ?
          <p>Loading...</p>
          :
          <>
            <Row>
              <Col lg="12">
                <div className="top d-flex align-items-center justify-content-between gap-10">
                  <div className="left">
                    <h6 className="m-0 capitalize">Total Symptom Burden: {totalSymptomBurden}</h6>
                  </div>
                  <div className="right">
                    <DownloadSBReportLink
                      todoId={todoId}
                      client={client}
                      submittedOn={submittedOn}
                      label={"Download Report"}
                      linkType={"button"}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="InfoSetting">
              <Col lg="6" md="6" className="my-2">
                <div
                  className="commonCardBox MainCard h-100"
                  style={{ borderColor: "#405C40" }}
                >
                  <div
                    className="top px-lg-4 px-3 py-2 commonContent"
                    style={{ background: "#405C40" }}
                  >
                    <h3 className="m-0 text-white">Potential Conditions</h3>
                  </div>
                  <div className="CardBody noLastBorder">
                    <div className="px-lg-4 p-3">
                      <p className="m-0">
                        Based on the results from the Nutritional Assessment
                        Questionnaire, the following conditions may apply to your
                        client.
                      </p>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Condition Name{" "}
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Score{" "}
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Total Possible{" "}
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Percentage{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(symptomBurdernData && symptomBurdernData.potential_conditions.length > 0) ?
                              symptomBurdernData.potential_conditions.map((condition) => {
                                return (
                                  <tr>
                                    <td className="p-2 border-0">
                                      <span className="text-dark fw-sbold">
                                        {condition.conditionName}
                                      </span>
                                    </td>
                                    <td className="p-2 border-0">{condition.conditionScore}</td>
                                    <td className="p-2 border-0">{condition.conditionTotalPossible}</td>
                                    <td className="p-2 border-0">{Math.round(condition.conditionScorePercentage)}%</td>
                                  </tr>
                                )
                              })
                              :
                              <tr>
                                <td className="text-center" colSpan={4}>Data not available</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6" className="my-2">
                <div
                  className="commonCardBox MainCard h-100"
                  style={{ borderColor: "#405C40" }}
                >
                  <div
                    className="top px-lg-4 px-3 py-2 commonContent"
                    style={{ background: "#405C40" }}
                  >
                    <h3 className="m-0 text-white">
                      Potential Nutritional Deficiencies
                    </h3>
                  </div>
                  <div className="CardBody noLastBorder">
                    <div className="px-lg-4 p-3">
                      <p className="m-0">
                        Based on the results from this Nutritional Assessment
                        Questionnaire, your client may have the following
                        nutritional deficiencies.
                      </p>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Deficiency
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Score{" "}
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Total Possible{" "}
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Percentage{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(symptomBurdernData && symptomBurdernData.potential_nutritional_deficiencies.length > 0) ?
                              symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
                                return (
                                  <tr>
                                    <td className="p-2 border-0">
                                      <span className="text-dark fw-sbold">
                                        {deficiency.conditionName}
                                      </span>
                                    </td>
                                    <td className="p-2 border-0">{deficiency.conditionScore}</td>
                                    <td className="p-2 border-0">{deficiency.conditionTotalPossible}</td>
                                    <td className="p-2 border-0">{Math.round(deficiency.conditionScorePercentage)}%</td>
                                  </tr>
                                )
                              })
                              :
                              <tr>
                                <td className="text-center" colSpan={4}>Data not available</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      </Accordion.Body>
    </Accordion.Item>
  )

  return (
    <>
      <div className="symptom-burden-report py-2">
        <h4>Symptom Burden Report</h4>
        <div className="top d-flex align-items-center justify-content-between gap-10">
          <div className="left">
            <h6 className="m-0 capitalize">Total Symptom Burden: {totalSymptomBurden}</h6>
          </div>
          <div className="right">
            <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
              <span className="icn me-2">
                <DownloadIcn />
              </span>
              Download Report
            </Button>
          </div>
        </div>
        <Row className="InfoSetting">
          <Col lg="6" md="6" className="my-2">
            <div
              className="commonCardBox MainCard h-100"
              style={{ borderColor: "#405C40" }}
            >
              <div
                className="top px-lg-4 px-3 py-2 commonContent"
                style={{ background: "#405C40" }}
              >
                <h3 className="m-0 text-white">Potential Conditions</h3>
              </div>
              <div className="CardBody noLastBorder">
                <div className="px-lg-4 p-3">
                  <p className="m-0">
                    Based on the results from the Nutritional Assessment
                    Questionnaire, the following conditions may apply to your
                    client.
                  </p>
                </div>
                <div className="">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Food Name{" "}
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Score{" "}
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Total Possible{" "}
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Percentage{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(symptomBurdernData && symptomBurdernData.potential_conditions.length > 0) ?
                          symptomBurdernData.potential_conditions.map((condition) => {
                            return (
                              <tr>
                                <td className="p-2 border-0">
                                  <span className="text-dark fw-sbold">
                                    {condition.condition_name}
                                  </span>
                                </td>
                                <td className="p-2 border-0">{condition.conditionScore}</td>
                                <td className="p-2 border-0">{condition.conditionTotalPossible}</td>
                                <td className="p-2 border-0">{Math.round(condition.conditionScorePercentage)}%</td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td className="text-center" colSpan={4}>Data not available</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" className="my-2">
            <div
              className="commonCardBox MainCard h-100"
              style={{ borderColor: "#405C40" }}
            >
              <div
                className="top px-lg-4 px-3 py-2 commonContent"
                style={{ background: "#405C40" }}
              >
                <h3 className="m-0 text-white">
                  Potential Nutritional Deficiencies
                </h3>
              </div>
              <div className="CardBody noLastBorder">
                <div className="px-lg-4 p-3">
                  <p className="m-0">
                    Based on the results from this Nutritional Assessment
                    Questionnaire, your client may have the following
                    nutritional deficiencies.
                  </p>
                </div>
                <div className="">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Deficiency
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Score{" "}
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Total Possible{" "}
                          </th>
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Percentage{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(symptomBurdernData && symptomBurdernData.potential_nutritional_deficiencies.length > 0) ?
                          symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
                            return (
                              <tr>
                                <td className="p-2 border-0">
                                  <span className="text-dark fw-sbold">
                                    {deficiency.condition_name}
                                  </span>
                                </td>
                                <td className="p-2 border-0">{deficiency.conditionScore}</td>
                                <td className="p-2 border-0">{deficiency.conditionTotalPossible}</td>
                                <td className="p-2 border-0">{Math.round(deficiency.conditionScorePercentage)}%</td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td className="text-center" colSpan={4}>Data not available</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SymptomBurdonReport;
