import React from 'react';
import { useField } from 'formik';

const AppInputField = ({ name, fieldType, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      {fieldType === "textarea" ? (
        <textarea
          aria-invalid={!!meta.error}
          {...field}
          {...props}
        ></textarea>
      ) : (
        <input
          aria-invalid={!!meta.error}
          {...field}
          {...props}
        />
      )}
      {meta.touched && meta.error && (
        <p className="invalid-feedback d-block">{meta.error}</p>
      )}
    </>
  );
};

export default AppInputField;