import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Naqv2SymptomGraph from "./Component/TabContent/NAQGraph/Index";
import Naqv2SymptomReport from "./Component/TabContent/NAQReport/Index";
import PriorityComparisionReport from "./Component/TabContent/PriorityComparsionReport/Index";
import { getCompletedTodoListV2 } from "store/services/clientDataReportsService";
import * as actions from "store/actions";

const DataAnalysisV2 = ({ dispatch }) => {
  const { clientId } = useParams();
  const [naqList, setNaqList] = useState(null);

  // get completed NAQ list
  useEffect(() => {
    getCompletedNAQList();
  }, []);

  const getCompletedNAQList = async () => {
    try {
      let data = {
        clientId: clientId
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getCompletedTodoListV2(data);
      dispatch(actions.persist_store({ loader: false }));
      if( response.success === true && response.body.length > 0) {
        setNaqList(response.body)
      }
    } catch(error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
    {(naqList && naqList.length > 0) ? (
      <Tab.Container id="left-tabs-example" defaultActiveKey="Graph">
        <div className="top px-lg-4 py-3">
          <Nav variant="pills" className="commonBtnTabs">
            <Nav.Item className="px-2">
              <Nav.Link
                className="fw-sbold capitalize py-2"
                eventKey="Graph"
              >
                NAQ v2: Symptom Burden Comparison Graph
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="px-2">
              <Nav.Link
                className="fw-sbold capitalize py-2"
                eventKey="Report"
              >
                NAQ v2: Symptom Burden Comparison Report
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="px-2">
              <Nav.Link
                className="fw-sbold capitalize py-2"
                eventKey="Severity"
              >
                NAQ v2: Priority Comparison Report
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="CardBody p-3 px-lg-4">
          {(naqList && naqList.length > 0) ?
            <Tab.Content>
              <Tab.Pane eventKey="Graph" mountOnEnter={true}>
                <Naqv2SymptomGraph naqList={naqList} />
              </Tab.Pane>
              <Tab.Pane eventKey="Report" mountOnEnter={true}>
                <Naqv2SymptomReport naqList={naqList} />
              </Tab.Pane>
              <Tab.Pane eventKey="Severity" mountOnEnter={true}>
                <PriorityComparisionReport naqList={naqList} />
              </Tab.Pane>
            </Tab.Content>
          :
            <p className="fw-sbold">Insufficient NAQ submissions. Compare feature requires two or more NAQs for analysis.</p>
          }
          
        </div>
      </Tab.Container>
    ):(
      <p className="fw-sbold">Insufficient NAQ submissions. Compare feature requires two or more NAQs for analysis.</p>
    )}
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DataAnalysisV2);