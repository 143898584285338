import React, { useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "store/actions";
import { useParams } from "react-router-dom";
import TableData from "./Components/TableData"
import { deleteClientNote, getClientNotesList } from "store/services/clientNotesService";
import AddNotesPopup from "./Components/Modals/AddNotesPopup";
import { ReactComponent as PlusIcn } from "../../../../../../../Assets/Images/icn/plus.svg";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import ReactPagination from "components/shared/ReactPagination";
import { DATE_FORMAT_TIME, FULL_NAME } from "helpers/common";
import CommonTable from "components/shared/CommonTable";
import ShowMoreText from "components/shared/ShowMoreText";

const ClientNotes = ({
  dispatch,
  notes,
  itemsCountPerPage,
  totalItemsCount,
  loadingClientDetails,
  clientDetails,
}) => {
  const { clientId } = useParams();
  const [activePage, setActivePage] = useState(1);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    orderBy: "created_at",
    order: "asc",
  });

  useEffect(() => {
    getNotes(activePage);
  }, [activePage, sortConfig]);

  // useEffect(() => {
  //   getNotes(activePage);
  // }, [sortConfig]);

  const getNotes = async (page) => {
    try {
      let payload = {
        clientId,
        params: {
          page,
          limit: itemsCountPerPage,
          orderBy: sortConfig.orderBy,
          order: sortConfig.order,
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientNotesList(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("Error loading food mood logs", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const deleteNote = async (noteId) => {
    try {
      let isConfirm = await CONFIRM_POPUP();
      if(!isConfirm) {
        return;
      }

      dispatch(actions.persist_store({ loader: true }));
      let response = await deleteClientNote(noteId);
      await getNotes();
      SUCCESS_MESSAGE(response?.message);
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("Error loading food mood logs", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const closeAddPopup = () => {
    setShowAddPopup(false);
    setEditingNote(null);
  }

  const openEditNotePopup = (note) => {
    setEditingNote(note);
    setShowAddPopup(true);
  }

  const handlePageChange = (page) => {
    setActivePage(page);
  }

  const clientFullName = () => {
    return FULL_NAME(clientDetails.first_name, clientDetails.last_name);
  }

  const columns = [
    {
      key: "note",
      label: "Note",
      width: "65%",
      component: (item) => (
        <p className="is-multiline m-0">
          <ShowMoreText
            text={item.note}
            maxLength={200}
          />
        </p>
      )
    },
    {
      key: "created_at",
      label: "Added On",
      sortable: true,
      width: "20%",
      component: (item) => DATE_FORMAT_TIME(item.created_at),
    },
    {
      key: "action",
      width: "15%",
      className: "py-3 px-4 fw-normal text-dark fw-bold capitalize",
      component: (item) => (
        <>
          <Button
            variant="transparent"
            onClick={() => openEditNotePopup(item)}
          >
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </Button>
          <Button
            variant="transparent"
            onClick={() => deleteNote(item.id)}
          >
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <AddNotesPopup
        showPopup={showAddPopup}
        closePopup={closeAddPopup}
        noteData={editingNote}
        refetch={getNotes}
      />
      <div className="top d-flex align-items-start justify-content-between gap-10">
        {(!loadingClientDetails && clientDetails) &&
          <div className="left">
            <h6 className="m-0 fw-bold">
            Notes for {clientFullName()}
            </h6>
            <p className="m-0">
            Below are the notes that you have added for {clientFullName()}.
            </p>
          </div>
        }
        <div className="right">
          <Button
            onClick={() => setShowAddPopup(true)}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            <span className="icn me-2">
              <PlusIcn />
            </span>
            Add a new note
          </Button>
        </div>
      </div>
      <div className="CardBody py-3">
        <div className="commonCardBox">
          <div className="table-responsive client-doc-notes">
            <CommonTable
              data={notes}
              columns={columns}
              sortBy={sortConfig.orderBy}
              setSortBy={(orderBy) => {
                setSortConfig((prevState) => ({
                  ...prevState,
                  orderBy
                }))
              }}
              sortOrder={sortConfig.order}
              setSortOrder={(order) => {
                setSortConfig((prevState) => ({
                  ...prevState,
                  order
                }))
              }}
            />
          </div>
          <div className="d-flex justify-content-end">
            {notes?.length>0 &&
              <ReactPagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  handlePageChange={handlePageChange}
                  showPageSizeDropdown={false}
                  // pageSizeOptions={[10, 20, 50]}
                  // onPageSizeChange={setPageSize}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    clientDetails: state.Client.client.details,
    loadingClientDetails: state.Client.loadingClientDetails,
    notes: state.Client.client.notes.data,
    itemsCountPerPage : state.Client.client.notes.itemsCountPerPage,
    totalItemsCount: state.Client.client.notes.totalItemsCount,
  };
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ClientNotes);