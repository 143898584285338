import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";
//imported
import * as Yup from "yup";
import { ReactComponent as OTick } from "../../../Assets/Images/icn/OrangeTick.svg";
import { DIRECT_ACCOUNT_TYPE, FULL_ACCOUNT_TYPE, QUESTIONS_PDF_LINK, PERMISSION_CONSTANTS } from "common/constants";
import useInstancePermissions from "hooks/useInstancePermissions";

const Form_ = ({
  initialValues,
  submitting,
  updateData_,
  loader,
  myFormListing,
  handleCheckboxChange,
  selectedCheckboxes,
  addClientData_,
  accountSettingDetail,
  clientCategory,
  setSelectedCategoryValue,
  emailInviteValue,
  setEmailInviteValue,
  addClientValues,
  setUserAccountType,
  practitionerInfo
}) => {
  let invite_link = addClientData_ ? addClientData_.invitation_link : "";
  const [copied, setCopied] = useState(false);

  const isAllowedToViewSection = useInstancePermissions();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(invite_link);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };
  const is_email_link = emailInviteValue;
  const validationSchema_ = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("First Name is required")
      .matches(/^[^0-9]+$/, "Must not contain numbers"),
    last_name: Yup.string()
      .trim()
      .required("Last Name is required")
      .matches(/^[^0-9]+$/, "Must not contain numbers"),
    email: Yup.string()
    .when("is_direct_account", {
      is: "yes",
      then: schema => schema.trim().required("Email is required"),
      otherwise: schema => {
        return is_email_link
        ? schema.trim().notRequired()
        : schema
            .trim()
            .email("Invalid email address")
            .required("Email is required")
      },
    }),
    client_category: Yup.string().trim().required("Client category is required"),
    todo_items: Yup
    .array()
    .of(Yup.string())
    // .compact()
    .when("is_direct_account", {
      is: "yes",
      then: schema => schema.min(1, "Please select at least one form."),
      otherwise: schema => schema.nullable(),
    }),
  });

  const handleCheckboxChange_ = (e, setFieldValue) => {
    setFieldValue("is_email_invitation", e.target.checked);
    setEmailInviteValue(e.target.checked);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema_}
        onSubmit={updateData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          // console.log("values", values, errors)
          return (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="commonCardBox px-lg-4 p-3">
              <Row>
                <Col lg="12" className="">
                  <div className="noLastBorder">
                    <div className="py-3">
                      {accountSettingDetail?.direct_link_clients ===
                        "enable" && (
                        <>
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize">
                            Client Type
                          </label>
                          <Row className="mb-3">
                            <Col lg="12" className="my-1">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="is_direct_account"
                                    onChange={(e) => {
                                      setUserAccountType(FULL_ACCOUNT_TYPE)
                                      setFieldValue("is_direct_account", "no");
                                      setFieldValue("is_email_invitation", false);
                                    }}
                                    onBlur={handleBlur}
                                    value="no"
                                    checked={values.is_direct_account === "no"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label htmlFor="" className="form-label theme-clr">
                                  Full Account
                                </label>
                              </div>
                              <p className=" m-0 ">
                                These clients will set up an account on the site
                                by choosing a login email and password. Once
                                they've logged onto the site they will be able to
                                log their foods/feelings and complete any To-Do
                                items that you've assigned to them. They will also
                                have access to their completed To-Do items after
                                they have been submitted.
                              </p>
                            </Col>
                            <Col lg="12" className="my-1">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="is_direct_account"
                                    onChange={(e) => {
                                      setUserAccountType(DIRECT_ACCOUNT_TYPE)
                                      setFieldValue("is_direct_account", "yes");
                                    }}
                                    onBlur={handleBlur}
                                    value="yes"
                                    checked={values.is_direct_account === "yes"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label htmlFor="" className="form-label theme-clr">
                                  Direct Links
                                </label>
                              </div>
                              <p className="m-0">
                                These clients will not set up a Nutri-Q account.
                                Instead you will send them direct links to the
                                To-Do items that you'd like them to complete. They
                                will not have access to the site other than these
                                To-Do items and they will not need to enter any
                                login information. This option is best for the
                                following situations:
                              </p>
                              <p className="m-0">
                                If you don't want your client to have access to
                                the food/feeling log. If you want your clients to
                                go directly to their To-Do item(s) without having
                                to create a login for the site.
                              </p>
                            </Col>
                          </Row>
                        </>
                      )}
                      {isAllowedToViewSection(PERMISSION_CONSTANTS.SHOW_CLIENT_CATEGORIES) && (
                        <Row className="mb-3">
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize my-2">
                            Client Category
                          </label>
                          <p className="m-0">
                            Which of your Professional Scopes should this client
                            belong to?
                          </p>
                          {clientCategory.length > 0 &&
                            <>
                              <Row className={`${errors.client_category && "is-invalid"}`}>
                                {clientCategory.map((category, i) => {
                                  return (
                                    <Col lg="6" sm="6" className="my-2" key={i}>
                                      <div className="py-1 d-flex align-items-start gap-10">
                                        <div className="cstmInputCheckWrp radio position-relative ">
                                          <input
                                            className="position-absolute w-100 h-100"
                                            type="radio"
                                            name="client_category"
                                            // onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={category.uid}
                                            onChange={(e) => {
                                              setSelectedCategoryValue(e.target.value)
                                              setFieldValue('client_category', e.target.value);
                                            }}
                                            checked={values?.client_category === category.uid}
                                            style={{ top: "2px" }}
                                          />
                                          <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                            <span className="icn d-none rounded-circle">
                                              {/* <OTick /> */}
                                            </span>
                                          </div>
                                        </div>
                                        <label
                                          htmlFor=""
                                          className="form-label theme-clr m-0"
                                        >
                                          {category.category_name}
                                        </label>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                              {errors.client_category &&
                                <p className="invalid-feedback">{errors.client_category}</p>
                              }
                            </>
                          }
                        </Row>
                      )}
                      <Row>
                        <Col lg="6" sm="6" className="my-2">
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            onChange={handleChange}
                            placeholder="First Name"
                            onBlur={handleBlur}
                            value={values.first_name}
                            autoComplete="off"
                          />
                          {errors.first_name && touched.first_name && (
                            <p className="invalid-feedback_">
                              {errors.first_name}
                            </p>
                          )}
                        </Col>
                        <Col lg="3" sm="6" className="my-2">
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            onChange={handleChange}
                            placeholder="Last Name"
                            onBlur={handleBlur}
                            value={values.last_name}
                            autoComplete="off"
                          />
                          {errors.last_name && touched.last_name && (
                            <p className="invalid-feedback_">
                              {errors.last_name}
                            </p>
                          )}
                        </Col>
                        <Col lg="3" sm="6" className="my-2">
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize">
                            Email{" "}
                            {values.is_email_invitation === true ? (
                              <>
                                <sub className="text-capitalize fw-normal">
                                  (optional)
                                </sub>
                              </>
                            ) : (
                              ""
                            )}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={handleChange}
                            placeholder="Email"
                            onBlur={handleBlur}
                            value={values.email}
                            autoComplete="off"
                            // required={
                            //   values.is_email_invitation === true ? false : true
                            // }
                          />
                          {errors.email && touched.email && (
                            <p className="invalid-feedback_">{errors.email}</p>
                          )}
                        </Col>
                        <Col lg="3" sm="6" className="my-2">
                          <label htmlFor="" className="form-label theme-clr">
                            Phone <sub className="theme-clr">(optional)</sub>
                          </label>
                          <PhoneInput
                            country={'us'}
                            enableAreaCodes={true}
                            inputProps={{
                              name: "phone",
                              onBlur: handleBlur,
                            }}
                            value={values.phone}
                            onChange={(phone, country) => {
                              setFieldValue("phone", phone);
                              setFieldValue("country_code", country.dialCode);
                            }}
                            onBlur={handleBlur}
                            // className={`phone-input ${errors[field.name] && 'is-invalid'}`}
                          />
                        </Col>
                      </Row>
                      {values.is_direct_account === "no" && (
                        <>
                          <p className="py-2 m-0">
                            If you wish to send the invitation link to your client
                            yourself or if your client will not be logging into the
                            site then check this box to suppress the email invite.
                          </p>
                          <div className="py-2 d-flex align-items-start">
                            <div className="cstmInputCheckWrp position-relative me-2">
                              <input
                                type="checkbox"
                                className="position-absolute w-100 h-100"
                                name="is_email_invitation"
                                checked={values.is_email_invitation}
                                // onChange={(e) => {
                                //   setFieldValue("is_email_invitation", e.target.checked);
                                // }}
                                onChange={(e) =>
                                  handleCheckboxChange_(e, setFieldValue)
                                }

                                // onChange={(e) => {
                                //   const { checked } = e.target;
                                //   setFieldValue("is_email_invitation", checked);
                                //   setEmailInviteValue(checked);
                                // }}
                              />
                              <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                  <OTick />
                                </span>
                              </div>
                            </div>
                            <h6 className="capitalize m-0 fw-sbold">
                              Do not email this client an invite to the site{" "}
                            </h6>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="py-3">
                      <Row>
                        <Col lg="3" sm="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label fw-bold text-dark capitalize"
                          >
                            Date of Birth{" "}
                            <sub className="text-capitalize fw-normal">
                              (optional)
                            </sub>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="birth_date"
                            onChange={handleChange}
                            placeholder="Date"
                            onBlur={handleBlur}
                            value={values.birth_date}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="py-3">
                      <label
                        htmlFor=""
                        className="form-label fw-bold text-dark capitalize"
                      >
                        Address{" "}
                        <sub className="text-capitalize fw-normal">
                          (optional)
                        </sub>
                      </label>
                      <Row>
                        <Col lg="6" className="my-2">
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            onChange={handleChange}
                            placeholder="Street address, P.O. box etc"
                            onBlur={handleBlur}
                            value={values.street}
                          />{" "}
                        </Col>
                        <Col lg="6" className="my-2">
                          <input
                            type="text"
                            className="form-control"
                            name="apartment"
                            onChange={handleChange}
                            placeholder="Apartment, suite, unit, building, floor etc"
                            onBlur={handleBlur}
                            value={values.apartment}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                    <div className="py-3">
                      <Row>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label fw-bold text-dark capitalize"
                          >
                            City{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            onChange={handleChange}
                            placeholder="City"
                            onBlur={handleBlur}
                            value={values.city}
                          />{" "}
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label fw-bold text-dark capitalize"
                          >
                            State/Province/Region{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            onChange={handleChange}
                            placeholder="State/Province/Region"
                            onBlur={handleBlur}
                            value={values.state}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                    <div className="py-3">
                      <Row>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label fw-bold text-dark capitalize"
                          >
                            Zip/Postal Code{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="zipcode"
                            onChange={handleChange}
                            placeholder="Zip/Postal Code"
                            onBlur={handleBlur}
                            value={values.zipcode}
                          />{" "}
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label fw-bold text-dark capitalize"
                          >
                            Country{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            onChange={handleChange}
                            placeholder="Country"
                            onBlur={handleBlur}
                            value={values.country}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                    {values.client_category &&
                      <div className="py-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold text-dark capitalize"
                        >
                          Client To-Do Items
                        </label>
                        {/* <div>
                          <Link target="_blank" to={QUESTIONS_PDF_LINK}>View what questions are included in each option.</Link>
                        </div> */}
                        <Row>
                          {myFormListing.length > 0 &&
                            myFormListing.map((item, i) => {
                              return (
                                <Col lg="4" className="my-2" key={i}>
                                  <ul className="list-unstyled p-0 mb-0">
                                    <li className="py-1">
                                      <div className={`py-2 d-flex align-items-start`}>
                                        <div className="cstmInputCheckWrp position-relative me-2">
                                          <input
                                            type="checkbox"
                                            name="todo_items"
                                            className={`position-absolute w-100 h-100`}
                                            checked={values.todo_items.includes(item.id.toString())}
                                            onChange={handleChange}
                                            value={item.id}
                                          />
                                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                            <span className="icn d-none">
                                              <OTick />
                                            </span>
                                          </div>
                                        </div>
                                        <p className=" m-0 ">{item.form_name}</p>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              );
                            })}
                          {errors.todo_items && touched.todo_items && (
                            <p className="invalid-feedback_ d-block">
                              {errors.todo_items}
                            </p>
                          )}
                        </Row>
                      </div>
                    }
                  </div>
                  {values?.is_direct_account == "yes" && values.todo_items && values.todo_items.length > 0 && (
                        <div className="py-3">
                          <label htmlFor="" className="form-label fw-bold text-dark capitalize">
                            Client Notification
                          </label>
                          <p>How do you want to notify your client about these forms? We can either send them an email with links to the forms or we can provide the links so you can send them yourself.</p>
                          <Row className="mb-3">
                            <Col lg="12" className="my-1">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="send_links_email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value="yes"
                                    checked={values.send_links_email == "yes"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label htmlFor="" className="form-label theme-clr">
                                  Have Nutri-Q email my client with links to these forms.
                                </label>
                              </div>
                            </Col>
                            <Col lg="12" className="my-1">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="send_links_email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value="no"
                                    checked={values.send_links_email == "no"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label htmlFor="" className="form-label theme-clr">
                                  I'll send the links myself. Please give me the form links.
                                </label>
                              </div>
                            </Col>
                          </Row>
                          </div>
                      )}
                </Col>
              </Row>
            </div>
            <div>
              {/* {renderHtml_(invite_link)} */}
              {addClientData_ !== null && (
                <RenderHtml
                  addClientData={addClientData_}
                  copyToClipboard={copyToClipboard}
                  copied={copied}
                  addClientValues={addClientValues}
                />
              )}
            </div>
            <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-3">
              <Button
                type="submit"
                className="d-inline-flex align-items-center justify-content-center commonBtn"
                loading={loader}
                disabled={submitting}
              >
                {isSubmitting ? "Wait..." : "Submit"}
              </Button>
              {/* <LoadingButton
                            type="submit"
                            className="d-inline-flex align-items-center justify-content-center commonBtn"
                            loading={loader}
                            disabled={submitting}
                        >
                        {isSubmitting ? 'Wait...' : 'Submit'}
                        </LoadingButton> */}
              <Button className="d-inline-flex align-items-center justify-content-center commonBtn">
                Cancel
              </Button>
            </div>
          </Form>
        )
        }}
      </Formik>
    </>
  );
};

const RenderHtml = ({addClientData, copyToClipboard, copied, addClientValues}) => {
  let invite_link = addClientData ? addClientData?.invitation_link : "";
  return (
    <>
      <div
        className="position-relative p-3 p-lg-4 gettingStarted my-2"
        style={{ background: "rgb(233 255 245)", zIndex: "9" }}
      >
        <h3 className="m-0 fw-bold text-success">Success!</h3>
        {addClientData.is_direct_account === "yes" ?
          <>
            {addClientData.send_links_email === "yes" ?
              <p className="m-0 py-1">
                {addClientData.first_name} has been created and we sent them an email with links to their To-Do Item.
              </p>
              :
              <>
                <p className="m-0 py-1">
                  {addClientData.first_name} has been created.
                </p>
                <p>
                  Below are the direct links to the forms for you to email to your client.
                  If you need these links later, you can also find them by going to the My Clients page,
                  clicking on your client's name and then on the 'Data & To-Do Items'.
                  Links are next to each To-Do item under the 'Options' button.
                </p>
              </>
            }
            {addClientData.assignedTodos && addClientData.assignedTodos.length > 0 &&
              addClientData.assignedTodos.map(todo => {
                return (
                  <div className="py-3">
                    <p className="m-0 py-1 fw-bold">
                      {todo.name}
                    </p>
                    <Row>
                      <Col lg="8">
                        <pre className="m-0 fw-sbold bg-white p-2">
                          {todo.link && todo.link}
                        </pre>
                      </Col>
                      <Col lg="4">
                        <Button
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                          onClick={() => {
                            try {
                              navigator.clipboard.writeText(todo.link)
                            } catch (err) {
                              console.log("Failed to copy to the clipboard");
                            }
                          }}
                        >
                          Copy Link
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )
              })
            }
            
          </>  
          :
          (addClientData.email ? (
            <>
              <p className="m-0 py-1">
                {addClientData.first_name} has been created.
                {(addClientValues && addClientValues?.is_email_invitation === false) && "We sent them an invite to log into the Nutri-Q site."}
              </p>
              <div class="FormbtnWrpper d-flex align-items-center gap-10 mt-3">
                <Link
                  to={`/my-clients/${addClientData.id}/detail`}
                  className="d-inline-flex align-items-center justify-content-center commonBtn btnSm btn text-white"
                >
                  Go to client page
                </Link>
                <a
                  href="/add-client"
                  className="d-inline-flex align-items-center justify-content-center commonBtn btnSm btn text-white"
                >
                  Add another client
                </a>
              </div>
            </>
          ) : (
            <>
              <p className="m-0 py-1">
                {addClientData.first_name} has been created. Below is the invite link if you
                want to send it to your client in your own email. If you need this
                link later, you can also find it on your client's 'Info &
                Settings' tab and click on the 'Invite Options' button.
              </p>
              <div className="iconWithText position-relative">
                <input class="form-control" value={invite_link} />
                <Button
                  onClick={copyToClipboard}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm icn position-absolute"
                  style={{ right: " 10px", left: "unset" }}
                >
                  {copied ? "Copied!" : "Copy Link"}
                </Button>
              </div>
              <div class="FormbtnWrpper d-flex align-items-center gap-10 mt-3">
                <Link
                  to={`/my-clients/${addClientData.id}/detail`}
                  className="d-inline-flex align-items-center justify-content-center commonBtn btnSm btn text-white"
                >
                  Go to client page
                </Link>
              </div>
            </>
          ))
        }
      </div>
    </>
  );
};

export default Form_;
