import { useState } from "react";
import { Link } from "react-router-dom";

const ShowMoreText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {isExpanded ? `${text} ` : `${text.substring(0, maxLength)}... `}
      <Link onClick={() => setIsExpanded(!isExpanded)} className="themeClr">
        {isExpanded ? "Show Less" : "Show More"}
      </Link>
    </span>
  );
};

export default ShowMoreText;
