import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Dropdown } from "react-bootstrap";
import { TODO, PACKAGE_INVITE } from "common/viewContent";
import parse from "html-react-parser";
import NewInvitation from "../NewInvitation";
import { cancelPackageInvitation, getInvitationsList, removePackageInvitation, resendPackageInvitation } from "store/services/packageService";
import * as actions from "store/actions";
import ResendPackageInvitePopup from "pages/SideTabs/MyClients/Detail/component/TabContent/InfoSetting/Component/ClientPackageInvitations/Components/Modals/ResendInvitePopup";
import SendInviteSuccessPopup from "pages/SideTabs/MyClients/Detail/component/TabContent/InfoSetting/Component/ClientPackageInvitations/Components/Modals/SendInviteSuccessPopup";
import CancelPackageInvitePopup from "pages/SideTabs/MyClients/Detail/component/TabContent/InfoSetting/Component/ClientPackageInvitations/Components/Modals/CancelPackageInvitePopup";
import RemovePackageInvitePopup from "pages/SideTabs/MyClients/Detail/component/TabContent/InfoSetting/Component/ClientPackageInvitations/Components/Modals/RemovePackageInvitePopup";
import ViewInvitationLinkPopup from "pages/SideTabs/MyClients/Detail/component/TabContent/InfoSetting/Component/ClientPackageInvitations/Components/Modals/ViewInvitationLinkPopup";
import { FULL_NAME } from "helpers/common";

const PackageInvitationsTab = ({ dispatch, packageDetails, invitations }) => {
  const { id: packageId } = useParams();

  const [showInviteLinkPopup, setShowInviteLinkPopup] = useState(false);
  const [showResendInvitePopup, setShowResendInvitePopup] = useState(false);
  const [showPackageInviteSuccessPopup, setShowPackageInviteSuccessPopup] = useState(false);
  const [showCancelInvitePopup, setShowCancelInvitePopup] = useState(false);
  const [showRemoveInvitePopup, setShowRemoveInvitePopup] = useState(false);
  const [showPackageInvitePopup, setShowPackageInvitePopup] = useState(false);
  const [showPackageLinkPopup, setShowPackageLinkPopup] = useState(false);
  const [currentPackageInvitation, setCurrentPackageInvitation] =
    useState(null);
  const [generatedPackageLink, setGeneratedPackageLink] = useState(null);

  useEffect(() => {
    getInvitations();
  }, []);

  async function getInvitations() {
    try {
      let payload = {
        packageId,
        status: "invited"
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getInvitationsList(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching package invitations", err);
    }
  }

  const openPackageInviteLinkPopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowInviteLinkPopup(true);
  };

  const openResendInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowResendInvitePopup(true);
  };

  const openCancelInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowCancelInvitePopup(true);
  };

  const openRemoveInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowRemoveInvitePopup(true);
  };

  const resendInvite = async (setSendingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation?.id,
        values: {
          client_id: currentPackageInvitation?.client_id,
        },
      };
      setSendingInvite(true);
      await resendPackageInvitation(data);
      setSendingInvite(false);
      setShowResendInvitePopup(false);
      setShowPackageInviteSuccessPopup(true);
    } catch (err) {
      console.log(err, "==============================");
      setSendingInvite(false);
    }
  };

  const cancelInvite = async (setCancelingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation?.id,
        values: {
          client_id: currentPackageInvitation?.client_id,
        },
      };
      setCancelingInvite(true);
      await dispatch(cancelPackageInvitation(data));
      await getInvitations();
      setCancelingInvite(false);
      setShowCancelInvitePopup(false);
    } catch (err) {
      console.log(err, "==============================");
      setCancelingInvite(false);
    }
  };

  const removeInvite = async (setRemovingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation?.id,
        values: {
          client_id: currentPackageInvitation?.client_id,
        },
      };
      setRemovingInvite(true);
      await dispatch(removePackageInvitation(data));
      setRemovingInvite(false);
      setShowRemoveInvitePopup(false);
    } catch (err) {
      console.log(err, "==============================");
      setRemovingInvite(false);
    }
  };

  return (
    <>
      <NewInvitation />
      <ViewInvitationLinkPopup
        showPopup={showInviteLinkPopup}
        setShowPopup={setShowInviteLinkPopup}
        invitation={currentPackageInvitation}
        client={currentPackageInvitation}
      />
      <ResendPackageInvitePopup
        showPopup={showResendInvitePopup}
        setShowPopup={setShowResendInvitePopup}
        handleResendInvite={resendInvite}
      />
      <SendInviteSuccessPopup
        showPopup={showPackageInviteSuccessPopup}
        setShowPopup={setShowPackageInviteSuccessPopup}
        client={currentPackageInvitation}
      />
      <CancelPackageInvitePopup
        showPopup={showCancelInvitePopup}
        setShowPopup={setShowCancelInvitePopup}
        handleCancelInvite={cancelInvite}
      />
      <RemovePackageInvitePopup
        showPopup={showRemoveInvitePopup}
        setShowPopup={setShowRemoveInvitePopup}
        handleRemoveInvite={removeInvite}
      />
      <div className="commonCardBox">
      <div className="table-responsive">
      <table className="table">
        <thead className="bg-transparent">
          <tr>
            <th className="p-3 fw-normal text-dark fw-bold capitalize">
              Client Name
            </th>
            <th className="p-3 fw-normal text-dark fw-bold capitalize">
              Status
            </th>
            <th className="p-3 fw-normal text-dark fw-bold capitalize">
              Date
            </th>
            <th className="p-3 fw-normal text-dark fw-bold capitalize">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {invitations?.length > 0 ? invitations.map((invitation, index) => {
            return (
              <tr>
                <td className="px-3 py-2 border-0">
                  <p className="theme-clr m-0 ws-normal fw-bold">
                    {FULL_NAME(invitation?.first_name, invitation?.last_name)}
                  </p>
                </td>
                <td className="px-3 py-2 border-0">
                  <p
                    className={PACKAGE_INVITE[invitation?.status]?.className}
                  >
                    <span className="icn me-2">
                      {parse(PACKAGE_INVITE[invitation?.status]?.icon || "")}
                    </span>
                    {PACKAGE_INVITE[invitation?.status]?.status}
                  </p>
                </td>
                <td className="px-3 py-2 border-0">
                  <p className="theme-clr m-0">
                    {moment(invitation?.created_at).format('MMM D, YYYY hh:mm A')}
                  </p>
                </td>
                <td className="px-3 py-2 border-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      className=" bg-white btnSm capitalize text-dark fw-sbold border pe-5 position-relative"
                      id="dropdown-basic"
                    >
                      Options
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {invitation && invitation.status == "invited" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => openResendInvitePopup(invitation)}
                          >
                            Resend Invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              openPackageInviteLinkPopup(invitation)
                            }
                          >
                            View Invite Link
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => openCancelInvitePopup(invitation)}
                          >
                            Cancel Invite
                          </Dropdown.Item>
                        </>
                          
                      ) : (
                        <Dropdown.Item
                          onClick={() => openRemoveInvitePopup(invitation)}
                        >
                          Remove
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            )
          }):(
            <tr>
              <td className="text-center" colSpan={4}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    invitations: state.Package.packageInvitations?.invited,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PackageInvitationsTab);
