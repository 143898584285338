import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Col,
  ProgressBar,
  Row,
} from "react-bootstrap";
import StepWizard from "react-step-wizard";
import ToDoPage from "./Component/ToDoPage";
import { decode } from "html-entities";
import { getStartFinishSteps, uniqId, validatePageConditions } from "helpers/common";
import { getTodoDetails, saveTodoResponse, updateTodoStatus } from "store/services/clientService";
import StartStep from "components/shared/AddDataForm/StartStep";
import FinishStep from "components/shared/AddDataForm/FinishStep";
import { useParams } from "react-router-dom";
import { useShowLoader } from "hooks/useShowLoader";
import parse from "html-react-parser";

const AddDataForm = ({
  dispatch,
  todoId,
  formId,
  todoPages,
  onToDoCompleted,
  todoFormDetails,
  initialStep,
  handleGoBack,
  deleteTodoAndGoBack,
  allQuestionAnswers,
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [questionAnswers, setQuestionAnswers] = useState(allQuestionAnswers);
  const [pages, setPages] = useState(todoPages);
  const [isLoadingToDoDetails, setIsLoadingToDoDetails] = useState(false);

  const {startStep, startContent, finishStep, finishContent} = getStartFinishSteps(formId);
  const { clientId } = useParams();
  const showLoader = useShowLoader();

  useEffect(() => {
    if(todoId) {
      getToDoData(todoId);
    }
  }, [todoId]);

  useEffect(() => {
    setQuestionAnswers(allQuestionAnswers);
  }, [allQuestionAnswers])

  /**
   * if there is a additional start step shown then add +1 to last active step
   */
  useEffect(() => {
    let step = initialStep;
    if(startStep === true) {
      step += 1;
    } else {
      step = step;
    }
    setActiveStep(step);
  }, [initialStep])

  /**
   * Validate if pages and questions need to be shown based on already answered questions
   */
  useEffect(() => {
    let copyPages = todoPages;
    copyPages = copyPages.map(page => {
      let copyPage = {...page};
      copyPage.visible = validatePageConditions(page, questionAnswers); // validate if the page should be displayed or not
      return copyPage;
    })
    setPages(copyPages);
  }, [questionAnswers, todoPages])

  let noTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  const handleStepChange = async (step) => {
    setActiveStep(step);
  };

  /**
   * Get data for current todo
   */
  const getToDoData = async (todoId) => {
    if (!todoId) {
      return;
    }
    try {
      let data = {
        todoId,
      };
      showLoader(true);
      setIsLoadingToDoDetails(true);
      await dispatch(getTodoDetails(data));
      setIsLoadingToDoDetails(false);
      showLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoadingToDoDetails(false);
      handleGoBack();
      showLoader(false);
    }
  };

  const saveFormResponse = async (data, isFinalSubmit, isAutoSave) => {
    try{
      const { pageId, values } = data;
      
      let formData = {
        isFinalSubmit,
        questions: values,
        pageId,
      };
      
      let postData = {
        id: todoId,
        formData,
      };
      await saveTodoResponse(postData);
      // setSubmitting(false);
      if(isAutoSave) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    } catch (err) {
      console.log(err, "==============================");
      // setSubmitting(false);
      throw new Error("Failed to save form response");
    }
  };

  const handleSubmitTodo = async () => {
    try {
      let data = {
        status: "completed",
        client_id: clientId,
      }
      let response = await updateTodoStatus(todoId, data);
      if(response?.success === true) {
        onToDoCompleted();
      }
    } catch(err) {
      console.log("error occured submitting todo", err);
    }
  }

  if (isLoadingToDoDetails || !todoId) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="myClients todo-form">
        <Row className="align-items-start">
          <Col lg="7" className="my-2">
            <div className="form-details mb-3">
              {todoFormDetails.form_name && (
                <h2 className="m-0 fw-bold">{todoFormDetails.form_name}</h2>
              )}
              {todoFormDetails.form_desc && (
                <div className="m-0 py-2">{parse(todoFormDetails.form_desc)}</div>
              )}
            </div>
            
            <StepWizard transitions={noTransitions} initialStep={activeStep}>
              {startStep && (
                <StartStep
                  content={startContent}
                />
              )}
              {pages && pages.length > 0 &&
                pages.map((page, i) => {
                  if(page.visible === false) {
                    return null;
                  }
                  return (
                    <ToDoPage
                      todoId={todoId}
                      key={page.id}
                      pageDetails={page}
                      onStepChange={handleStepChange}
                      handleSubmit={saveFormResponse}
                      todoFormDetails={todoFormDetails}
                      onToDoCompleted={onToDoCompleted}
                      questionAnswers={questionAnswers}
                      setQuestionAnswers={setQuestionAnswers}
                      isPageVisible={page.visible}
                      handleGoBack={handleGoBack}
                      deleteTodoAndGoBack={deleteTodoAndGoBack}
                    />
                  );
                })
              }
              {finishStep && (
                <FinishStep
                  content={finishContent}
                  handleSubmit={handleSubmitTodo}
                />
              )}
            </StepWizard>
          </Col>
          <Col lg="4" className="my-2 sticky-top ProgressBarBox">
            <div className="commonCardBox bg-white">
              <div className="top py-2 px-3 border-bottom">
                <h3 className="m-0 py-2 fw-sbold">Progress</h3>
              </div>
              <div className="CardBody py-3 px-3 commonContent">
                <ProgressBar
                  now={(Number(activeStep) / pages.length) * 100}
                />
                <ul className="list-unstyled ps-0 mb-0 py-2 mt-3">
                  {pages &&
                    pages.length > 0 &&
                    (function() {
                      let stepNumber = 0;
                      return pages.map((page, i) => {
                        if(page.visible === false) {
                          return null;
                        }
                        stepNumber++;
                        return (
                          <li
                            key={`step-${stepNumber}`}
                            className={`py-2 position-relative d-flex align-items-start ${
                              activeStep >= stepNumber && "active"
                            }`}
                          >
                            <span className="icn me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                              >
                                <circle
                                  cx="5.55629"
                                  cy="5.40199"
                                  r="5.235"
                                  fill="#f4694b"
                                />
                              </svg>
                            </span>
                            <h4 className="m-0">
                              Step {stepNumber}
                              <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                                {page.page_title && `(${decode(page.page_title)})`}
                              </p>
                            </h4>{" "}
                          </li>
                        );
                      })
                    })()
                  }
                  {/* <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepOne && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">Start</h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepTwo && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">
                      Step 1
                      <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                        (Gender)
                      </p>
                    </h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepThree && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">
                      Step 2
                      <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                        (NAQ)
                      </p>
                    </h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepFour && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">Finish</h4>{" "}
                  </li> */}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    allQuestionAnswers: state.Client.currentTodoData.allQuestionAnswers,
    todoFormDetails: state.Client.currentTodoData.formDetails,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddDataForm);
