import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { passwordStrength } from 'check-password-strength'
import { convertBytes, DATE_FORMAT_TIME } from 'helpers/common'
import { changePassword } from "store/services/authService";
import { updateProfile_ } from "store/services/profileService";
import { Formik } from "formik";
import validation from "./validation";
import { SUCCESS_MESSAGE } from "common/constantMessage";

const AccountInfo = ({ dispatch, userInfo, submitting, accountSettingList }) => {
  const [step, setStep] = useState("1");
  const [sending, setSending] = useState(false)

  const [fields, setfields] = useState({
    currentPassword: "",
    email: "",
  })

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const submitData_ = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      values = JSON.stringify(values)
      let response = await dispatch(changePassword(values));
      if(response?.success === true) {
        SUCCESS_MESSAGE("Password updated successfully")
      }
      resetForm();
      setStep(1)
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  };

  //handle changes
  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )

  const submitProfileData_ = async (evt) => {
    try {
      evt.preventDefault();
      const fieldsJSON = JSON.stringify(fields);
      setSending(true)
      await dispatch(updateProfile_(fieldsJSON));
      setSending(false)
      setStep(1)
    } catch (err) {
      console.log(err, "==============================");
      setSending(false);
    }
  };

  return (
    <>
      {step == "1" ? (
        <>
          <div className="top py-2">
            <h3 className="m-0 py-1 fw-bold">Account Information</h3>
          </div>
          <div className="TabBody py-3">
            <div className="info">
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Name
                </p>
                {userInfo && (
                  <p className="tex-muted m-0">{`${userInfo?.first_name} ${userInfo?.last_name !== null ? userInfo?.last_name : ''}`}</p>
                )}
              </div>
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Login Email
                </p>
                <p className="tex-muted m-0">{userInfo?.email}</p>
              </div>
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Password Last Updated On
                </p>
                <p className="tex-muted m-0"> {DATE_FORMAT_TIME(userInfo?.last_password_updated !== null ? userInfo?.last_password_updated : userInfo?.created_at)}</p>
              </div>
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Account ID
                </p>
                <p className="tex-muted m-0">{userInfo?.uuid}</p>
              </div>
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Practitioner Name
                </p>
                <p className="tex-muted m-0">{userInfo?.display_name}</p>
              </div>
              {accountSettingList?.length && accountSettingList?.map((setting, index) => (
                <>
                  {setting.data_key === "practitioner_storage_limit" && (
                    <div key={index} className="py-2">
                      <p className="text-bold m-0 text-dark fw-bold capitalize">
                        Total Storage Space Available
                      </p>
                      <p className="tex-muted m-0">{convertBytes(Number(setting.data_set) * 1024, "mb")} MB</p>
                    </div>
                  )}
                  {setting.data_key === "storage_size" && (
                    <div key={index} className="py-2">
                      <p className="text-bold m-0 text-dark fw-bold capitalize">
                        Utilized Storage Space
                      </p>
                      <p className="tex-muted m-0">{convertBytes(Number(setting.data_set) * 1024, "mb")} MB</p>
                    </div>
                  )}
                </>
              ))}
              <div className="py-2">
                <Link
                  onClick={() => setStep("2")}
                  to=""
                  className=" fw-sbold capitalize underline"
                >
                  <i>Update log in email or password</i>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : step == "2" ? (
        <>
          <div className="top py-2">
            <h3 className="m-0 py-1 fw-bold">Edit Log In Information</h3>
          </div>
          <div className="TabBody py-3">
            <div className="info">
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Update Password
                </p>
                <p className="tex-muted m-0">
                  Your password was last updated on {DATE_FORMAT_TIME(userInfo?.last_password_updated !== null ? userInfo?.last_password_updated : userInfo?.created_at)} To
                  update your password please enter in your current password and
                  then your new password below.
                </p>
              </div>
              <div className="py-2">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validation}
                  onSubmit={submitData_}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <Row className="py-2">
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold theme-clr m-0"
                            >
                              Current Password
                            </label>
                            <input
                              type="password"
                              className={`form-control ${errors.currentPassword && 'is-invalid'}`}
                              name="currentPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Current Password"
                              value={values.currentPassword}
                            />
                          </Col>
                          {errors.currentPassword && touched.currentPassword && (
                            // <Col lg="6" className="my-2">
                            <p className="m-0 formText errorMessage">
                              {errors.currentPassword}
                            </p>
                            // </Col>
                          )}
                        </Row>
                        <Row className="py-2">
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold theme-clr m-0"
                            >
                              New Password
                            </label>
                            <input
                              type="password"
                              className={`form-control ${errors.newPassword && 'is-invalid'}`}
                              name="newPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="New Password"
                              value={values.newPassword}
                            />
                            {values?.newPassword && (
                              <p className="m-0 py-1 password-strength">Password Strength: <span className={`strength strength-${passwordStrength(values.newPassword).id}`}>{passwordStrength(values.newPassword)?.value}</span></p>
                            )}
                            {errors.newPassword && touched.newPassword && (
                              <p className="m-0 formText errorMessage">
                                {errors.newPassword}
                              </p>
                            )}
                          </Col>

                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold theme-clr m-0"
                            >
                              Re-enter New Password
                            </label>
                            <input
                              type="password"
                              className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="New Password"
                              value={values.confirmPassword}
                            />
                            {errors.confirmPassword && touched.confirmPassword && (
                              <p className="m-0 formText errorMessage">
                                {errors.confirmPassword}
                              </p>
                            )}
                          </Col>
                          <Col lg="12" className="my-2 btnWrp">
                            <Button
                              // onClick={() => setStep("1")}
                              type="submit"
                              disabled={submitting}
                              className="d-flex align-items-center justify-conten-center commonBtn btnSm"
                            >
                              {isSubmitting ? "Wait..." : "Submit"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )
                  }}
                </Formik>

              </div>
              <div className="py-2">
                <p className="text-bold m-0 text-dark fw-bold capitalize">
                  Update Log In Email
                </p>
                <p className="tex-muted m-0">
                  Your current log in email is{" "}
                  <Link to="" className="underline">
                    {userInfo?.email}
                  </Link>{" "}
                  To update your log in email please enter in your email along
                  with your current password.
                </p>
              </div>
              <div className="py-2">
                <Form>
                  <Row className="py-2">
                    <Col lg="6" className="py-2">
                      <label htmlFor="" className="form-label m-0 fw-sbold">
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        name="email"
                        onChange={handleChange("email")}
                        value={fields.email}
                      />
                    </Col>
                    <Col lg="6" className="py-2">
                      <label htmlFor="" className="form-label m-0 fw-sbold">
                        Current Password
                      </label>
                      <input
                        type="password"
                        placeholder="Current Password"
                        className="form-control"
                        name="currentPassword"
                        onChange={handleChange("currentPassword")}
                        value={fields.currentPassword}
                      />
                    </Col>
                    <Col lg="12" className="my-2 btnWrp">
                      <Button
                        // onClick={() => setStep("1")}
                        type="submit"
                        onClick={submitProfileData_}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        {sending ? 'Wait...' : 'Update Log in Email'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            <div className="py-2">
              <Link
                onClick={() => setStep("1")}
                to=""
                className=" fw-sbold capitalize underline"
              >
                <i>Go Back</i>
              </Link>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    userInfo: state.Profile.user,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AccountInfo);
