import { Col, Dropdown } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import NewInvitation from "../NewInvitation";
import { getPackageUtilization } from "store/services/packageService";
import * as actions from "store/actions";
import { useEffect, useState } from "react";
import ReactPagination from "components/shared/ReactPagination";
import ClientPackageHistoryPopup from "../Modals/ClientPackageHistoryPopup";

const PackageUsageOverviewTab = ({ dispatch, utilizationHistory }) => {
  const { id: packageId } = useParams();

  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  // client package activity popup
  const [showActivityPopup, setShowActivityPopup] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);

  const itemsCountPerPage = utilizationHistory?.per_page || limit;
  const totalItemsCount = utilizationHistory?.total_count || 0;

  useEffect(() => {
    getUtilizationHistory();
  }, [activePage, orderBy, order]);

  const openClientActivityPopup = (data) => {
    setClientDetails(data);
    setShowActivityPopup(true);
  }

  async function getUtilizationHistory() {
    try {
      let payload = {
        packageId,
        params: {
          limit,
          // invitation_id: invitationId,
          page: activePage,
          orderBy,
          order,
        }
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPackageUtilization(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching package invitations", err);
    }
  }

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <>
      <NewInvitation />
      <ClientPackageHistoryPopup
        showPopup={showActivityPopup}
        setShowPopup={setShowActivityPopup}
        data={clientDetails}
        dispatch={dispatch}
      />
      <div className="commonCardBox">
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-transparent">
              <tr>
                <th
                  className="p-3 fw-normal text-dark fw-bold capitalize cursor-pointer"
                >
                  Client Name
                </th>
                <th
                  className="p-3 fw-normal text-dark fw-bold capitalize cursor-pointer"
                >
                  Appointment
                </th>
                <th
                  className="p-3 fw-normal text-dark fw-bold capitalize cursor-pointer"
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {utilizationHistory?.data?.length > 0 ? (
                utilizationHistory?.data.map((item) => (
                  <tr key={item.id}>
                    <td className="px-3 py-2 border-0">
                      <Link
                        className="theme-clr m-0 ws-normal fw-bold underline"
                        onClick={() => {
                          openClientActivityPopup(item);
                        }}
                      >
                        {`${item.first_name} ${item.last_name}`}
                      </Link>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">{item.appointment_name}</p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">
                        {moment(item.created_at).format("MMM D, YYYY hh:mm A")}
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={3}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {utilizationHistory?.data?.length > 0 && (
          <ReactPagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    utilizationHistory: state.Package.utilizationHistory,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PackageUsageOverviewTab);
