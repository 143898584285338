import {postData, getData, deleteData, putData} from 'helpers/apiHelper'
import { MAIN_PATH_CLIENTS, CLIENT_NOTES_LIST, CLIENT_NOTES_ADD, CLIENT_NOTES_UPDATE, CLIENT_NOTES_DELETE  } from './apiPath'
import { getClientNotes } from "store/actions/client";

// get notes list
export function getClientNotesList(payload, callback) {
  let { clientId, params } = payload;
  let queryString = new URLSearchParams(params).toString();
  let url = `${MAIN_PATH_CLIENTS}${CLIENT_NOTES_LIST}/${clientId}?${queryString}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientNotes(data, callback));
      return data;
    });
}

// add note
export function addClientNote(clientId, value) {
  return postData(`${MAIN_PATH_CLIENTS}${CLIENT_NOTES_ADD}/${clientId}`, value);
}

// update note
export function updateClientNote(id, value) {
  return putData(`${MAIN_PATH_CLIENTS}${CLIENT_NOTES_UPDATE}/${id}`, value);
}


// delete note
export function deleteClientNote(value) {
  return deleteData(`${MAIN_PATH_CLIENTS}${CLIENT_NOTES_DELETE}${value}`);
}