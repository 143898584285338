import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DATE_FORMAT, truncateText } from "helpers/common";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const TableData = ({ isLoading, data, onClickRemove, handleToggleVisiblity, owner, handleEditNotes }) => {
  const columnCount = owner === "practitioner" ? 5 : 3;

  return (
    <>
      <div className="table-responsive client-doc-notes">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Document Name
              </th>
              {owner === "practitioner" && (
                <>
                  <th className="p-3 fw-normal text-dark fw-bold capitalize">
                    Notes
                  </th>
                  <th className="p-3 fw-normal text-dark fw-bold capitalize">
                    Visible to Client
                  </th>
                  <th className="p-3 fw-normal text-dark fw-bold capitalize">
                    Client Viewed On
                  </th>
                </>
              )}
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Added On
              </th>
              <th className="p-3 fw-normal text-dark fw-bold capitalize">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columnCount} className="border-0 text-center">Loading...</td>
              </tr>
            ):data?.length ? (
              data.map((document, idx) => {
                return (
                  <tr key={idx}>
                    <td className="px-3 py-2 border-0 underline">
                      <Link
                        to={document.document}
                        className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                        target="_blank"
                      >
                        {document.document_name}
                      </Link>
                    </td>
                    {owner === "practitioner" && (
                      <>
                        <td className="px-3 py-2 border-0">
                          <div className="d-flex gap-10 align-items-center">
                            {document?.notes?.length > 0 && (
                              <p className="m-0 is-multiline" title={document?.notes[0]?.note}>{truncateText(document?.notes[0]?.note, 50)}</p>
                            )}
                            <div className="d-flex">
                              <Button
                                variant="transparent"
                                className="p-0"
                                onClick={() => {
                                  handleEditNotes(document?.id)
                                }}
                              >
                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                              </Button>
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-2 border-0">
                          <div className="cstmSwitch">
                            <BootstrapSwitchButton
                              className="rounded-pill"
                              checked={document.client_visible === "yes"}
                              onlabel="Yes"
                              offlabel="No"
                              size="xs"
                              onChange={(isChecked) => {
                                let value = isChecked === true ? "yes" : "no"
                                handleToggleVisiblity(document.id, value)
                              }}
                            />
                          </div>
                        </td>
                        <td className="px-3 py-2 border-0">
                          <p className="theme-clr m-0">{document.client_viewed_at ? DATE_FORMAT(document.client_viewed_at) : "--"}</p>
                        </td>
                      </>
                    )}
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">{DATE_FORMAT(document.created_at)}</p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          className=" bg-white btnSm capitalize text-dark fw-sbold border pe-5 position-relative"
                          id="dropdown-basic"
                        >
                          Options
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <ul className="list-unstyled ps-0 mb-0 noLastBorder">
                            <li>
                              <Link
                                to={document.document}
                                className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                                target="_blank"
                              >
                                View Document
                              </Link>
                            </li>
                            {owner === "practitioner" && (
                              <li>
                                <Link
                                  to=""
                                  onClick={() => {
                                    onClickRemove(document.id);
                                  }}
                                  className="text-dark capitalize fw-sbold text-dark px-3 py-1 d-block"
                                >
                                  Remove
                                </Link>
                              </li>
                            )}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              }
            )):(
              <tr>
                <td colSpan={columnCount} className="border-0 text-center">No documents found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableData;
