import ShowMoreText from "components/shared/ShowMoreText";
import { useFormik } from "formik";
import { decode } from "html-entities";
import { memo, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

const ManageNotes = memo(({ page, notesData, handleSave }) => {
  const [editMode, setEditMode] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [noteValue, setNoteValue] = useState("");

  let existingNoteValue = useMemo(() => {
    if (!notesData?.length) {
      return "";
    }

    return notesData.find(note => (note.page_id == page.form_page_id || !note.page_id))?.general_notes || "";
  }, [notesData]);

  // console.log("initialValues", initialValues, notesData)

  const formik = useFormik({
    initialValues: { notes: noteValue },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await handleSave(values.notes, page);
        setSubmitting(false);
        disableEditMode();
      } catch (err) {
        console.log("Error saving notes", err);
        setSubmitting(false);
      }
    }
  })

  useEffect(() => {
    if (!page || !editMode || !formik.dirty || formik.isSubmitting) {
      return;
    }
    let timeout = setTimeout(async () => {
      try {
        setIsAutoSaving(true);
        await handleSave(formik.values.notes, page);
        setIsAutoSaving(false);
      } catch (err) {
        console.log("Error while auto saving notes", err);
        setIsAutoSaving(false);
      }
    }, 1000);

    return () => clearTimeout(timeout)
  }, [formik.values.notes, formik.isSubmitting, editMode]);

  const enableEditMode = () => {
    setNoteValue(existingNoteValue);
    setEditMode(true);
  }

  const disableEditMode = () => {
    formik.resetForm();
    setEditMode(false);
  }

  return (
    <div className="my-2 p-3 bg-light">
      <div className="d-flex align-items-baseline">
        <h6>
          My Notes
          {(page && page.page_title) && `: ${decode(page.page_title)}`}
        </h6>
        <p className="px-1">(not visible to clients)</p>
      </div>
      <div className="py-1">
        {editMode ? (
          <textarea
            className="form-control resizable"
            rows={4}
            name="notes"
            disabled={!editMode}
            onChange={formik.handleChange}
            value={formik.values.notes}
          >
          </textarea>
        ):(
          <textarea
            className="form-control resizable"
            rows={4}
            disabled={true}
            value={existingNoteValue}
          >
          </textarea>
        )}
      </div>
      <div className="py-1">
        {editMode ? (
          <div className="d-flex gap-10">
            <Link
              onClick={formik.submitForm}
            >
              {formik.isSubmitting ? "Saving..." : "Save"}
            </Link>
            {/* <Link
              onClick={disableEditMode}
            >
              Cancel
            </Link> */}
          </div>
        ) : (
          <Link
            onClick={enableEditMode}
          >
            Edit
          </Link>
        )}
        {(!formik.isSubmitting && isAutoSaving) && (
          <p className="text-muted m-0">Autosaving...</p>
        )}
      </div>
    </div>
  )
})

export default ManageNotes;