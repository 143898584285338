import { APPT_BOOKING_REASON_MAX, APPT_BOOKING_REASON_MIN } from "common/constants";
import { string } from "yup";

export const apptBookReasonValidation = string()
      .required("Required")
      .max(APPT_BOOKING_REASON_MAX, `Must be at most ${APPT_BOOKING_REASON_MAX} characters`)
      .min(APPT_BOOKING_REASON_MIN, `Must be at least ${APPT_BOOKING_REASON_MIN} characters`)

export const stringRequired = (fieldName, { min = 1, max = 1500 } = {}) => string()
      .required(`${fieldName} is required`)
      .max(max, `${fieldName} must be at most ${max} characters`)
      .min(min, `${fieldName} must be at least ${min} characters`)