import { useCallback, useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { decode } from "html-entities";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import { parseHtml, transformYupErrors } from "helpers/common";
import RecommendationDataPopup from "../Modal/RecommendationDataPopup/Index";

// icons
import { ReactComponent as EditIcon } from "Assets/Images/icn/Edit.svg";
import { ReactComponent as DeleteIcon } from "Assets/Images/icn/delete.svg";
import { ReactComponent as AddIcon } from "Assets/Images/icn/AddCircle.svg";
import AppCKEditor from "components/shared/AppCKEditor";

const recommendationRow = {
  recommendation: "",
  benefit: "",
  per_day: "",
  upon_waking: "",
  with_breakfast: "",
  midmorning: "",
  with_lunch: "",
  midafternoon: "",
  with_dinner: "",
  before_bed: "",
}

const validationSchema = Yup.object({
  visit: Yup.string()
      .trim()
      .required("This field is required")    
});

const AddNewRecommendation = ({ data, saveData, nextStep, previousStep }) => {
  const [showRecommendationDataPopup, setShowRecommendationDataPopup] = useState(false);
  const [currentEditingRow, setCurrentEditingRow] = useState(null);
  const [currentRecommendationType, setCurrentRecommendationType] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  // recommendation rows
  const [rows, setRows] = useState({
    nutritional: [{ ...recommendationRow }],
    lifestyle: [{ ...recommendationRow }],
    supplement: [{ ...recommendationRow }]
  });

  // other form fields
  const [fields, setFields] = useState({
    visit: "",
    additional_nutritional: "",
    additional_lifestyle: "",
    additional_supplement: "",
    client_goals: "",
  });

  useEffect(() => {
    // validateForm();
    const timeout = setTimeout(saveRecommendation, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [fields]);

  useEffect(() => {
    saveRecommendation();
  }, [rows]);

  useEffect(() => {
    setFields({
      visit: data.visit_number || "",
      additional_nutritional: data?.additional_recommendation?.nutritional || "",
      additional_lifestyle: data?.additional_recommendation?.lifestyle || "",
      additional_supplement: data?.additional_recommendation?.supplement || "",
      client_goals: data.client_goals || "",
    });

    setRows({
      nutritional: data?.recommendation?.nutritional || [{ ...recommendationRow }],
      lifestyle: data?.recommendation?.lifestyle || [{ ...recommendationRow }],
      supplement: data?.recommendation?.supplement || [{ ...recommendationRow }],
    });
  }, [data]);

  const closeAddDataPopup = () => {
    setCurrentEditingRow(null);
    setShowRecommendationDataPopup(false);
  }

  const showAddDataPopup = (index, type) => {
    setCurrentRecommendationType(type);
    setCurrentEditingRow(index);
    setShowRecommendationDataPopup(true);
  }

  const validateForm = async () => {
    try {
      await validationSchema.validate(fields, {abortEarly: false});
      setErrors({});
      return null;
    } catch(err) {
      let validationErrors = transformYupErrors(err);
      if(validationErrors) {
        setErrors(validationErrors);
      }
      return validationErrors;
    }
  }

  const addNewRow = (type) => {
    const existingRows = { ...rows };
    existingRows[type].push({ ...recommendationRow });
    setRows(existingRows);
  }

  const deleteRow = (index, type) => {
    const existingRows = { ...rows };
    if (typeof existingRows[type][index] !== "undefined" && existingRows[type].length > 1) {
      existingRows[type].splice(index, 1);
      setRows(existingRows);
    }
  }

  const handleSaveRecommendationData = (rowIndex, values, type) => {
    try {
      let newRows = { ...rows };
      if (typeof newRows[type][rowIndex] !== "undefined") {
        newRows[type][rowIndex] = values;
        setRows(newRows);
      }
    } catch (error) {
      throw error;
    }
  }

  const saveRecommendation = async () => {
    try {
      let errors = await validateForm();
      if(errors && !isEmpty(errors)) {
        return;
      }
      let data = {
        recommendation: JSON.stringify(rows),
        additional_recommendation: JSON.stringify({
          nutritional: fields.additional_nutritional && fields.additional_nutritional.trim(),
          lifestyle: fields.additional_lifestyle && fields.additional_lifestyle.trim(),
          supplement: fields.additional_supplement && fields.additional_supplement.trim(),
        }),
        client_goals: fields.client_goals && fields.client_goals.trim(),
        visit_number: fields.visit,
        recommendation_type: "nutritional"
      }
      await saveData(data);
      return true;
    } catch(err) {
      console.log("Error saving recommendation data", err);
      return err;
    }
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      let isSaved = await saveRecommendation();
      setSubmitting(false);
      if(isSaved === true) {
        nextStep();
      }
    } catch (error) {
      console.log("Error saving recommendation", error);
      setSubmitting(false);
    }
  }

  const handleFieldChange = useCallback(
    name => evt => {
      setFields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    }, []);

  const shiftRowUp = (index, type) => {
    const newRowPosition = index - 1;
    const newRows = [...rows[type]];
    const rowToShift = newRows[index];
    const origRowAtIndex = newRows[newRowPosition];
    if (newRows[newRowPosition]) {
      newRows[newRowPosition] = rowToShift;
      newRows[index] = origRowAtIndex;
    }
    setRows(prevState => ({
      ...prevState,
      [type]: newRows
    }))
  }

  const shiftRowDown = (index, type) => {
    const newRowPosition = index + 1;
    const newRows = [...rows[type]];
    const rowToShift = newRows[index];
    const origRowAtIndex = newRows[newRowPosition];
    if (newRows[newRowPosition]) {
      newRows[newRowPosition] = rowToShift;
      newRows[index] = origRowAtIndex;
    }
    setRows(prevState => ({
      ...prevState,
      [type]: newRows
    }))
  }

  return (
    <>
      <RecommendationDataPopup
        showPopup={showRecommendationDataPopup}
        closePopup={closeAddDataPopup}
        handleSubmit={handleSaveRecommendationData}
        rowIndex={currentEditingRow}
        rows={rows}
        recommendationType={currentRecommendationType}
      />
      <div className="py-2">
        <div className="top d-flex align-items-start justify-content-between gap-10">
          <div className="left">
            <h6 className="m-0 fw-bold">
              Recommendation
            </h6>
          </div>
        </div>
        <div className="my-3">
          <Row>
            <Col lg="3" className="py-2">
              <label className="m-0 fw-bold">Visit #</label>
              <input
                type="text"
                name="visit"
                className={`form-control ${errors.visit && "is-invalid"}`}
                onChange={handleFieldChange("visit")}
                value={fields.visit}
              />
              {errors.visit && (
                <p className="invalid-feedback">{errors.visit}</p>
              )}
            </Col>
            <Col lg="12" className="py-2">
              <div className="recommendation-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Nutritional Recommendation</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Benefit / Instructions / Notes</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Per Day</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Upon Waking</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Breakfast</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-morning</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Lunch</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-afternoon</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Dinner</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Before Bed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRows
                      rows={rows.nutritional}
                      addNewRow={addNewRow}
                      deleteRow={deleteRow}
                      showAddDataPopup={showAddDataPopup}
                      type="nutritional"
                      shiftRowUp={shiftRowUp}
                      shiftRowDown={shiftRowDown}
                    />
                    <tr>
                      <Button
                        className="border-0 py-2 d-flex align-items-center fw-sbold gap-10 px-lg-5"
                        variant="transparent"
                        onClick={() => {
                          addNewRow("nutritional");
                        }}
                      >
                        <AddIcon />
                        <span className="icn">
                          Add
                        </span>{" "}
                      </Button>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col lg="12" className="py-2">
              <label className="m-0 fw-bold">Additional Nutritional Recommendations</label>
              <AppCKEditor
                value={fields.additional_nutritional}
                onChange={value => handleFieldChange("additional_nutritional")({ target: { value } })}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4 py-2">
              <div className="recommendation-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Lifestyle Recommendation</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Benefit / Instructions / Notes</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Per Day</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Upon Waking</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Breakfast</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-morning</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Lunch</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-afternoon</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Dinner</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Before Bed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRows
                      rows={rows.lifestyle}
                      addNewRow={addNewRow}
                      deleteRow={deleteRow}
                      showAddDataPopup={showAddDataPopup}
                      type="lifestyle"
                      shiftRowUp={shiftRowUp}
                      shiftRowDown={shiftRowDown}
                    />
                    <tr>
                      <Button
                        className="border-0 py-2 d-flex align-items-center fw-sbold gap-10 px-lg-5"
                        variant="transparent"
                        onClick={() => {
                          addNewRow("lifestyle");
                        }}
                      >
                        <AddIcon />
                        <span className="icn">
                          Add
                        </span>{" "}
                      </Button>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col lg="12" className="py-2">
              <label className="m-0 fw-bold">Additional Lifestyle Recommendations</label>
              <AppCKEditor
                value={fields.additional_lifestyle}
                onChange={value => handleFieldChange("additional_lifestyle")({ target: { value } })}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4 py-2">
              <div className="recommendation-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Supplement Recommendation</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Benefit / Instructions / Notes</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Per Day</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Upon Waking</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Breakfast</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-morning</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Lunch</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Mid-afternoon</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">With Dinner</th>
                      <th className="p-3 fw-normal text-dark fw-sbold">Before Bed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRows
                      rows={rows.supplement}
                      addNewRow={addNewRow}
                      deleteRow={deleteRow}
                      showAddDataPopup={showAddDataPopup}
                      type="supplement"
                      shiftRowUp={shiftRowUp}
                      shiftRowDown={shiftRowDown}
                    />
                    <tr>
                      <Button
                        className="border-0 py-2 d-flex align-items-center fw-sbold gap-10 px-lg-5"
                        variant="transparent"
                        onClick={() => {
                          addNewRow("supplement");
                        }}
                      >
                        <AddIcon />
                        <span className="icn">
                          Add
                        </span>{" "}
                      </Button>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col lg="12" className="py-2">
              <label className="m-0 fw-bold">Additional Supplement Recommendations</label>
              <AppCKEditor
                value={fields.additional_supplement}
                onChange={value => handleFieldChange("additional_supplement")({ target: { value } })}
              />
            </Col>
          </Row>
          <hr></hr>
          <Row className="mt-4">
            <Col lg="12" className="py-2">
              <label className="m-0 fw-bold">Client Goals</label>
              <textarea
                rows={5}
                name="client_goals"
                className="form-control"
                onChange={handleFieldChange("client_goals")}
                value={fields.client_goals}
              ></textarea>
            </Col>
          </Row>
          {errors && !isEmpty(errors) && (
            <Row className="mt-4">
              <Col lg="12">
                <p className="invalid-feedback d-block m-0">Form contains errors, please review your entries.</p>
              </Col>
            </Row>
          )} 
          <Row>
            <Col lg="12" className="d-flex gap-10 mt-4">
              <Button
                onClick={previousStep}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                Back
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                {isSubmitting ? "Saving..." : "Next"}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const TableRows = ({ rows, showAddDataPopup, deleteRow, type, shiftRowUp, shiftRowDown }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr>
              <td className="p-3 border-0 d-flex">
                <div>
                  <Button
                    className="border-0 py-2 d-flex align-items-center fw-sbold gap-10"
                    variant="transparent"
                    onClick={() => {
                      showAddDataPopup(index, type);
                    }}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    className="border-0 py-2 d-flex align-items-center fw-sbold gap-10"
                    variant="transparent"
                    onClick={() => {
                      deleteRow(index, type);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
                {rows.length > 1 &&
                  <div>
                    <Button
                      className="sort-btn commonBtn btnSm"
                      // variant="transparent"
                      disabled={index === 0}
                      onClick={() => {
                        shiftRowUp(index, type);
                      }}
                    >
                      {/* <i className="fa fa-sort fa-sort-up" /> */}
                      Move Up
                    </Button>
                    <Button
                      className="sort-btn commonBtn btnSm"
                      // variant="transparent"
                      disabled={index === rows.length - 1}
                      onClick={() => {
                        shiftRowDown(index, type);
                      }}
                    >
                      {/* <i className="fa fa-sort fa-sort-down" /> */}
                      Move Down
                    </Button>
                  </div>
                }
              </td>
              <td className="p-3 border-0">
                <p className="m-0">{decode(row.recommendation)}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0">
                  {parseHtml(decode(row.benefit))}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day}</td>
              <td className="p-3 border-0">{row.upon_waking}</td>
              <td className="p-3 border-0">{row.with_breakfast}</td>
              <td className="p-3 border-0">{row.midmorning}</td>
              <td className="p-3 border-0">{row.with_lunch}</td>
              <td className="p-3 border-0">{row.midafternoon}</td>
              <td className="p-3 border-0">{row.with_dinner}</td>
              <td className="p-3 border-0">{row.before_bed}</td>
            </tr>
          )
        })
      }
    </>
  )
}
export default AddNewRecommendation;