import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

// icn
import { ReactComponent as EditIcn } from "../../../../../../Assets/Images/icn/pencil.svg";
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as HelpIcn } from "../../../../../../Assets/Images/icn/Help.svg";
import EnableDisablePop from "components/Modals/EnableDisablePop";
//imported
import { addUpdateAccountSetting_ } from 'store/services/accountSettingService';
import * as actions from 'store/actions';
import FormDetailsPopup from "./Components/Modals/FormDetailsPopup";

const AccountSetting = ({dispatch, accountSettingList, defaultFormList, setSuccessResponse, selectedCheckboxes, setSelectedCheckboxes, showMessage, setShowMessage, sending, setSending, currentTabValue, setCurrentTabValue}) => {
  const [editDisplayName, setDisplayName] = useState();
  const [onOffPop, setOnOffPop] = useState();
  const [popHeading, setPopHeading] = useState("");
  const [showFormDetailPopup, setShowFormDetailPopup] = useState(false);
  const [formHelpText, setFormHelpText] = useState(null);
  const [editSettingKey, setEditSettingKey] = useState(null);

  const handleDisplayName = (value) => {
    setDisplayName(!editDisplayName);
    if(value === 'cancel'){
      setCurrentTabValue(null)
    }else{
      setCurrentTabValue(value)
    }
  };
  
  const handleOnOff = (heading, value) => {
    setOnOffPop(!onOffPop);
    setPopHeading(heading);
    setCurrentTabValue(value)
  };

  const [fields, setfields] = useState({
    data_set : currentTabValue ? currentTabValue.data_set : ""
  })

  useEffect(() => {
    if (currentTabValue) {
      setfields(prevState => ({
        ...prevState,
        ...currentTabValue,
      }))
    }
  }, [currentTabValue, accountSettingList])

  //submit setting data
  const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
        let final_obj = {
          data_set : fields.data_set
        }
        let fieldsId = fields.id
        // return
        final_obj = JSON.stringify(final_obj)
        setSending(true)
        setSuccessResponse(false)
        await dispatch(addUpdateAccountSetting_(fieldsId, final_obj));
        setSending(false)
        setDisplayName(false)
        setSuccessResponse(true)
        setOnOffPop(false)
        setCurrentTabValue(null)
        setEditSettingKey(null);
    } catch (err) {
        console.log(err);
        setSending(false)
        setSuccessResponse(false)
    }
  }

  const defaultForm = async () => {
    try{
      let final_obj = {
        data_set : JSON.stringify(selectedCheckboxes)
      }
      let fieldsId = currentTabValue?.id
      final_obj = JSON.stringify(final_obj)
      if(fieldsId){
        let res = await dispatch(addUpdateAccountSetting_(fieldsId, final_obj));
        if(res.success === true){
          setShowMessage(true)
        }
        setCurrentTabValue(null)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if(selectedCheckboxes?.length>0){
      defaultForm();
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 2000); 
  }, [selectedCheckboxes])


  //handle changes
  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )
  // checkbox
  const handleCheckboxChange = (event, checkboxId, settingData) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
      setCurrentTabValue(settingData)
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== checkboxId)
      );
      setCurrentTabValue(settingData)
    }
  };

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  }

  const openFormDetailModal = (helpText) => {
    setShowFormDetailPopup(true);
    setFormHelpText(helpText);
  }

  const closeFormDetailModal = () => {
    setShowFormDetailPopup(false);
    setFormHelpText(null);
  }
  
  return (
    <>
      <EnableDisablePop
        heading={popHeading}
        onOffPop={onOffPop}
        setOnOffPop={setOnOffPop}
        currentTabValue={currentTabValue}
        setCurrentTabValue={setCurrentTabValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange('data_set')}
        sending={sending}
      />

      <FormDetailsPopup
        showPopup={showFormDetailPopup}
        handleClose={closeFormDetailModal}
        content={formHelpText}
      />

      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Account Setting</h3>
      </div>
      <div className="TabBody py-3">
        {accountSettingList?.length > 0 && accountSettingList?.map((settingList, idx) => {
          return(
            <div key={idx}>
              {settingList.data_key === 'display_name' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Display Name:{" "}
                          <span className="fw-normal">{settingList.data_set}</span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() => handleDisplayName(settingList)}
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        Your Display Name is the name we'll use any time we communicate with your clients.
                        You should make your display name something your clients will recognize like
                        "Dr. Rachel Geller" or include your company name like "Sara at Peak Health".
                      </p>
                      {editDisplayName ? (
                        <>
                          <div className="commonCardBox p-3 mt-3">
                            <Form onSubmit={handleSubmit}>
                              <Row>
                                <Col lg="6" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-clr fw-sbold m-0"
                                  >
                                    Your Display Name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="NTA Academic Team"
                                    className="form-control"
                                    value={fields.data_set}
                                    onChange={handleChange("data_set")}
                                  />
                                </Col>
                                <Col lg="12" className="my-2">
                                  <p className="m-0 py-2">
                                    Here's a sample of what part of your welcome email
                                    to clients will look like based on your input above.
                                  </p>
                                  <div className="noteCstm alert py-2 px-3">
                                    <p className="m-0">
                                      {" "}
                                      <b>{fields.data_set}</b> would like you to sign
                                      up to use Nutri-Q.
                                    </p>
                                  </div>
                                </Col>
                                <Col lg="12" className="my-2">
                                  <div className="FormbtnWrpper d-flex align-items-center gap-10">
                                    <Button
                                      onClick={() => handleDisplayName('cancel')}
                                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      // onClick={handleDisplayName}
                                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                    >
                                    {sending ? 'wait...' : 'Update Display Name'}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              }
              {settingList.data_key === 'direct_link_clients' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                  <div className="top d-flex align-items-center justify-content-between gap-10">
                    <div className="left">
                      <h6 className="m-0 fw-bold">
                        Direct Link Clients:{" "}
                        <span className="fw-normal">
                          ({ settingList.data_set == "enable" ? "Enabled" : "Disabled" })
                        </span>
                      </h6>
                    </div>
                    <div className="right">
                      <Button
                        onClick={() => handleOnOff("Direct Link Clients", settingList)}
                        className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                      >
                        <span className="icn me-2">
                          <EditIcn />
                        </span>
                        Edit
                      </Button>
                    </div>
                  </div>
                  <div className="CardBody py-2">
                    <p className="m-0">
                      Enabling the 'Direct Link Clients' option allows you to send
                      your clients direct links that will take them directly to their
                      To-Do Items. They will not create a Nutri-Q account and they
                      will not have access to the site other than the To-Do items that
                      you send to them. Please use this option if your clients won't
                      be logging their foods and you'd prefer to have them go directly
                      to their To-Do items without having access to the rest of the
                      site. This is also a great option if you are working with
                      multiple family members that are all sharing a single email
                      address. Otherwise they will need to share a single site login.
                    </p>
                  </div>
                  </div>
                </div>
              }
              {/* {settingList.data_key === 'client_anytime_logging' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Client Anytime Logging:{" "}
                          <span className="fw-normal">
                            ({ settingList.data_set == "enable" ? "Enabled" : "Disabled" })
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() => handleOnOff("Client Anytime Logging", settingList)}
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        There are two food log options. Anytime Logging allows your
                        Active Clients to log their foods and feelings at any time and
                        it also lets you assign the "Food & Feeling Log" to them. (This
                        is the logging that has been in place since this site launched.)
                        If you disable Anytime Logging then you can assign the "Food &
                        Mood Journal" to your client. This new food log corresponds to
                        the one currently used by the NTA.
                      </p>
                    </div>
                  </div>
                </div>
              } */}
              {settingList.data_key === 'show_burden_graphs_to_clients' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Show NAQ Symptom Burden Graphs to Clients:{" "}
                          <span className="fw-normal">
                            ({ settingList.data_set == "enable" ? "Enabled" : "Disabled" })
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() =>
                            handleOnOff("Show NAQ Symptom Burden Graphs to Clients", settingList)
                          }
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        When enabled new Active Clients will automatically be able to
                        see their Symptom Burden Graphs after they fill out a new NAQ.
                        This setting will be the default for NEW Active Clients but this
                        can also be set and changed at the client level.
                      </p>
                    </div>
                  </div>
                </div>
              }
              {settingList.data_key === 'multi_report_download' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Multi-Report Download:{" "}
                          <span className="fw-normal">
                            ({ settingList.data_set == "enable" ? "Enabled" : "Disabled" })
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() => handleOnOff("  Multi-Report Download", settingList)}
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        When enabled, you can use the Multi-Report Download tool to select multiple reports
                        from your client's Submitted Data section and export them together as a single consolidated PDF file.
                      </p>
                    </div>
                  </div>
                </div>
              }
              {settingList.data_key === "min_fmj_days" &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Food & Mood Journal required number of days:{" "}
                          <span className="fw-normal">
                            ({ settingList.data_set })
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() => {
                            setCurrentTabValue(settingList);
                            setEditSettingKey("min_fmj_days")
                          }}
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        The number of days a client must log before they can submit their Food & Mood Journal.
                        By default, clients are required to log three days before submitting.
                      </p>
                      {editSettingKey == "min_fmj_days" && (
                        <>
                          <div className="commonCardBox p-3 mt-3">
                            <Form onSubmit={handleSubmit}>
                              <Row>
                                <Col lg="6" className="my-2">
                                  <input
                                    type="number"
                                    min={3}
                                    max={7}
                                    placeholder="Enter number of required days"
                                    className="form-control"
                                    value={fields.data_set}
                                    onChange={handleChange("data_set")}
                                  />
                                </Col>
                                <Col lg="12" className="my-2">
                                  <div className="FormbtnWrpper d-flex align-items-center gap-10">
                                    <Button
                                      onClick={() => setEditSettingKey(null)}
                                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                    >
                                    {sending ? 'wait...' : 'Update'}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              }
              {settingList.data_key === 'default_forms' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">Default Forms</h6>
                      </div>
                      {/* <div className="right">
                        <Button className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3">
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div> */}
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        The following checked forms are shown when you select a new
                        To-Do item for an existing or new client. If you no longer wish
                        to see a particular form listed when choosing a new To-Do item
                        then you can uncheck the box next to the form name.
                      </p>
                      <Row>
                        <Col lg="6">
                          <ul className="list-unstyled p-0 mb-0">
                            {defaultFormList.length > 0  && defaultFormList.map((form, id) => {
                              return(
                                <li className="py-1" key={id}>
                                  <div className="py-2 d-flex align-items-start gap-10">
                                    <div className="cstmInputCheckWrp position-relative ">
                                      <input
                                        type="checkbox"
                                        name="c-ToDo"
                                        className="position-absolute w-100 h-100"
                                        checked={selectedCheckboxes?.includes(form.id)} 
                                        onChange={(e) => handleCheckboxChange(e, form.id, settingList)}
                                      />
                                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                        <span className="icn d-none">
                                          <OTick />
                                        </span>
                                      </div>
                                    </div>
                                    <p className="m-0 d-flex align-items-center">
                                      {form.form_name}
                                      {form.help_text &&
                                        <Link
                                          onClick={() => {
                                            openFormDetailModal(form.help_text)
                                          }}
                                        >
                                          <HelpIcn
                                            width={20}
                                            height={20}
                                            style={{padding: "5px"}}
                                          />
                                        </Link>
                                      }
                                    </p>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                          {showMessage && <p className="save_content">Saved!...</p>}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>
    </>
  );
};


const mapStateToPros = (state) => {
  return{
      // accountSettingList: state.AccountSetting.accountSettingList,
  }
};

function mapDispatchToProps(dispatch) {
return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(AccountSetting);
