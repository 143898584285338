import React from "react";
import { formatText } from "helpers/common";
import { Link } from "react-router-dom";

const CommonTable = ({
  isLoading,
  data,
  columns,
  sortOrder,
  setSortOrder,
  sortBy,
  setSortBy,
}) => {
  if(!columns?.length) {
    throw new Error("No columns provided");
  }
  return (
    <>
        <table className="table">
          <thead className="bg-transparent">
            <tr>
            {columns?.map((col, index) => (
              <th
                key={index}
                className={col.className || "p-3 fw-normal text-dark fw-bold capitalize"}
                style={{ width: col.width || "auto" }}
              >
                {col.sortable ? (
                  <Link
                    onClick={() => {
                      setSortBy(col.key);
                      setSortOrder(sortBy === col.key && sortOrder === "asc" ? "desc" : "asc");
                    }}
                    className="text-dark fw-bold"
                  >
                    {col.label || formatText(col.key)}
                    <i className={`fa fa-sort${sortBy === col.key ? (sortOrder === "asc" ? "-up" : "-down") : ""} px-2`}></i>
                  </Link>
                ) : (
                  col.label || formatText(col.key)
                )}
              </th>
            ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="border-0 text-center">Loading...</td>
              </tr>
            ):data?.length ? (
              data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    {columns &&
                      columns.length > 0 &&
                      columns.map((col, index) => {
                        if (col.component) {
                          return (
                            <td className="px-3 py-2">
                              {col.component(item, idx)}
                            </td>
                          );
                        }

                        return (
                          <td className="">{item[col?.key] || "--"}</td>
                        );
                      })}
                  </tr>
                );
              }
            )):(
              <tr>
                <td colSpan={columns.length} className="text-center">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
    </>
  );
};

export default CommonTable;
