import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import RecentSidebar from "./Component/RecentSidebar/RecentSidebar";
import ColorCards from "./Component/ColorCards/ColorCards";

// icns
import { ReactComponent as RecentIcn } from "../../../Assets/Images/icn/Recent.svg";
import { ReactComponent as RecentIcn2 } from "../../../Assets/Images/icn/Recent2.svg";
import { ReactComponent as GCal } from "../../../Assets/Images/icn/GreyCalender.svg";
import { ReactComponent as Ystar } from "../../../Assets/Images/icn/starYellow.svg";
import { ReactComponent as Wstar } from "../../../Assets/Images/icn/starWhite.svg";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
import { getDashboardContent } from "store/services/miscService";
import { DATE_FORMAT, FULL_NAME, UC_FIRST, dateTimeRange, formatDate, formatText, hasActivePlan, isDateWithinRange, parseTimeString, timeZoneFormat_ } from "helpers/common";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { getTagsList } from "store/services/tagsService";
import { getAccountSettingList_ } from "store/services/accountSettingService";
import GettingStarted from "./Component/GettingStarted";
import { helpEmail } from "common/constants";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";

const Dashboard = ({
  dispatch,
  user,
  profile,
  is_free_account,
  completedForms,
  favoriteClients,
  feelingLog,
  recentEvents,
  taggedClients,
  upcomingAppointments,
  tags,
  accountSettings,
  stepsProgress,
  announcements,
}) => {
  const [RecentTab, setRecentTab] = useState();
  const [selectedTag, setSelectedTag] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(14, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      try {
        getPractDashboardContent();
        dispatch(getAccountSettingList_());
      } catch (err) {
        console.log("Error occured in init function", err);
      }
    }

    init();
  }, []);

  useEffect(() => {
    if (tags && tags.length > 0 && !selectedTag) {
      // check if selected tag exists in local storage and preselect if found
      let prevSelectedTag = localStorage.getItem("dash_selected_tag");
      let tagExist = tags.find(tag => tag.name == prevSelectedTag);
      if (tagExist) {
        setSelectedTag(tagExist?.name);
      } else {
        setSelectedTag(tags[0].name);
      }
    }
  }, [tags])

  useEffect(() => {
    if (selectedTag) {
      localStorage.setItem("dash_selected_tag", selectedTag);
    }
  }, [selectedTag])

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    getPractDashboardContent();
  }, [startDate, endDate]);

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleRecentTab = () => {
    setRecentTab(!RecentTab);
  };
  const checkPlanStatus = async () => {
    if (profile?.terms_accepted === 'no') {
      navigate(Path.dashboardTermsCondition)
    } else if (!hasActivePlan(profile)) {
      navigate(Path.dashboardSubscription);
    }
  };
  useEffect(() => {
    checkPlanStatus();
  }, [profile]);

  const handleCreateEvent = async () => {
    // const auth = new google.auth.OAuth2(
    //   'YOUR_CLIENT_ID',
    //   'YOUR_CLIENT_SECRET',
    //   'YOUR_REDIRECT_URI'
    // );

    // auth.setCredentials({ access_token: 'ACCESS_TOKEN' }); // Set the access token obtained during the OAuth flow

    // const calendar = google.calendar({ version: 'v3', auth });

    // const event = {
    //   summary: 'Meeting with John',
    //   description: 'Discuss project progress',
    //   start: {
    //     dateTime: '2023-11-03T10:00:00',
    //     timeZone: 'YourTimeZone',
    //   },
    //   end: {
    //     dateTime: '2023-11-03T12:00:00',
    //     timeZone: 'YourTimeZone',
    //   },
    // };

    try {
      //   const response = await calendar.events.insert({
      //     calendarId: 'primary', // Use 'primary' to insert events into the user's primary calendar
      //     resource: event,
      //   });

      console.log("Event created: ", response.data);
    } catch (err) {
      console.error("Error creating event: ", err);
    }
  };

  const getPractDashboardContent = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let payload = {
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
      await dispatch(getDashboardContent(payload));
      await dispatch(getTagsList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("Error getting dashboard content", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <section className="dashboard d-flex align-items-start ">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="commonContent py-2">
                  <h3 className="m-0 fw-bold">
                    Hi {user?.first_name}, Welcome Back
                  </h3>
                  <p className="m-0">
                    When a Client Logs a Meal or Feeling We'll Show It Here.
                  </p>
                </div>
                <div className="recentBtn">
                  <Button
                    onClick={handleRecentTab}
                    className="d-flex align-items-center justify-cotent-center commonBtn btnSm d-xl-none"
                  >
                    <span className="icn">
                      <RecentIcn />
                    </span>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <ColorCards
                completedForms={completedForms}
                taggedClients={taggedClients}
                upcomingAppointments={upcomingAppointments}
              />
            </Col>
            <GettingStarted
              dispatch={dispatch}
              accountSettings={accountSettings}
              stepsProgress={stepsProgress}
            />
            <Col lg="12" className="my-2">
              <Row>
                <Col lg="8" className="my-2">
                  <div className="completed-forms py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom d-flex justify-content-between align-items-center">
                        <h3 className="m-0 py-2 fw-bold">
                          Newly Completed Forms
                        </h3>
                        <div>
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={handleDateRangeChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            // isClearable={true}
                            placeholderText="Select a time range"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="CardBody px-lg-4 px-3 py-3"
                      // style={{ overflowY: "scroll", maxHeight: "600px" }}
                      >
                        {(completedForms && completedForms.data && completedForms.data.length > 0) ?
                          <Row className="list-unstyled ps-0 mb-0 listView">
                            {completedForms.data.map(form => {
                              return (
                                <>
                                  <Col lg="6" className="my-2">
                                    <Link
                                      to={Path.myClientDetails.replace(":clientId", form.client_id)}
                                      className="text-dark d-flex align-items-start py-1 border p-3 py-3 rounded LinkCard"
                                    >
                                      <div
                                        className="imgWrp d-flex align-items-center me-2 justify-content-center rounded-circle circledIcn"
                                        style={{
                                          background: "#DEDEDE",
                                          height: "22px",
                                          width: "22px",
                                        }}
                                      >
                                        <Wstar />
                                      </div>
                                      <div className="content">
                                        <h6 className="m-0 fw-sbold">
                                          {FULL_NAME(form.first_name, form.last_name)}
                                        </h6>
                                        <p className="m-0">
                                          {form.form_name}
                                        </p>
                                        <span className="text-muted m-0 pt-2 mt-1 tym">
                                          {/* Mon, Sep 12th at 6:58 pm */}
                                          {`${moment(form.completed_at).format("ddd, MMM DD")} at ${moment(form.completed_at).format("h:mm A")}`}
                                        </span>
                                      </div>
                                    </Link>
                                  </Col>
                                </>
                              )
                            })}
                          </Row>
                          :
                          <div className="content py-2 text-center">
                            <p className="fw-bold m-0">
                              No completed forms were found in the selected date range.
                            </p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="4" className="my-2">
                  <div className="py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom">
                        <h3 className="m-0 py-2 fw-bold">
                          Favorite Clients
                        </h3>
                      </div>
                      <div
                        className="CardBody px-lg-4 px-3 py-3"
                        style={{ overflowY: "auto", maxHeight: "200px" }}
                      >
                        {favoriteClients && favoriteClients.data && favoriteClients.data.length > 0 ?
                          <ul className="list-unstyled ps-0 mb-0 listView">
                            {favoriteClients.data.map((client) => {
                              return (
                                <li className="" key={client.client_id}>
                                  <Link
                                    to={Path.myClientDetails.replace(":clientId", client.client_id)}
                                    className="text-dark d-flex align-items-center py-1"
                                  >
                                    <div
                                      className="imgWrp d-flex align-items-center justify-content-center rounded-circle circledIcn me-2"
                                      style={{
                                        background: "#FF5757",
                                        height: "22px",
                                        width: "22px",
                                      }}
                                    >
                                      <Ystar />
                                    </div>
                                    <div className="content">
                                      <p className="m-0 fw-sbold">
                                        {FULL_NAME(client.first_name, client.last_name)}
                                      </p>
                                    </div>
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                          :
                          <div className="content py-2">
                            <h6 className="fw-bold m-0">
                              No favorite clients
                            </h6>
                            <p className="m-0">
                              Make a client a favorite by clicking on the{` `}
                              <div className="imgWrp d-inline">
                                <StarRatings
                                  rating={0}
                                  starRatedColor="#e79d46"
                                  starDimension="20px"
                                  starSpacing="15px"
                                  numberOfStars={1}
                                  name="is_favorite"
                                />
                              </div>
                              {` `}next to their name and they'll show up here for quick access.
                            </p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom d-flex align-items-center justify-content-between">
                        <h3 className="m-0 py-2 fw-bold">
                          Tagged Clients
                        </h3>
                        {tags && tags.length > 0 &&
                          <Dropdown className="rightMenu">
                            <Dropdown.Toggle
                              style={{
                                backgroundColor: "#FF5757",
                                borderColor: "#FF5757",
                              }}
                              className="TagDropdown pe-3"
                              id="dropdown-basic"
                            >
                              {selectedTag}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="noLastBorder">
                              {tags.map(tag => {
                                return (
                                  <Dropdown.Item
                                    className="fw-sbold m-0 formText d-block px-3 py-1"
                                    onClick={(e) => setSelectedTag(tag.name)}
                                  >
                                    {tag.name}
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        }
                      </div>
                      <div
                        className="CardBody px-lg-4 px-3 py-3"
                        style={{ overflowY: "auto", maxHeight: "200px" }}
                      >
                        {taggedClients && taggedClients.data && taggedClients.data.length > 0 ?
                          <ul className="list-unstyled ps-0 mb-0 listView">
                            {taggedClients.data.map(client => {
                              let isTagAssigned = client.tags_assigned.find(tag => tag.name == selectedTag);
                              if (isTagAssigned) {
                                return (
                                  <li className="" key={client.client_id}>
                                    <Link
                                      to={Path.myClientDetails.replace(":clientId", client.client_id)}
                                      className="text-dark d-flex align-items-center gap-10 py-1"
                                    >
                                      <div className="content">
                                        <p className="m-0 fw-sbold">
                                          {FULL_NAME(client.first_name, client.last_name)}
                                        </p>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              }
                            })}
                          </ul>
                          :
                          <div className="content py-2">
                            <h6 className="fw-bold m-0">
                              No tagged clients
                            </h6>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="my-2">
                  <div className="commonCardBox border h-100">
                    <div className="top px-lg-4 px-3 py-2 border-bottom">
                      <h3 className="m-0 py-2 fw-bold">
                        Announcements
                      </h3>
                    </div>
                    <div
                      className="CardBody px-lg-4 px-3 py-3 announcements"
                      style={{ overflowY: "auto", maxHeight: "400px" }}
                    >
                      {announcements?.length > 0 ?
                        <ul className="list-unstyled">
                          {announcements.map((announcement, idx) => {
                            let createdAt = DATE_FORMAT(announcement.created_at);
                            return (
                              <li className="my-2 position-relative" key={idx}>
                                {isDateWithinRange(announcement.created_at, 7, "days") && (
                                  <span className="new-badge">•</span>
                                )}
                                {announcement?.description ? (
                                  <Accordion className="ColoredAcorrdion">
                                    <Accordion.Header
                                      className=""
                                      style={{
                                        background: "#F9FAFF",
                                        // border: "1px solid #2B3674",
                                        color: "#2B3674",
                                      }}
                                    >
                                      <span className="toggleBtn position-absolute"></span>
    
                                      <div className="d-flex align-items-center gap-10">
                                        {announcement.title}
                                      </div>
                                      <p className="m-0 text-dark pt-2">
                                        {createdAt}
                                      </p>
                                    </Accordion.Header>
                                    <Accordion.Body
                                      style={{
                                        borderTop: "1px solid #2B3674",
                                      }}
                                    >
                                      <div className="inner">
                                        {parse(announcement.description)}
                                      </div>
                                    </Accordion.Body>
                                  </Accordion>
                                ):(
                                  <div className="announcement-item">
                                    <div className="d-flex align-items-center gap-10 justify-content-between">
                                      <div>
                                        {announcement.title}
                                      </div>
                                    </div>
                                    <p className="m-0 text-dark pt-2">
                                      {createdAt}
                                    </p>
                                  </div>
                                )}
                              </li>
                            )
                          })}

                        </ul>
                        :
                        <div className="text-center py-2">
                          <div className="icn">
                            <RecentIcn2 />
                          </div>
                          <div className="content py-2">
                            <h6 className="fw-bold m-0">
                              No annoucements to show
                            </h6>
                            <p className="m-0">
                              When a announcement is added we'll show it here.
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </Col>
                {/* <Col lg="6" className="my-2">
                  <div className="commonCardBox border h-100">
                    <div className="top px-lg-4 px-3 py-2 border-bottom">
                      <h3 className="m-0 py-2 fw-bold">
                        Latest Logs
                      </h3>
                    </div>
                    <div
                      className="CardBody px-lg-4 px-3 py-3"
                      style={{ overflowY: "auto", maxHeight: "400px" }}
                    >
                      {feelingLog && feelingLog.data && feelingLog.data.length > 0 ?
                        <ul className="list-unstyled py-2">
                          {feelingLog.data.map(log => {
                            const logDate = moment(log.log_date);
                            const logTime = parseTimeString(log.log_time);
                            return (
                              <div className="d-flex align-items-start py-2" key={log.client_id}>
                                <div
                                  className="imgWrp d-flex align-items-center me-2 justify-content-center rounded-circle circledIcn"
                                  style={{
                                    background: "#DEDEDE",
                                    height: "22px",
                                    width: "22px",
                                  }}
                                >
                                  <Wstar />
                                </div>
                                <div className="content w-100">
                                  <Link
                                    to={`${Path.myClientDetails.replace(":clientId", log.client_id)}?tab=FMLogs`}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <div className="w-50">
                                        <p className="m-0 text-dark fw-sbold">{FULL_NAME(log.first_name, log.last_name)}</p>
                                        {log.log_type === "feeling" && (
                                          <p className="m-0">
                                            Feeling Logged: {UC_FIRST(log.feelings)}
                                          </p>
                                        )}
                                        {log.log_type === "meal" && (
                                          <p className="m-0">
                                            Meal Logged: {UC_FIRST(log.meal_type)}
                                          </p>
                                        )}
                                      </div>
                                      <div className="w-50 d-flex align-items-end justify-content-end">
                                        <p className="m-0">{`${logDate.format("ddd, MMM DD")} at ${logTime.format("hh:mm A")}`}</p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )
                          })}

                        </ul>
                        :
                        <div className="text-center py-2">
                          <div className="icn">
                            <RecentIcn2 />
                          </div>
                          <div className="content py-2">
                            <h6 className="fw-bold m-0">
                              No client logs to show
                            </h6>
                            <p className="m-0">
                              When a client logs a meal or feeling we'll show it
                              here.
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </Col> */}
                <Col lg="6" className="my-2">
                  <div className="commonCardBox border h-100">
                    <div className="top px-lg-4 px-3 py-2 border-bottom">
                      <h3 className="m-0 py-2 fw-bold">
                        Upcoming Appointments
                      </h3>
                    </div>
                    <div
                      className="CardBody px-lg-4 px-3 py-3"
                      style={{ overflowY: "auto", maxHeight: "400px" }}
                    >
                      {(upcomingAppointments && upcomingAppointments.data && upcomingAppointments.data.length > 0) ?
                        <div className="">
                          {upcomingAppointments.data.map(appointment => {
                            return (
                              <div className="d-flex align-items-center py-2">
                                <div className="imgWrp me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="27"
                                    viewBox="0 0 25 27"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_0_8148)">
                                      <path
                                        d="M22.0702 2.5918H20.8983V0.560547H18.9452V2.5918H6.05457V0.560547H4.10144V2.5918H2.92957C1.31414 2.5918 -0.00012207 3.95863 -0.00012207 5.63867V23.5137C-0.00012207 25.1937 1.31414 26.5605 2.92957 26.5605H22.0702C23.6856 26.5605 24.9999 25.1937 24.9999 23.5137V5.63867C24.9999 3.95863 23.6856 2.5918 22.0702 2.5918ZM23.0468 23.5137C23.0468 24.0737 22.6087 24.5293 22.0702 24.5293H2.92957C2.39109 24.5293 1.953 24.0737 1.953 23.5137V10.1074H23.0468V23.5137ZM23.0468 8.07617H1.953V5.63867C1.953 5.07866 2.39109 4.62305 2.92957 4.62305H4.10144V6.6543H6.05457V4.62305H18.9452V6.6543H20.8983V4.62305H22.0702C22.6087 4.62305 23.0468 5.07866 23.0468 5.63867V8.07617Z"
                                        fill="#f4694b"
                                      />
                                      <path
                                        d="M3.71082 12.2402H5.66394V14.2715H3.71082V12.2402ZM7.61707 12.2402H9.57019V14.2715H7.61707V12.2402ZM11.5233 12.2402H13.4764V14.2715H11.5233V12.2402ZM15.4296 12.2402H17.3827V14.2715H15.4296V12.2402ZM19.3358 12.2402H21.2889V14.2715H19.3358V12.2402ZM3.71082 16.3027H5.66394V18.334H3.71082V16.3027ZM7.61707 16.3027H9.57019V18.334H7.61707V16.3027ZM11.5233 16.3027H13.4764V18.334H11.5233V16.3027ZM15.4296 16.3027H17.3827V18.334H15.4296V16.3027ZM3.71082 20.3652H5.66394V22.3965H3.71082V20.3652ZM7.61707 20.3652H9.57019V22.3965H7.61707V20.3652ZM11.5233 20.3652H13.4764V22.3965H11.5233V20.3652ZM15.4296 20.3652H17.3827V22.3965H15.4296V20.3652ZM19.3358 16.3027H21.2889V18.334H19.3358V16.3027Z"
                                        fill="#f4694b"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_0_8148">
                                        <rect
                                          width="25"
                                          height="26"
                                          fill="white"
                                          transform="translate(-0.00012207 0.560547)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="content">
                                  <Link
                                    to={`${Path.appointment}?apptId=${appointment.appt_id}`}
                                  >
                                    <p className="m-0">Appointment Type: {appointment.title}</p>
                                    <p className="m-0">With: {FULL_NAME(appointment.first_name, appointment.last_name)}</p>
                                    <p className="m-0">Where: {formatText(appointment.location)}</p>
                                    <p className="m-0 text-dark fw-sbold">
                                      {dateTimeRange(appointment.start_time_tz, appointment.end_time_tz)}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        :
                        <div className="text-center py-2">
                          <div className="icn">
                            <GCal />
                          </div>
                          <div className="content py-2">
                            <h6 className="fw-bold m-0">
                              No upcoming appointments
                            </h6>
                            <p className="m-0">
                              When a client signs up for an appointment we'll
                              email you and also show your upcoming appointments
                              here.
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <p>
                Need help?{" "}
                <Link
                  target="_blank"
                  to={Path.helpHome}
                >
                  Check out our help site{" "}
                </Link>
                or email us at{" "}
                <a
                  href={`mail-to:${helpEmail}`}
                >
                  {helpEmail}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <RecentSidebar
          RecentTab={RecentTab}
          setRecentTab={setRecentTab}
          recentEvents={recentEvents}
        />
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  let dashboardData = state.Misc.dashboardData;
  return {
    authentication: state.Auth.isAuthenticated,
    user: state.Auth.user,
    profile: state.Profile?.user,
    is_free_account: state.Profile?.is_free_account,
    completedForms: dashboardData.completed_forms,
    favoriteClients: dashboardData.favorite_clients,
    feelingLog: dashboardData.latest_log,
    recentEvents: dashboardData.recentEvents,
    taggedClients: dashboardData.tagged_clients,
    upcomingAppointments: dashboardData.upcoming_appointments,
    announcements: dashboardData.announcements,
    tags: state.Tags.tags,
    accountSettings: state.AccountSetting.accountSettingList,
    stepsProgress: state.Misc.stepsProgress,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Dashboard);