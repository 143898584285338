import React, { useEffect, useRef, useState } from "react";
import {
  Accordion, Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
// import LinePlot from "./Component/TestChart";
import { Link, useParams } from "react-router-dom";

import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import * as actions from "store/actions";
import AllQuestionAnswers from "../../ToDoAllQuestionAnswers/Components/AllQuestionAnswers/Index";

const HealthWellnessQuestionAnswers = ({ eventKey, todoId, formId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion.Item
      eventKey={eventKey}
      className=" my-2"
    >
      <Accordion.Header
       className="accordian-cap "
      >
        All Questions/Answers
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="AccordionInner">
          <div className="inner">
            <AllQuestionAnswers
              todoId={todoId}
              formId={formId}
              isOpen={isOpen}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default HealthWellnessQuestionAnswers;