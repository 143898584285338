import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import TableData from "./Component/TableData";
import ReactPagination from "components/shared/ReactPagination";

// icn
import { ReactComponent as WPlus } from "../../../../Assets/Images/icn/plus.svg";
import { ReactComponent as FormVector } from "../../../../Assets/Images/icn/FormVector.svg";
import { ReactComponent as SearchIcn } from "../../../../Assets/Images/icn/search.svg";
import { ReactComponent as OTick } from "../../../../Assets/Images/icn/OrangeTick.svg";

//import
import {
  getResourceList,
  getResourcesCategories,
  resourcePagination_,
} from "store/services/resourceService";
import * as actions from "store/actions";

const MyResources = ({
  dispatch,
  resourceListing,
  categories,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([])

  const [activePage, setActivePage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0)

  useEffect(() => {
    getResourceCats();
  }, []);

  useEffect(() => {
    getData()
  }, [activePage, sortBy, sortOrder, selectedCategories]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      getData();
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm])

  // console.log(selectedCheckboxes, "selectedCheckboxeFilter")
  //get data
  async function getData() {
    try {
      let filter = {
        limit: itemsCountPerPage,
        page: activePage,
        orderBy: sortBy,
        order: sortOrder,
        search: searchTerm.toLowerCase().replace(/\s/g, ""),
      };

      if (selectedCategories && selectedCategories.length > 0) {
        filter.categories = selectedCategories;
      }

      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getResourceList(filter));
      if (res.success === true && res.body) {
        setItemsCountPerPage(res.body.per_page);
        setTotalItemsCount(res.body.total_count);

        setFilteredResults(res.body.data);
        // let value_ = res.body.data;
        // const uniqueCategories = new Set();
        // const filteredResultss = [];
        // value_.forEach((value) => {
        //   if (!uniqueCategories.has(value.category_name)) {
        //     // If the category name is not in the Set, add it to the Set and to the filteredResults
        //     uniqueCategories.add(value.category_name);
        //     filteredResultss.push(value);
        //   }
        // });
        // setSelectedCheckboxeFilter(filteredResultss);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const getResourceCats = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getResourcesCategories());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const handleSelectCategory = (catId) => {
    let selectedCats = [...selectedCategories];
    let alreadySelected = selectedCats.findIndex(id => id == catId);
    if (alreadySelected !== -1) {
      selectedCats.splice(alreadySelected, 1);
    } else {
      selectedCats.push(catId);
    }

    setSelectedCategories(selectedCats);
  }

  // useEffect(() => {
  //   const filterResults = async () => {
  //     const formattedSearchValue = searchTerm.toLowerCase().replace(/\s/g, "");

  //     if (formattedSearchValue === "") {
  //       getData();
  //     } else {
  //       const newFilteredResults = filteredResults.filter((item) => {
  //         const formattedCategoryName = item.category_name
  //           .toLowerCase()
  //           .replace(/\s/g, "");
  //         const formattedSubcategoryName = item.subcategory_name
  //           .toLowerCase()
  //           .replace(/\s/g, "");

  //         return (
  //           formattedCategoryName.includes(formattedSearchValue) ||
  //           formattedSubcategoryName.includes(formattedSearchValue)
  //         );
  //       });
  //       setFilteredResults(newFilteredResults);
  //     }
  //   };

  //   filterResults();
  // }, [searchTerm]);


  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
  }

  const SortableColumns = () => {
    let columns = [
      {
        key: "resource_name",
        sortable: true,
        name: "Document",
      },
      {
        key: "category_name",
        sortable: true,
        name: "Category",
      },
      {
        key: "subcategory_name",
        sortable: true,
        name: "Subcategory",
      },
    ];

    return columns.map(column => {
      let sortClassName = "fa-sort";
      let sortClass = "";
      if (sortBy == column.key) {
        sortClass = (sortOrder == "asc") ? "fa-sort-up" : "fa-sort-down";
      }
      return (
        <th className="p-3 fw-normal text-dark fw-sbold text-muted">
          {column.sortable ?
            <Link
              onClick={() => {
                let order = sortOrder === "asc" ? "desc" : "asc";
                setSortBy(column.key);
                setSortOrder(order);
              }}
            >
              {column.name}
              <i class={`fa ${sortClassName} ${sortClass} px-2`}></i>
            </Link>
            :
            <>
              {column.name}
            </>
          }
          {column.desc && <sub>({column.desc})</sub>}
        </th>
      )
    })
  }

  return (
    <>
      <section className="DocsForms position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div
                className="commonCardBox d-flex border-0 justify-content-between align-items-start gap-10 p-lg-4 p-3 ps-lg-5 pe-3 DocFormBox"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(241,141,6,1) 6%, rgba(254,250,224,1) 57%, rgba(255,254,247,1) 100%)",
                }}
              >
                <div className="left d-flex align-items-start gap-10 ">
                  <div className="imgWrp me-2">
                    <FormVector />
                  </div>
                  <div className="content">
                    <h6 className="fw-bold m-0 capitalize">Resources</h6>
                    <p className="m-0 py-1">
                      Forms, documents and protocols from the Nutritional
                      Therapy Association.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="commonCardBox my-3 p-3">
                <Form>
                  <Row>
                    <Col lg="3" className="my-2">
                      <div className="searchForm position-relative">
                        <div className="iconWithText poistion-relative">
                          <span className="icn position-absolute">
                            <SearchIcn />
                          </span>
                          <input
                            type="text"
                            placeholder="Filter Resources"
                            className="form-control"
                            name="search"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                          />
                        </div>
                      </div>
                      {searchTerm && (
                        <Link
                          className="py-1"
                          onClick={() => {
                            setSearchTerm("");
                          }}
                        >
                          Clear Filter
                        </Link>
                      )}
                    </Col>
                    <Col lg="9" className="my-2">
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-start gap-10 flex-wrap">
                        {categories && categories.length > 0 &&
                          categories.map((category, i) => {
                            return (
                              <>
                                <li className="px-2" key={i}>
                                  <div className="py-2 d-flex align-items-start">
                                    <div className="cstmInputCheckWrp position-relative me-2">
                                      <input
                                        type="checkbox"
                                        name="filter"
                                        className="position-absolute w-100 h-100"
                                        // defaultChecked={value.category_name}
                                        onClick={() =>
                                          handleSelectCategory(category.id)
                                        }
                                      />
                                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                        <span className="icn d-none">
                                          <OTick />
                                        </span>
                                      </div>
                                    </div>
                                    <p className=" m-0">
                                      {category.name}
                                    </p>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        {/* <li className="px-2">
                          <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp position-relative ">
                              <input
                                type="checkbox"
                                name="filter"
                                className="position-absolute w-100 h-100"
                              />
                              <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                  <OTick />
                                </span>
                              </div>
                            </div>
                            <p className=" m-0">Blank Forms</p>
                          </div>
                        </li>
                        <li className="px-2">
                          <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp position-relative ">
                              <input
                                type="checkbox"
                                name="filter"
                                className="position-absolute w-100 h-100"
                              />
                              <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                  <OTick />
                                </span>
                              </div>
                            </div>
                            <p className="m-0">Condition Reports - Client</p>
                          </div>
                        </li>
                        <li className="px-2">
                          <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp position-relative ">
                              <input
                                type="checkbox"
                                name="filter"
                                className="position-absolute w-100 h-100"
                              />
                              <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                  <OTick />
                                </span>
                              </div>
                            </div>
                            <p className=" m-0">
                              Diet, Nutrition and Lifestyle
                            </p>
                          </div>
                        </li>
                        <li className="px-2">
                          <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp position-relative ">
                              <input
                                type="checkbox"
                                name="filter"
                                className="position-absolute w-100 h-100"
                              />
                              <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                  <OTick />
                                </span>
                              </div>
                            </div>
                            <p className=" m-0">Nutritional Protocols</p>
                          </div>
                        </li> */}
                      </ul>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="commonCardBox">
                <TableData
                  filteredResults={filteredResults}
                  Columns={SortableColumns}
                />
                {(filteredResults.length > 0) &&
                  <ReactPagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    resourceListing: state.Resource.resources,
    activePage: state.Resource.activePage,
    itemsCountPerPage: state.Resource.itemsCountPerPage,
    loader: state.Resource.loader,
    categories: state.Resource.resourceCategories,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyResources);
