import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TimePicker from "rc-time-picker";
import DatePicker from "components/shared/DatePicker/Index";
import { FOOD_MOOD_DIRECTIONS_PDF, TIME_FORMAT_24 } from "common/constants";
import { ReactComponent as DeleteIcn } from "Assets/Images/icn/delete.svg";
import { timeRowFields } from "pages/SideTabs/MyClients/Detail/component/TabContent/AddData/Component/FoodMoodJournalForm/Component/FMJournalStructure";

const FoodMoodJournalFormPreview = ({ dispatch, todoId, formId, client, onToDoCompleted }) => {
  const [progress, setProgress] = useState(null);
  return (
    <>
      <section className="myLog position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2 py-3">
              <Row>
                <Col lg="7" className="my-2">
                  <div className="commonCardBox p-3">
                    <DatePicker
                      selectedDate={new Date()}
                      onSelectDate={() => { }}
                    />
                  </div>
                </Col>
                <Col lg="5" className="my-2">
                  <div className="commonCardBox p-3">
                    <ul className="list-unstyled ps-0 mb-0 py-3 d-flex align-items-baseline justify-content-center gap-10">
                      <li className="position-relative d-flex align-items-baseline gap-10">
                        <label
                          htmlFor=""
                          className="form-label theme-clr fw-sbold ws-nowrap"
                        >
                          Wake Time
                        </label>
                        {/* <input
                          type="time"
                          className="form-control p-1 d-inline-block"
                          value={currentDayFMData?.wake_time}
                          onChange={(e) => {
                            handleCurrentDayFieldChange("wake_time", e.target.value)
                          }}
                          style={{ width: "60px" }}
                        /> */}
                        <TimePicker
                          showSecond={false}
                          format={TIME_FORMAT_24}
                          use12Hours={false}
                        />
                      </li>
                      <li className="position-relative d-flex align-items-baseline gap-10">
                        <label
                          htmlFor=""
                          className="form-label theme-clr fw-sbold ws-nowrap"
                        >
                          Sleep Time
                        </label>
                        {/* <input
                          type="time"
                          className="form-control p-1 d-inline-block"
                          value={currentDayFMData?.sleep_time}
                          onChange={(e) => {
                            handleCurrentDayFieldChange("sleep_time", e.target.value)
                          }}
                          style={{ width: "60px" }}
                        /> */}
                        <TimePicker
                          showSecond={false}
                          format={TIME_FORMAT_24}
                        // use12Hours
                        />
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="my-2">
              <div className="table-responsive border-0">
                <table className="table food-mood-table">
                  <thead className="bg-transparent">
                    <tr>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Meal Time
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Foods
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Drinks
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Supps, Herbs & Meds
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Energy & Mood
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Movement & Relaxation
                      </th>
                      <th className="p-3 fw-normal text-dark fw-sbold capitalize">
                        Digestion & Reactions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-3 border-start border-end text-center d-flex gap-10">
                        <Button
                          variant="transparent"
                          className="border-0 p-0"
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          variant="transparent"
                          className="border-0 p-0"
                        >
                          <DeleteIcn />
                        </Button>
                        <ul className="list-unstyled ps-0 mb-0">
                          <li className="d-flex align-items-center justify-content-between gap-10 py-1">
                            <span className="theme-clr">Start:</span>
                            <span className="theme-clr"></span>
                          </li>
                          <li className="d-flex align-items-center justify-content-between gap-10 py-1">
                            <span className="theme-clr">End:</span>
                            <span className="theme-clr"></span>
                            {/* <span className="theme-clr">{moment(row.end_time, ['h:m a', 'H:m']).format("H:mm")}</span> */}
                          </li>
                        </ul>
                      </td>
                      {timeRowFields && Object.keys(timeRowFields).map(field => {
                        return (
                          <td className="border-start border-end text-center">
                            <Button
                              variant="transparent"
                              className="border-0 p-0"
                            >
                              <EditIcon />
                            </Button>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <div className="commonContnet d-flex aling-items-center justify-content-between gap-10 flex-wrap mb-3">
                  <div className="left">
                    <Button
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    >
                      <span className="icn me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_0_11892)">
                            <path
                              d="M15.1581 1.72786C16.9061 1.72786 18.3277 3.14811 18.3277 4.89453V14.3945C18.3277 16.1409 16.9061 17.5612 15.1581 17.5612H5.64956C3.90156 17.5612 2.48003 16.1409 2.48003 14.3945V4.89453C2.48003 3.14811 3.90156 1.72786 5.64956 1.72786H15.1581ZM15.1581 0.144531H5.64956C4.38864 0.144531 3.17937 0.644976 2.28776 1.53577C1.39616 2.42657 0.895264 3.63475 0.895264 4.89453L0.895264 14.3945C0.895264 15.6543 1.39616 16.8625 2.28776 17.7533C3.17937 18.6441 4.38864 19.1445 5.64956 19.1445H15.1581C16.4191 19.1445 17.6283 18.6441 18.5199 17.7533C19.4115 16.8625 19.9124 15.6543 19.9124 14.3945V4.89453C19.9124 3.63475 19.4115 2.42657 18.5199 1.53577C17.6283 0.644976 16.4191 0.144531 15.1581 0.144531Z"
                              fill="white"
                            />
                            <path
                              d="M5.64941 9.64518C5.64941 9.43522 5.7329 9.23386 5.8815 9.08539C6.0301 8.93692 6.23164 8.85352 6.4418 8.85352H14.3656C14.5758 8.85352 14.7773 8.93692 14.9259 9.08539C15.0745 9.23386 15.158 9.43522 15.158 9.64518C15.158 9.85515 15.0745 10.0565 14.9259 10.205C14.7773 10.3534 14.5758 10.4368 14.3656 10.4368H6.4418C6.23164 10.4368 6.0301 10.3534 5.8815 10.205C5.7329 10.0565 5.64941 9.85515 5.64941 9.64518Z"
                              fill="white"
                            />
                            <path
                              d="M10.404 14.3984C10.194 14.3984 9.99264 14.315 9.84418 14.1664C9.69571 14.0178 9.6123 13.8162 9.6123 13.6061L9.6123 5.68223C9.6123 5.47208 9.69571 5.27053 9.84418 5.12193C9.99264 4.97333 10.194 4.88985 10.404 4.88985C10.6139 4.88985 10.8153 4.97333 10.9638 5.12193C11.1122 5.27053 11.1956 5.47208 11.1956 5.68223L11.1956 13.6061C11.1956 13.8162 11.1122 14.0178 10.9638 14.1664C10.8153 14.315 10.6139 14.3984 10.404 14.3984Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_0_11892">
                              <rect
                                width="19.0172"
                                height="19"
                                fill="white"
                                transform="translate(0.895264 0.144531)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Add New Time Row
                    </Button>
                  </div>
                  <div className="right">
                    <h6 className="m-0 fw-sbold capitalize">
                      Need help?{" "}
                      <a
                        className="underline"
                        href={FOOD_MOOD_DIRECTIONS_PDF}
                        target="_blank"
                      >
                        View the Directions
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="position-relative p-3 p-lg-4 gettingStarted d-flex align-items-start gap-10"
                style={{ background: "#fffef7", zIndex: "9" }}
              >
                <div className="imgWrp me-2" style={{ maxWidth: "80px" }}>
                  <CircularProgressbar
                    value={0}
                    text={`0%`}
                    styles={buildStyles({
                      pathColor: "#f4694b",
                      textColor: "#f4694b",
                    })}
                  />
                </div>
                <div className="commonContent">
                  <h3 className="m-0 py-2">Food & Mood Journal Progress</h3>
                  <p className="m-0 capitalize">
                    <span>You're logging data for your <strong>1st</strong> day!</span>{" "}
                    <span>Once you've logged at least 3 days you'll be able to submit your Food & Mood Journal.</span>
                  </p>
                  {progress && progress.numDaysWithData >= 3 && (
                    <div className="btnWrp mt-2">
                      <Button
                        className="d-flex capitalize align-items-center justify-content-center commonBtn btnSm"
                      >
                        Submit Food & Mood Journal
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <g clip-path="url(#clip0_0_11862)">
        <path
          d="M17.4166 11.5417C16.9097 11.5417 16.5 11.9711 16.5 12.5V20.1667C16.5 20.6947 16.0893 21.125 15.5834 21.125H2.75C2.24396 21.125 1.83339 20.6947 1.83339 20.1667V6.75C1.83339 6.22199 2.24396 5.79173 2.75 5.79173H10.0834C10.5903 5.79173 11 5.36236 11 4.83345C11 4.30441 10.5903 3.875 10.0834 3.875H2.75C1.23385 3.875 0 5.16493 0 6.75V20.1667C0 21.7518 1.23385 23.0417 2.75 23.0417H15.5834C17.0995 23.0417 18.3334 21.7518 18.3334 20.1667V12.5C18.3334 11.9701 17.9235 11.5417 17.4166 11.5417Z"
          fill="#333"
        />
        <path
          d="M8.59454 10.6692C8.53083 10.7361 8.4872 10.821 8.46899 10.9135L7.82093 14.3023C7.79073 14.4593 7.83838 14.6213 7.94649 14.7353C7.98915 14.7798 8.03978 14.815 8.09547 14.839C8.15116 14.863 8.21083 14.8753 8.27107 14.8752C8.30029 14.8752 8.33067 14.8724 8.36088 14.8657L11.6013 14.1882C11.6911 14.1689 11.7727 14.124 11.836 14.0568L19.0887 6.47442L15.8482 3.08686L8.59454 10.6692ZM21.3289 0.743784C20.4353 -0.190636 18.9814 -0.190636 18.0884 0.743784L16.8198 2.07001L20.0603 5.45775L21.3289 4.13134C21.7616 3.68001 21.9999 3.0781 21.9999 2.43797C21.9999 1.79783 21.7616 1.19597 21.3289 0.743784Z"
          fill="#333"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_11862">
          <rect
            width="22"
            height="23"
            fill="white"
            transform="translate(0 -0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FoodMoodJournalFormPreview