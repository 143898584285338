import { deleteData, getData, postData, putData } from "helpers/apiHelper";
import { CLIENT_DOC_NOTES_ADD, CLIENT_DOC_NOTES_DELETE, CLIENT_DOC_NOTES_LIST, CLIENT_DOC_NOTES_UPDATE, CLIENT_DOCUMENT_ADD, CLIENT_DOCUMENT_LIST, CLIENT_DOCUMENT_REMOVE, CLIENT_DOCUMENT_TOGGLE_VISIBILITY, CLIENT_DOCUMENT_UPDATE, MAIN_PATH } from "./apiPath";
import { getClientDocumentList } from "store/actions/client";
import { generatePathCustom } from "helpers/common";

// Add client document
export function addClientDocument(data) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DOCUMENT_ADD}/${clientId}`;
  return postData(url, values);
}

// Update client document
export function updateClientDocument(data) {
  let { id, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DOCUMENT_UPDATE}/${id}`;
  return postData(url, values);
}

// Toggle client document visibility
export function toggleClientDocumentVisiblity(data) {
  let { id, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DOCUMENT_TOGGLE_VISIBILITY}/${id}`;
  return postData(url, values);
}

// remove client document
export function deleteClientDocument(id) {
  let url = `${MAIN_PATH}${CLIENT_DOCUMENT_REMOVE}/${id}`;
  return deleteData(url);
}

// List client documents
export function listClientDocuments(data, callback) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DOCUMENT_LIST}/${clientId}`;
  return (dispatch) =>
      postData(url, values).then((data) => {
        dispatch(getClientDocumentList(data, callback));
        return data;
      });
}

// get notes
export function getClientDocNotes(documentId) {
  let url = `${MAIN_PATH}${generatePathCustom(CLIENT_DOC_NOTES_LIST, { documentId })}`;
  return getData(url);
}

// add note
export function addClientDocNote(data) {
  let { documentId, values } = data;
  let url = `${MAIN_PATH}${generatePathCustom(CLIENT_DOC_NOTES_ADD, { documentId })}`;
  return postData(url, values);
}

// update note
export function updateClientDocNote(data) {
  let { noteId, values } = data;
  let url = `${MAIN_PATH}${generatePathCustom(CLIENT_DOC_NOTES_UPDATE, { noteId })}`;
  return putData(url, values);
}

// delete note
export function deleteClientDocNote(noteId) {
  let url = `${MAIN_PATH}${generatePathCustom(CLIENT_DOC_NOTES_DELETE, { noteId })}`;
  return deleteData(url);
}