import { decode } from "html-entities";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTodoResponse } from "store/services/clientService";
import { getTodoNotes, saveTodoNotes } from "store/services/clientDataReportsService";
import { RenderAnswerField } from "components/shared/ToDoQuestionsAnswers/RenderAnswer/Index";
import { validatePageConditions, validateQuestionConditions } from "helpers/common";
import { formatTodoResponse } from "helpers/dataFormat";
import ManageNotes from "../ManageNotes";

const AllQuestionAnswers = ({ todoId, formId, isOpen }) => {
  const { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [todoDetails, setTodoDetails] = useState(null);
  const [notesData, setNotesData] = useState(null);
  
  let questionCount = 0;

  useEffect(() => {
    if (isOpen && !todoDetails) {
      async function init() {
        try {
          setIsLoading(true);
          await Promise.all([
            getTodoDetails(),
            getNotes(),
          ]);
          setIsLoading(false);
        } catch(err) {
          setIsLoading(false);
          console.log("Error in init function", err);
        }
      }
      init();
    }
  }, [isOpen])


  const handleSaveNotes = async (notes, page) => {
    try {
      let data = {
        todoId,
        values: {
          todo_id: todoId,
          general_notes: notes || "",
        }
      };

      if(page && page?.form_page_id && page?.form_page_id !== "null" ) {
        data.values.page_id = page?.form_page_id;
      }

      await saveTodoNotes(data);
      await getNotes();
    } catch(err) {
      throw err;
    }
  }

  const getNotes = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      const response = await getTodoNotes(todoId);
      if (response.success === true && response.body.length > 0) {
        setNotesData(response.body);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getTodoDetails = async () => {
    if (!todoId) {
      return;
    }
    try {
      let data = {
        todoId,
        clientId
      };
      const response = await getTodoResponse(data);
      if (response.success === true) {
        const details = formatTodoResponse(response.body);
        const formattedData = validateConditions(details);
        console.log("formattedData", formattedData);
        setTodoDetails(formattedData);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const validateConditions = (allPages) => {
    let allQuestionAnswers = [];
    for(let i = 0; i < allPages.length; i++) {
      if(allPages[i] && allPages[i].questions && allPages[i].questions.length > 0) {
        allQuestionAnswers= [...allPages[i].questions, ...allQuestionAnswers];
      }
    }
    return allPages.map(page => {
      page.visible = validatePageConditions(page, allQuestionAnswers);
      page.questions = page.questions.map(q => {
        q.visible = validateQuestionConditions(q, allQuestionAnswers);
        return q;
      })
      return page;
    })
  }

  return (
    <>
      {isLoading ?
        <p>Loading...</p>
        :
        <div className="accordion-inner">
        {todoDetails && todoDetails.map(page => {
          if(!page || page.visible === false) {
            return null;
          }
          return (
            <>
              <div className="py-2">
                <div className="top py-2 commonContent">
                  <h3 className="m-0 py-1 capitalize">
                    {decode(page.page_title)}
                  </h3>
                </div>
                <div className="py-2">
                  <div className="commonCardBox">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="p-3 text-dark fw-sbold">Question</th>
                            {(formId == 4 || formId == 12) &&
                              <th className="p-3 text-dark fw-sbold">Answer Score</th>
                            }
                            <th className="p-3 text-dark fw-sbold">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {page.questions && page.questions.length > 0 &&
                            page.questions.map(question => {
                              if(!question || question.visible === false) {
                                return null;
                              }
                              questionCount++;
                              return (
                                <tr>
                                  <th className="p-3 ws-normal">
                                    <span className="text-dark fw-sbold">
                                      {question.sorting}. {decode(question.question)}
                                    </span>
                                  </th>
                                  <td className="p-3 ws-normal">
                                    <RenderAnswerField
                                      question={question}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <ManageNotes
                  page={page}
                  notesData={notesData}
                  handleSave={handleSaveNotes}
                />
              </div>
            </>
          )
        })}
        </div>
      }
    </>
  )
}

export default AllQuestionAnswers;