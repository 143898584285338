import { useDispatch } from "react-redux";
import { decode } from "html-entities";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { viewMyFormData, viewMyFormDetails } from "store/services/myFormService";
import { useShowLoader } from "hooks/useShowLoader";
import { ERROR_MESSAGE } from "common/constantMessage";
import TextArea from "./InputComponents/TextArea";
import TextInput from "./InputComponents/TextInput";
import Scale from "./InputComponents/Scale";
import DatePicker from "./InputComponents/DatePicker";
import Weight from "./InputComponents/Weight";
import Disclaimer from "./InputComponents/Disclaimer";
import Height from "./InputComponents/Height";
import Address from "./InputComponents/Address";
import RadioButtons from "./InputComponents/RadioButtons";
import CheckBoxes from "./InputComponents/CheckBoxes";
import { useSelector } from "react-redux";
import { editForm } from "Routes/paths";
import { Container, Row } from "react-bootstrap";
import { FOOD_JOURNAL_FORM_ID } from "common/constants";
import FoodMoodJournalFormPreview from "components/shared/FoodMoodJournalFormPreview";
import parse from "html-react-parser";

export const MyFormPreview = () => {
  const { formId } = useParams();
  const dispatch = useDispatch();
  const showLoader = useShowLoader();
  // currentUserId: state?.Auth?.user?.id || null,
  const currentUserId = useSelector((state) => state.Auth?.user?.id);

  const [formData, setformData] = useState({});

  const handleFormData = async () => {
    try {
      showLoader(true);
      const res = await viewMyFormDetails(formId);
      setformData(res.body || {});
      showLoader(false);
    } catch (err) {
      console.log("Error rendering form pdf", err);
      ERROR_MESSAGE("Something went wrong");
      showLoader(false);
    }
  };

  useEffect(() => {
    handleFormData();
  }, []);

  const RenderFormPreview = () => {
    let formId = formData?.details?.id;
    switch(formId) {
      case FOOD_JOURNAL_FORM_ID:
        return (
          <FoodMoodJournalFormPreview />
          // <p>Food Mood Preview</p>
        )
      default:
        return (
          <>
          {formData.pages &&
            formData.pages.map((page, index) => {
              if(!page?.questions?.length) {
                return "";
              }
              return (
                <div className="commonCardBox my-3 p-3" key={page.id}>
                  {page?.title && page?.questions?.length > 0 && (
                    <h5 className="m-0 fw-bold">
                      Page {index + 1} : {decode(page?.title)}
                    </h5>
                  )}
                  <div className="mt-3">
                    {page?.questions?.map((question) => (
                      <QuestionFields key={question.id} question={question} />
                    ))}
                  </div>
                </div>
              );
            })}
          </>
        )
    }
  }

  console.log("formData", formData);

  if (!formData.pages?.length) {
    return "";
  }

  // console.log("formData", formData);

  return (
    <section
      className="position-relative"
      style={{ overflow: "hidden" }}
    >
      <Container fluid>
        <Row>
          <div className="form-details mt-3">
            {formData?.details?.form_name && (
              <div className="d-flex align-items-center gap-10">
                <h2 className="m-0 fw-bold">{formData?.details?.form_name}</h2>
                {formData.details?.created_by === currentUserId && (
                  <Link
                    to={editForm.replace(":formId", formData.details.id)}
                    className="border rounded btn bg-tranpsparent px-1 pt-0 pb-1 ms-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M15.5325 5.27992C15.825 4.98742 15.825 4.49992 15.5325 4.22242L13.7775 2.46742C13.5 2.17492 13.0125 2.17492 12.72 2.46742L11.34 3.83992L14.1525 6.65242M2.25 12.9374V15.7499H5.0625L13.3575 7.44742L10.545 4.63492L2.25 12.9374Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            )}
            {formData?.details?.form_desc && (
              <p className="m-0 py-2">{parse(formData?.details?.form_desc)}</p>
            )}
          </div>
          <div className="commonContent py-2">
            <RenderFormPreview />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export const QuestionFields = ({ question }) => {
  const textInputFields = ["textTiny", "textXShort", "textShort", "numberRange"];
  const textAreaFields = ["textLong", "textXLong", "textXXLong"];

  return (
    <div>
      <div style={{ margin: "3px 0px 3px 0x" }}>
        <div className="d-flex">
          <h4 className="m-0 fw-bold">
            {decode(question.question)}
            {question.is_required === "yes" ? " *" : " (optional)"}
          </h4>
        </div>
      </div>
      <div className="my-form-preview">
        {textAreaFields.includes(question?.question_type) ? (
          <TextArea question={question} />
        ) : question?.question_type == "radioButtons" ? (
          <RadioButtons question={question} />
        ) : textInputFields.includes(question?.question_type) ? (
          <TextInput question={question} />
        ) : question?.question_type === "checkBoxes" ? (
          <CheckBoxes question={question} />
        ) : question?.question_type == "ratingScale" ? (
          <Scale question={question} />
        ) : question?.question_type == "date" ? (
          <DatePicker question={question} />
        ) : question?.question_type == "weight" ? (
          <Weight question={question} />
        ) : question?.question_type == "disclaimer" ? (
          <Disclaimer question={question} />
        ) : question?.question_type == "height" ? (
          <Height question={question} />
        ) : question?.question_type == "address" ? (
          <Address question={question} />
        ) : (
          <TextArea question={question} /> // default
        )}
      </div>
    </div>
  );
};

export default MyFormPreview;
