import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { CONFIRM_POPUP, PROMISE_TOAST } from "common/constantMessage";
import AppInputField from "components/shared/FormComponents/AppInputField";
import LoadingButton from "components/shared/LoadingButton";
import { FormikProvider, useFormik } from "formik";
import { DATE_FORMAT, DATE_FORMAT_TIME } from "helpers/common";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addClientDocNote, deleteClientDocNote, getClientDocNotes, updateClientDocNote } from "store/services/clientDocumentsService";
import { object, string } from "yup";

const DocumentNotesPopup = ({ documentId, showPopup, handleClose, getDocuments }) => {
  const [documentsDirty, setDocumentsDirty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [editNoteData, setEditNoteData] = useState(null);
  const [notes, setNotes] = useState([]);

  const notesInitialValues = useMemo(() => {
    return {
      note: editNoteData?.note || "",
      pinned: editNoteData?.pinned || 0,
    }
  }, [editNoteData]);

  useEffect(() => {
    if(!documentId) {
      return;
    }

    fetchNotes();
  }, [documentId]);

  const fetchNotes = async () => {
    try {
      setLoading(true);
      let response = await getClientDocNotes(documentId)
      if(response?.body?.length) {
        setNotes(response?.body);
      } else {
        setNotes([]);
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      console.error("Error fetching notes", err);
    }
  }

  const saveNote = async (values, { setSubmitting }) => {
    try {
      setDocumentsDirty(true);
      setSubmitting(true);
      let payload = {
        values
      }
      if(editNoteData) {
        payload.noteId = editNoteData?.id;
        await updateClientDocNote(payload);
      } else {
        payload.documentId = documentId;
        await addClientDocNote(payload);
      }
      fetchNotes();
      setSubmitting(false);
      closeEditNoteForm();
    } catch(err) {
      setSubmitting(false);
      console.error("Error saving note", err);
    }
  }

  const deleteNote = async (noteId) => {
    try {
      let isConfirm = await CONFIRM_POPUP();
      if(!isConfirm) {
        return;
      }

      await PROMISE_TOAST(deleteClientDocNote(noteId), {
        success: "Note removed!",
        pending: "Please wait...",
        error: "Failed!"
      });
      setDocumentsDirty(true);
      fetchNotes();
    } catch(err) {
      console.error("Error saving note", err);
    }
  }

  const handleEditNote = (note) => {
    setShowAddNote(true);
    setEditNoteData(note);
  }

  const closeEditNoteForm = () => {
    formik.resetForm();
    setShowAddNote(false);
    setEditNoteData(null);
  }

  const closePopup = () => {
    if(documentsDirty) {
      getDocuments();
      setDocumentsDirty(false);
    }
    setShowAddNote(false);
    setEditNoteData(null);
    setNotes([]);
    handleClose();
  }

  const formik = useFormik({
    initialValues: notesInitialValues,
    onSubmit: saveNote,
    enableReinitialize: true,
    validationSchema: noteValidationSchema
  });

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAddNote ? (
            <Row>
              <Col lg="12" className="">
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Row>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Note*
                        </label>
                        <AppInputField
                          name="note"
                          className="form-control"
                          fieldType={"textarea"}
                          rows={4}
                        />
                      </Col>
                      <Col lg="12" className="my-2 d-flex gap-10 align- -center">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Pin Note?
                        </label>
                        {/* <AppInputField
                          clas
                          name="pinned"
                          type="checkbox"
                          checked={!!values.pinned}
                          onChange={() => setFieldValue("pinned", values.pinned ? 0 : 1)} // Toggle value
                        /> */}
                        <BootstrapSwitchButton
                          className="rounded-pill"
                          checked={!!formik.values.pinned}
                          onlabel="Yes"
                          offlabel="No"
                          size="xs"
                          onChange={(isChecked) => {
                            let value = isChecked === true ? 1 : 0
                            formik.setFieldValue("pinned", value)
                          }}
                        />
                      </Col>
                      <Col lg="12" className="mt-4 d-flex gap-10">
                        <LoadingButton
                          type="submit"
                          className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn btnSm"
                          loading={formik.isSubmitting}
                          disabled={formik.isSubmitting}
                        >
                          {formik.isSubmitting ? "Saving..." : "Save Note"}
                        </LoadingButton>
                        <Button
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                          onClick={closeEditNoteForm}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </Col>
            </Row>
          ):(
            <Row>
              <Col lg="12" className="">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-transparent">
                      <tr>
                        <th className="p-0" width={"2%"}>

                        </th>
                        <th className="p-3 fw-normal text-dark fw-bold capitalize" width={"68%"}>
                          Note
                        </th>
                        <th className="p-3 fw-normal text-dark fw-bold capitalize" width={"15%"}>
                          Created At
                        </th>
                        <th className="p-3 fw-normal text-dark fw-bold capitalize" width={"15%"}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={3} className="border-0 text-center">Loading...</td>
                        </tr>
                      ):notes?.length > 0 ? (
                        notes.map((note, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="p-0 border-0 text-center">
                                {note.pinned === 1 && (
                                  <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                )}
                              </td>
                              <td className="px-3 py-2 border-0">
                                <p className="theme-clr m-0 fw-bold is-multiline">
                                  {note.note}
                                </p>
                              </td>
                              <td className="px-3 py-2 border-0">
                                <p
                                  className="theme-clr m-0"
                                  title={DATE_FORMAT_TIME(note.created_at)}
                                >
                                  {DATE_FORMAT(note.created_at)}
                                </p>
                              </td>
                              <td className="px-3 py-2 border-0">
                                <Button
                                  variant="transparent"
                                  className="p-0 me-1"
                                  onClick={() => {
                                    handleEditNote(note)
                                  }}
                                >
                                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </Button>
                                <Button
                                  variant="transparent"
                                  className="p-0"
                                  onClick={() => {
                                    deleteNote(note?.id)
                                  }}
                                >
                                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )):(
                        <tr>
                          <td colSpan={3} className="border-0 text-center">No documents found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col lg="12" className="mt-4">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={() => {
                    setShowAddNote(true);
                  }}
                >
                  Add New Note
                </Button>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const noteValidationSchema = object({
    note: string().required("Note is Required").max(1000),
    pinned: string().oneOf(["0", "1"])
});

export default DocumentNotesPopup;
