import { SUCCESS_MESSAGE } from "common/constantMessage";
import { useFormik } from "formik";
import { stringRequired } from "helpers/validationHelper";
import React, { useMemo } from "react";
import { Button, Form, Modal, } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { addClientNote, updateClientNote } from "store/services/clientNotesService";
import { object } from "yup";

const AddNotesPopup = ({ showPopup, closePopup, noteData, handleSave, refetch }) => {
  const editMode = !!noteData;
  const { clientId } = useParams();

  const initialValues = useMemo(() => ({
    note: noteData?.note || "",
  }), [noteData])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: noteValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        let response = null;
        if(editMode) {
          response = await updateClientNote(noteData.id, values);  
        } else {
          response = await addClientNote(clientId, values);
        }
        SUCCESS_MESSAGE(response?.message);
        await refetch();
        hidePopup();
      } catch(err) {
        console.error("Error saving notes", err);
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true
  });
  
  const hidePopup = () => {
    formik.resetForm();
    closePopup();
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">
            {editMode ? "Edit" : "Add"} Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Form onSubmit={formik.handleSubmit}>
            <div className="fm-note-fields py-2">
              <div className="py-2">
                <div className="input-group">
                  <textarea
                    autoFocus={true}
                    className={`form-control resizable ${formik.errors.note ? "is-invalid" : ""}`}
                    aria-label="Client Notes"
                    rows={4}
                    name="note"
                    {...formik.getFieldProps("note")}
                  ></textarea>
                </div>
                  {formik.touched.note && formik.errors.note && (
                    <p className="invalid-feedback d-block">{formik.errors.note}</p>
                  )}
              </div>
              <div className="py-2 d-flex gap-10 justify-content-end">
                <Button
                  onClick={hidePopup}
                  className="d-flex align-items-center justify-content-center commonBtn"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn"
                  disabled={formik.isSubmitting}
                  type="submit"
                >
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const noteValidationSchema = object({
    note: stringRequired("Note"),
});

export default AddNotesPopup;
